import React from 'react';
import '../styles/footer.css';

const Footer = () => {

  return (
    <div id='footer'>
      <span id='copyright'>© RATIONAL PRODUCTION</span>        <a id='link' target='_blank' href='http://www.rationalproduction.com' rel='noreferrer'>www.rationalproduction.com</a>
    </div>
  );
};

export default Footer;
