import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import '../styles/toast.css';
import isEqual from 'react-fast-compare';

class Toast extends React.Component {

    constructor(props) {
        super(props);


        this.state = { Notification: null };

    }

    componentDidUpdate() {

        if (!isEqual(this.props?.Notification, this.state?.Notification)) {
            if (this.state.Notification===null) {

                if (this.props.Notification.Type === 0)
                    return;

                this.setState({  Notification: this.props.Notification });
            }
        }

    }

    renderToast() {
        if (this.state.Notification!==null) {

            let content  = this.state.Notification.Message;
            let cssToast;
            setTimeout(function () {
                this.setState({ Notification: null });
            }.bind(this), 3000);

            if (this.state.Notification.Type === 1)
                cssToast = 'success';
              
            if (this.state.Notification.Type === -1)
                cssToast = 'error';

            return (
            
                <div id='toast_default' className={cssToast} ><img className={'icon '+ cssToast} src={'/images/icons/'+cssToast+'.svg'}></img><span id='textNotification'><FormattedMessage id={content}/></span></div>
            )
        }
    }

    render() {
        return (
            <div id='divToastSideBar'>
                {this.renderToast()}
            </div>
        )
    }
}

Toast.propTypes = {
    Notification: PropTypes.object,
    intl: PropTypes.object,
    Option: PropTypes.string,
    Refresh: PropTypes.func,
};

export default injectIntl(Toast);