import React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import CommonDataManager from '../../components/CommonDataManager';


export function statusTemplate(props) {
    this.commonData = CommonDataManager.getInstance();
    this.Alarms = this.commonData?.getAlarms();
    if (this !== undefined) {
        if (this.intl !== undefined)
            return statusTemplateIntl(props, this.intl, this.Alarms)

        if (this.props.intl !== undefined)
            return statusTemplateIntl(props, this.props.intl, this.Alarms)
    }

    return (<div></div>);
}

export function statusTemplateIntl(props, intl, alarm) {
    try {

        let status = '';

        status = intl.formatMessage({ id: 'status_' + props.Status, defaultMessage: ' ' });

        if (props.Status === 0)
            return (
                <div id='StatusNC'>{status}</div>
            );

        if (props.Status === 1)
            return (
                <div id='StatusOFF'>{status}</div>
            );

        if (props.Status === 2)
            return (
                <div id='StatusON'>{status}</div>
            );

        if (props.Status === 3)
            return (
                <div id='StatusCYCLE'>{status}{props.CycleNumber}</div>
            );

        if (props.Status === 4) {
            let alarmCode = '';
            let alarmDescription = '';

            if (props.Alarms !== undefined)
                if (props.Alarms.length > 0)
                    alarmCode = props.Alarms[0];

            if (alarmCode !== '') {
                if (alarm !== undefined)
                    if (alarm.length > 0)
                        for (let i = 0; i < alarm.length; i++) {
                            if (alarm[i].ID === alarmCode)
                                if (alarm[i].CustomDescription !== '')
                                    alarmDescription = (alarm[i].Description + ' ' + alarm[i].CustomDescription)
                                else
                                    alarmDescription = alarm[i].Description; //CustomDescription        
                        }
            }
            return (
                <TooltipComponent position='TopCenter' target='#StatusALARM' content={alarmDescription}>
                    <div id='StatusALARM'>{status} {alarmCode}</div>
                </TooltipComponent>
            );
        }

        if (props.Status === 5)
            return (
                <div id='StatusERROR'>{status}</div>
            );

        if (props.Status === 6)
            return (
                <div id='StatusWAITING'>{status}</div>
            );

        if (props.Status === 7)
            return (
                <div id='StatusSETTINGS'>{status}</div>
            );
    }
    catch (exp) {
        return (
            <div></div>
        );
    }
}
