import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import '../styles/toolbar.css';

class Toolbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = { SelectedToolbarItem: this.props?.Items[0]?.Title };
    }

    ItemSelected(title, route) {
        this.setState({ SelectedToolbarItem: title })
        this.props.ToolbarSelect(title, route);
    }

    toolbarHeader() {
        return (
            <div className={'divToolbar ' + this.props.Option}>
                {this.props.Items.map((menu, index) => {
                    const t = this.props.intl.formatMessage({ id: menu.Title });
                    if (this.props.Option === 'menuHeader' || this.props.Option === 'menuSetupHeader' || this.props.Option === 'menuEventHeader')
                        var imgList = '/images/icons/' + menu.Route.TemplateId.toLowerCase() + '.svg';

                    if (this.props.Option === 'setupUsers' || this.props.Option === 'sidebarManage')
                        imgList = '/images/icons/' + menu.Title.toLowerCase() + '.svg'

                    let classSelected = '';
                    if (this.props.Option === 'menuHeader' || this.props.Option === 'menuSetupHeader' || this.props.Option === 'menuEventHeader') {

                        if (this.props.Option === 'menuHeader')
                            classSelected = ((menu.Route.TemplateId.startsWith('setupUsers')) && (this.props.CurrentView.startsWith('setupUsers')) || (this.props.CurrentView === menu.Route.TemplateId)) ? 'selected' : '';

                        if (this.props.Option === 'menuSetupHeader')
                            classSelected = (this.props.CurrentView === menu.Route.TemplateId) ? 'selected' : '';
                        if (this.props.Option === 'menuEventHeader')
                            classSelected = (this.props.CurrentView === menu.Route.TemplateId) ? 'selected' : '';
                    }
                    else {
                        classSelected = (this.state.SelectedToolbarItem === menu.Title) ? 'selected' : '';
                    }

                    return (
                        <div key={index} >
                            <div className={'tabToolbar ' + this.props.Option}>
                                <button className={'listToolbar ' + this.props.Option + ' ' + classSelected}
                                    onClick={() => this.ItemSelected(menu.Title, menu.Route)}>
                                    <div id='avatarToolbar' className='e-avatar e-avatar-xsmall e-avatar-circle' >
                                        <img id={'listImgToolbar' + classSelected} src={imgList}></img>
                                    </div>
                                    {t}
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>)
    }

    render() {
        return (
            <div>
                {this.toolbarHeader()}
            </div>
        );
    }
}

Toolbar.propTypes = {
    ItemSelected: PropTypes.func,
    intl: PropTypes.object,
    Items: PropTypes.array,
    ToolbarSelect: PropTypes.func,
    Option: PropTypes.string,
    CurrentView: PropTypes.string
};
export default injectIntl(Toolbar);