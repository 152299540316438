import React from 'react';
import PropTypes from 'prop-types';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Sort, ExcelExport } from '@syncfusion/ej2-react-grids';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Zoom, Export, DateTime, DateTimeCategory, Legend, Category, Crosshair, LineSeries, Tooltip, StripLine } from '@syncfusion/ej2-react-charts';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import '../../styles/manage/chartControl.css';
import CommonDataManager from '../../components/CommonDataManager';
import { loadCldr, L10n, setCulture } from '@syncfusion/ej2-base';
import { injectIntl, FormattedMessage } from 'react-intl';
import { convertFahrenheit } from '../../components/utils/TemperatureConversion';

class ChartControl extends React.Component {

    items = [
        { id: 0, text: 'JPEG' },
        { id: 1, text: 'PNG' },
        { id: 2, text: 'SVG' },
        { id: 3, text: 'PDF' },
        { id: 4, text: 'EXCEL' }
    ];

    constructor(props) {
        super(props);

        this.state = {
            Data: [],
            FullScreenVisible: false,
            enableCrosshair: false,
            enabledNextHistory: false,
            enabledPrevHistory: true
        };
        this.palette = ['red', 'blue', 'green'];
        this.fields = { text: 'text', value: 'id' };

        this.commonData = CommonDataManager.getInstance();
        L10n.load(this.commonData.getMessages());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());
        setCulture(this.commonData.getLanguage());
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextState.enableCrosshair !== this.state.enableCrosshair) return true;

        if (!this.props?.Realtime)
            if (this.props?.SelectedDevice)
                if ((this.props.SelectedDevice.Id === nextProps.SelectedDevice.Id) && (this.props?.DataDetailHistory?.DataHistoryTemperature === nextProps?.DataDetailHistory?.DataHistoryTemperature))
                    return false;

        return true;
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.intl.locale !== prevProps.intl.locale) {

            L10n.load(this.commonData.getMessages());
            setCulture(this.commonData.getLanguage());
            loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());

        }

        if (this.props !== prevProps) {

            if (this.props?.DataDetailHistory) {

                this.chartInstance.dataSource = null;

                let chartT = JSON.parse(JSON.stringify(this.props?.DataDetailHistory?.DataHistoryTemperature));
                if (this.props.UmTemperature === 'F') {
                    for (let i = 0; i < chartT?.length; i++) {

                        chartT[i].TOven = convertFahrenheit(chartT[i].TOven);
                        chartT[i].TFridge = convertFahrenheit(chartT[i].TFridge);
                        chartT[i].TProbe = convertFahrenheit(chartT[i].TProbe);
                    }
                }

                this.chartInstance.dataSource = chartT;
                this.renderCycleBands();
            }
        }

        if (this.chartInstance) {
            let tmpTitle = '';
            if (this.props.SelectedDevice !== undefined)
                tmpTitle = this.props.SelectedDevice.DeviceID + ' ' + this.props.SelectedDevice.Site + ' ' + this.props.SelectedDevice.Department;

            let tmpSubTitle = '';
            if (this.props.Realtime)
                tmpSubTitle = this.props.intl.formatDate(Date());
            else
                if (this.datepickerInstance?.value)
                    tmpSubTitle = this.props.intl.formatDate(this.datepickerInstance.value);

            if (this.chartInstance.title !== tmpTitle)
                this.chartInstance.title = tmpTitle;

            if (this.chartInstance.subTitle !== tmpSubTitle)
                this.chartInstance.subTitle = tmpSubTitle;

            if (prevState.enableCrosshair !== this.state.enableCrosshair)
                this.chartInstance.refresh(false);

        }

        if ((this.props?.SelectedDevice?.Id !== prevProps?.SelectedDevice?.Id) || (this.props?.SelectedDevice === undefined)) {
            this.filterDateTemperaturesHistory();
        }

    }

    handleExport(e) {
        let msgExport = this.props.SelectedDevice.DeviceID + '_' + this.props.SelectedDevice.Site + '_' + this.props.SelectedDevice.Department;

        if (e.item.id === 0) this.chartInstance.exportModule.export('JPEG', msgExport);
        if (e.item.id === 1) this.chartInstance.exportModule.export('PNG', msgExport);
        if (e.item.id === 2) this.chartInstance.exportModule.export('SVG', msgExport);
        if (e.item.id === 3) this.chartInstance.exportModule.export('PDF', msgExport);
        if (e.item.id === 4) this.exportExcel();
    }

    exportExcel() {

        let cloneColumns = JSON.parse(JSON.stringify(this.gridInstance.columns));
        let msgExport = this.props.SelectedDevice.DeviceID + '_' + this.props.SelectedDevice.Site + '_' + this.props.SelectedDevice.Department + '.xlsx';
        this.gridInstance?.excelExport({ includeHiddenColumn: true, columns: cloneColumns.filter(c => c.exportexcel), fileName: msgExport });
    }

    gridChart() {

        let chartT = this.props?.DataDetailHistory?.DataHistoryTemperature;
        if (this.props.UmTemperature === 'F') {
            for (let i = 0; i < chartT?.length; i++) {

                chartT[i].TOven = convertFahrenheit(chartT[i].TOven);
                chartT[i].TFridge = convertFahrenheit(chartT[i].TFridge);
                chartT[i].TProbe = convertFahrenheit(chartT[i].TProbe);
            }
        }

        return (
            <div>
                <GridComponent id='exportExcelChart' ref={grid => this.gridInstance = grid}
                    dataSource={chartT} enableHover={true} enableVirtualization={false}
                    allowFiltering={true} enableToggle={true}
                    allowExcelExport={true}
                    allowSorting={true} allowSelection={true}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='Id' visible={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective field='DateTime' headerText={this.props.intl.formatMessage({ id: 'date' })} width='10%' type='datetime' format={{ type: 'dateTime', skeleton: 'short' }} visible={true} exportexcel={true}></ColumnDirective>
                        <ColumnDirective field='TFridge' headerText={this.props.intl.formatMessage({ id: 'tfridge' })} visible={true} exportexcel={true}></ColumnDirective>
                        <ColumnDirective field='TOven' headerText={this.props.intl.formatMessage({ id: 'toven' })} visible={true} exportexcel={true}></ColumnDirective>
                        <ColumnDirective field='TProbe' headerText={this.props.intl.formatMessage({ id: 'tprobe' })} visible={false} exportexcel={true}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Filter, Sort, ExcelExport]} />
                </GridComponent>

            </div>
        )
    }

    handleFullScreen() {
        this.setState({ FullScreenVisible: true });
    }

    handleCloseFullScreen() {
        this.setState({ FullScreenVisible: false });
    }

    filterDateTemperaturesHistory(direction) {
        if (this.datepickerInstance !== undefined)
            if (this.datepickerInstance.value !== undefined) {
                if (this.datepickerInstance.value !== null) {
                    if (direction === 0)
                        this.datepickerInstance.value = new Date(new Date().toDateString());
                    this.props.FilterTemperaturesHistory(this.props.SelectedDevice.Id, this.datepickerInstance.value);
                } else {
                    this.datepickerInstance.value = new Date(new Date().toDateString());

                    if (direction !== 0)
                        this.datepickerInstance.value.setDate(this.datepickerInstance.value.getDate() + direction);

                    this.props.FilterTemperaturesHistory(this.props.SelectedDevice.Id, this.datepickerInstance.value);
                }

            }
    }

    changeDateTemperaturesHistory(direction) {
        let newDate = this.datepickerInstance.value;

        if (newDate === null)
            newDate = new Date(new Date().toDateString())

        let cloneListPrev = this.props?.DataDetailHistorySummary.filter(d => new Date(d.DateTime) < newDate);
        let cloneListnext = this.props?.DataDetailHistorySummary.filter(d => new Date(d.DateTime) > newDate);

        if (cloneListPrev.length < 1)
            this.setState({ enabledPrevHistory: false })
        else this.setState({ enabledPrevHistory: true })

        if (cloneListnext.length < 1)
            this.setState({ enabledNextHistory: false })
        else this.setState({ enabledNextHistory: true })

        if ((direction === -100) || (direction === 100)) {
            let listDateTime = this.props?.DataDetailHistorySummary.filter(d => direction === -100 ? new Date(d.DateTime) < newDate : new Date(d.DateTime) > newDate);
            if (listDateTime.length !== 0)
                newDate = direction === -100 ? new Date(listDateTime[listDateTime.length - 1].DateTime) : new Date(listDateTime[0].DateTime);
            this.datepickerInstance.value = newDate;

            if (cloneListPrev.length === 1)
                this.setState({ enabledPrevHistory: false })
            else this.setState({ enabledPrevHistory: true })

            if (cloneListnext.length === 1)
                this.setState({ enabledNextHistory: false })
            else this.setState({ enabledNextHistory: true })
        }
        else {
            newDate.setDate(newDate.getDate() + direction);
            this.datepickerInstance.navigateTo(newDate);
            this.filterDateTemperaturesHistory(direction);
        }
    }

    renderChartControlHeader() {
        let t = this.props.intl.formatMessage({ id: 'chart_history' });
        if (this.props.Realtime) {
            t = this.props.intl.formatMessage({ id: 'chart_realtime' });
            return (
                <div id='divChartControlHeader' className='row'>
                    <div className='col-xs-12 col-lg-9 col-md-9'>
                        <span className='lblValue'>{t}</span>
                    </div>
                    <div className='col-xs-12 col-lg-3 col-md-3'>
                    </div>
                </div>
            )
        }
/*        if (!this.props.Realtime)
            return (
                <div id='divChartControlHeader' className='row'>
                    <div className='col-xs-12 col-lg-9 col-md-9'>
                        <span className='lblValue'>{t}</span>
                    </div>
                    <div className='col-xs-12 col-lg-3 col-md-3' id='divBtnCrosshair'>
                        <TooltipComponent position='TopCenter' content={this.props.intl.formatMessage({ id: 'crosshair' })} target='#btnCrosshair'>
                            <ButtonComponent id='btnCrosshair' className='ButtonEmpty' onClick={() => this.changeCrosshair()} iconCss='e-icons e-add-new' cssClass="tooltipElement"></ButtonComponent>
                        </TooltipComponent>
                    </div>
                </div>
            )*/
    }

    renderFilterTitle() {
        if (!this.props.Realtime)
            return (
                <span className='lblDescription'><FormattedMessage id='date' /></span>
            )
    }

    customDatesSummary(args) {
        let dateSummary = this.props.DataDetailHistorySummary.filter(summary => new Date(summary.DateTime).getTime() == new Date(args.date).getTime());

        if (dateSummary.length == 1) {
            args.element.headers = 'ppp';
            let spanData = document.createElement('span');
            if (dateSummary[0].HasData === 1)
                spanData.setAttribute('class', 'e-icons highlight data');
            args.element.appendChild(spanData);

            let spanAlarm = document.createElement('span');
            if (dateSummary[0].HasAlarms === 1)
                spanAlarm.setAttribute('class', 'e-icons highlight alarm');
            args.element.appendChild(spanAlarm);

        }
    }

    renderChartDateTimePicker() {
        if (!this.props.Realtime)
            return (
                <div>
                    <DatePickerComponent id='dataFilter'
                        ref={dp => this.datepickerInstance = dp}
                        width='200px'
                        allowEdit={false}
                        showTodayButton={false}
                        cssClass='e-customStyle'
                        change={this.filterDateTemperaturesHistory.bind(this)}
                        renderDayCell={this.customDatesSummary.bind(this)}
                    />
                </div>
            )
    }

    renderChartButtons() {
        if (!this.props.Realtime)
            return (

                <div className='row'>
                    <TooltipComponent position='TopCenter' opensOn='Hover' content={this.props.intl.formatMessage({ id: 'prev_data' })} >
                        <ButtonComponent className='tooltiphover' id={'btnPrevData' + this.state.enabledPrevHistory} disabled={!this.state.enabledPrevHistory} onClick={() => this.changeDateTemperaturesHistory(-100)} iconCss='e-icons e-Data_Prev' cssClass='tooltipElement' ></ButtonComponent>
                    </TooltipComponent>
                    <TooltipComponent position='TopCenter' content={this.props.intl.formatMessage({ id: 'prev_day' })} target='#btnPrevChart'>
                        <ButtonComponent id='btnPrevChart' onClick={() => this.changeDateTemperaturesHistory(-1)} iconCss='e-icons e-Chart_Prev' />
                    </TooltipComponent>
                    <ButtonComponent id='btnToday' className='Button' onClick={() => this.changeDateTemperaturesHistory(0)} ><FormattedMessage id='today' /></ButtonComponent>
                    <TooltipComponent position='TopCenter' content={this.props.intl.formatMessage({ id: 'next_day' })} target='#btnNextChart'>
                        <ButtonComponent id='btnNextChart' onClick={() => this.changeDateTemperaturesHistory(+1)} iconCss='e-icons e-Chart_Next' />
                    </TooltipComponent>
                    <TooltipComponent position='TopCenter'  opensOn='Hover' content={this.props.intl.formatMessage({ id: 'next_data' })} >
                        <ButtonComponent className='tooltiphover' id={'btnNextData' + this.state.enabledNextHistory} disabled={!this.state.enabledNextHistory} onClick={() => this.changeDateTemperaturesHistory(+100)} iconCss='e-icons e-Data_Next' cssClass='tooltipElement' ></ButtonComponent>
                    </TooltipComponent>
                </div>

            )
    }

    changeCrosshair() {
        let tmp = this.state.enableCrosshair;
        this.setState({ enableCrosshair: !tmp });
    }

    renderChartControlBottom() {
        return (
            <div id='divChartControlBottom'>
                <div id='chartRow'>
                    {this.renderFilterTitle()}
                </div>
                <div id='chartDate'>
                    {this.renderChartDateTimePicker()}

                </div>
                <div id='chartBtn'>
                    {this.renderChartButtons()}
                </div>
                <div id='chartExport'>
                    <DropDownButtonComponent id='btnExportChart' className='Button'
                        ref={dd => this.dropdown = dd}
                        items={this.items}
                        fields={this.fields}
                        select={this.handleExport.bind(this)}>
                        <FormattedMessage id='export' />
                    </DropDownButtonComponent>

                </div>
            </div>
        )
    }

    contentFullScreen() {
        return React.cloneElement(this.chartInstance);
    }

    renderCycleBands() {
        let dataBands = this.props?.DataDetailHistory?.DataHistoryCycleBands;
        let dataAlarm = this.props.DataDetailHistory.DataHistoryAlarmBands;
        let bands = new Array();

        if (dataBands !== undefined || dataAlarm !== undefined) {

            for (let i = 0; i < dataBands.length; i++)
                for (let j = 0; j < dataAlarm.length; j++)
                    bands.push({
                        start: new Date(dataBands[i].Start),
                        end: new Date(dataBands[i].End),
                        textStyle: { size: 14, fontFamily: 'Roboto' },
                        opacity: 0.5,
                        color: 'var(--main-color)',
                        text: this.props.intl.formatMessage({ id: 'cycle' }) + ' ' + dataBands[i].Text
                    },
                        {
                            start: new Date(dataAlarm[j].Start),
                            end: new Date(dataAlarm[j].End),
                            dashArray: '9,5',
                            sizeType: 'Pixel',
                            color: 'red',
                            visible: true,
                            textStyle: { size: 14, fontFamily: 'Roboto', color: 'red' },
                            verticalAlignment: 'Start',
                            horizontalAlignment: 'End',
                            text: this.props.intl.formatMessage({ id: 'alarm' }) + ' ' + dataAlarm[j].Text,
                            tooltip: { enable: this.props?.DataDetailHistory?.DataHistoryAlarmBands }
                        }
                    )
            this.chartInstance.primaryXAxis.stripLines = bands;
        }
        else
            this.chartInstance.primaryXAxis.stripLines = null;
    }
    render() {
        let minT = -10;
        let maxT = 150;
        if (this.props?.IsEvo)
            maxT = 190;
        if (this.props.UmTemperature === 'F') {
            maxT = convertFahrenheit(maxT)
            minT = convertFahrenheit(minT)
        }

        return (
            <div id='divChartControl'>
                <span>{this.state.enableCrosshair}</span>
                {this.renderChartControlHeader()}
                <div>
                    <ChartComponent id='chartControl'
                        palettes={this.palette}
                        style={{ textAlign: 'center' }}
                        legendSettings={{ visible: true, position: 'Bottom', location: { x: 10, y: 20 }, textStyle: { fontFamily: 'Roboto' } }}
                        legendShape='Rectangle'
                        crosshair={{ enable: this.state.enableCrosshair }}
                        enableAnimation={false}
                        ref={chart => this.chartInstance = chart}
                        primaryXAxis={{
                            valueType: 'DateTime',
                            intervalType: 'Minutes',
                            labelFormat: 'HH:mm',
                            crosshairTooltip: { enable: this.state.enableCrosshair, textStyle: { fontFamily: 'Roboto' } },
                            labelStyle: { fontFamily: 'Roboto' }
                        }}
                        chartArea={{ border: { width: 0 } }} primaryYAxis={{
                            labelFormat: '{value}°' + this.props.UmTemperature,
                            minimum: minT,
                            maximum: maxT,
                            lineStyle: { width: 0 },
                            majorTickLines: { width: 0 },
                            minorTickLines: { width: 0 },
                            crosshairTooltip: { enable: this.state.enableCrosshair, textStyle: { fontFamily: 'Roboto' } },
                            labelStyle: { fontFamily: 'Roboto' }
                        }}
                        tooltip={{ enable: true }}
                        zoomSettings={{
                            enableMouseWheelZooming: false, enablePinchZooming: true,
                            enableSelectionZooming: true, mode: 'X', enableScrollbar: true
                        }}>
                        <Inject services={[LineSeries, Legend, Crosshair, Category, DateTime, Export, Tooltip, Zoom, DateTimeCategory, StripLine]} />
                        <SeriesCollectionDirective>
                            <SeriesDirective xName='DateTime' yName='TOven' legendShape='Rectangle' width={3} name={this.props.intl.formatMessage({ id: 'toven' })} color='blue' type='Line' marker={{ visible: true, width: 1, height: 1 }}>
                            </SeriesDirective>
                            <SeriesDirective xName='DateTime' yName='TFridge' legendShape='Rectangle' width={3} name={this.props.intl.formatMessage({ id: 'tfridge' })} color='red' type='Line' marker={{ visible: true, width: 1, height: 1 }}>
                            </SeriesDirective>
                            <SeriesDirective xName='DateTime' yName='TProbe' legendShape='Rectangle' width={3} opacity={0} name={this.props.intl.formatMessage({ id: 'tprobe' })} color='green' type='Line' marker={{ visible: true, width: 4, height: 4, fill: 'green' }}>
                            </SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </div>

                {this.renderChartControlBottom()}
                <div id='divHideGrid'>{this.gridChart()}</div>

            </div>
        );
    }
}

ChartControl.propTypes = {
    Realtime: PropTypes.bool,
    IsEvo: PropTypes.bool,
    SelectedDevice: PropTypes.object,
    DataDetailHistory: PropTypes.object,
    DataDetailHistorySummary: PropTypes.any,
    intl: PropTypes.object,
    FilterTemperaturesHistory: PropTypes.func,
    UmTemperature: PropTypes.string
};

export default injectIntl(ChartControl);