import React from 'react';

export function dateTimeTemplate(props) {

    if (this !== undefined) {
        if (this.intl !== undefined)
            if (props.column.field === 'DateTimeInsert')
                return dateInsertTemplate(props, this.intl);
        if (props.column.field === 'DateTimeLastChange')
            return dateLastChangeTemplate(props, this.intl);

        if (this.props.intl !== undefined)
            if (props.column.field === 'DateTimeInsert')
                return dateInsertTemplate(props, this.props.intl);

        if (this.props.intl !== undefined)
            if (props.column.field === 'DateTimeLastChange')
                return dateLastChangeTemplate(props, this.props.intl);
    }

    return (<div></div>);
}

export function dateInsertTemplate(props, intl) {
    try {
        let dataFormat = intl.formatDate(props.DateTimeInsert) + ' ' + intl.formatTime(props.DateTimeInsert);

        if ((props.DateTimeInsert === null))
            return (
                <div></div>
            );
        else
            return (
                <div>{dataFormat}</div>
            );
    }
    catch (exp) {
        return (<div></div>);
    }
}

export function dateLastChangeTemplate(props, intl) {
    try {
        let dataFormat = intl.formatDate(props.DateTimeLastChange) + ' ' + intl.formatTime(props.DateTimeLastChange);

        if ((props.DateTimeLastChange === null))
            return (
                <div></div>
            );
        else
            return (
                <div>{dataFormat}</div>
            );
    }
    catch (exp) {
        return (<div></div>);
    }
}
