import React from 'react';
import PropTypes from 'prop-types';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import '../../styles/manage/deviceDataConfiguration.css';
import ProgressBar from '../../components/ProgressBar';
import { injectIntl, FormattedMessage } from 'react-intl';

class DeviceDataConfiguration extends React.Component {

    constructor(props) {
        super(props);

        this.state = { Detail: props.Detail };
    }

    menuCloseClick() {
        this.props.MenuCloseClick();
    }

    menuSaveClick() {
        this.props.SetData(this.state.Detail);
        this.props.MenuCloseClick();
    }

    componentDidUpdate() {
        if ((this.props?.Selected !== undefined) && (this.props?.Selected?.Id !== this.props?.Detail?.Id)) {

            if(this.state.Detail!==null)
                this.setState({ Detail: null });
            return;
        }
        else {
            let bSetDetail = (this.state.Detail == null);

            if (!bSetDetail)
                bSetDetail = (this.props.Detail.Id !== this.state.Detail.Id);

            if (bSetDetail) {
                this.setState({  Detail: this.props.Detail });
                return;
            }
        }
    }

    deviceIDChange() {
        this.setState({ Detail: { ...this.state.Detail, DeviceID: this.textDeviceID.value } });
    }

    dropdownSiteChange() {
        this.setState({ Detail: { ...this.state.Detail, SiteId: this.listSites.value } });
    }

    dropdownDepartmentChange() {
        this.setState({ Detail: { ...this.state.Detail, DepartmentId: this.listDepartments.value } });
    }

    renderDeviceDataConfiguration() {
        let msgProgressbar =  <span className="lblValue"><FormattedMessage id='waiting' /></span>
        if (this.state.Detail === null)
            return (
                <ProgressBar Id='DeviceDataConfiguration' Option={this.props.Option} Message={msgProgressbar} />
            )


        return (

            <div id="divDeviceDataBottom">
                <div className="row rowDeviceDataConfiguration">
                    <span className="lblDescription"><FormattedMessage id='id_trolley' /></span>

                    <TextBoxComponent id="textDeviceID"
                        ref={textbox => this.textDeviceID = textbox}
                        cssClass="e-outline"
                        value={this.state.Detail?.DeviceID}
                        change={this.deviceIDChange.bind(this)}
                        floatLabelType="Auto" />
                </div>
                <div className="row rowDeviceDataConfiguration">
                    <span className="lblDescription"><FormattedMessage id='site' /></span>

                    <DropDownListComponent id='listSites'
                        dataSource={this.props.ListSites}
                        ref={(dropdownlist) => { this.listSites = dropdownlist; }}
                        fields={{ value: 'Id', text: 'Name' }}
                        popupHeight='auto'
                        value={this.state.Detail?.SiteId}
                        change={this.dropdownSiteChange.bind(this)}
                    />
                </div>
                <div className="row rowDeviceDataConfiguration">
                    <span className="lblDescription"><FormattedMessage id='department' /></span>
                    <DropDownListComponent id='listDepartments'
                        dataSource={this.props.ListDepartments}
                        ref={(dropdownlist) => { this.listDepartments = dropdownlist; }}
                        fields={{ value: 'Id', text: 'Name' }}
                        popupHeight='auto'
                        value={this.state.Detail?.DepartmentId}
                        change={this.dropdownDepartmentChange.bind(this)}
                    />
                </div>
                <div className="row rowDeviceDataConfiguration">
                    <span className="lblDescription"><FormattedMessage id='model' /></span>
                    <TextBoxComponent id="txtModel"
                        ref={text => this.textModel = text}
                        cssClass="e-outline" enabled={false}
                        value={this.state.Detail?.Model}
                        floatLabelType="Auto" />
                </div>
                <div className="row rowDeviceDataConfiguration">
                    <span className="lblDescription"><FormattedMessage id='sn_trolley' /></span>
                    <TextBoxComponent id="textDeviceSN"
                        ref={text => this.textDeviceSN = text}
                        cssClass="e-outline" enabled={false}
                        value={this.state.Detail?.DeviceSN}
                        floatLabelType="Auto" />
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="divConfiguration">
                <div id="divConfigurationTop">
                    <div id="deviceConfig">
                        <span className="lblValue"><FormattedMessage id='configuration_trolley_data' /></span>
                    </div>
                    <div id="configBtn">
                        <ButtonComponent
                            id="btnCloseConfigurations"
                            onClick={() => this.menuCloseClick()}
                            iconCss='e-icons e-FT_close'
                            className="ButtonEmpty"><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div id="configBtnSave">
                        <ButtonComponent
                            id="btnSaveConfigurations"
                            onClick={() => this.menuSaveClick()}
                            disabled={(this.state?.Detail?.Status !== 1)}
                            iconCss='e-icons e-FB_PV_Save'
                            className="Button"><FormattedMessage id='save' /></ButtonComponent>
                    </div >
                </div>
                {this.renderDeviceDataConfiguration()}
            </div>
        );
    }
}

DeviceDataConfiguration.propTypes = {
    Selected: PropTypes.any,
    Detail: PropTypes.object,
    MenuCloseClick: PropTypes.func,
    SetData: PropTypes.func,
    intl: PropTypes.object,
    ListSites: PropTypes.any,
    ListDepartments: PropTypes.any,
    Option: PropTypes.string
};

export default injectIntl(DeviceDataConfiguration);