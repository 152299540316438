// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divMaintenance {\n    padding-bottom: 5px;\n    padding-top: 5px;\n}\n\n\n#scheduleMaintenance {\n    font-family: 'Roboto', sans-serif;\n}", "",{"version":3,"sources":["webpack://./src/styles/maintenancePage.css"],"names":[],"mappings":"AACA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;;AAGA;IACI,iCAAiC;AACrC","sourcesContent":["@import \"app.css\";\n#divMaintenance {\n    padding-bottom: 5px;\n    padding-top: 5px;\n}\n\n\n#scheduleMaintenance {\n    font-family: 'Roboto', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
