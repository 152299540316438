import * as React from 'react';
import PropTypes from 'prop-types';
import '../styles/dialogAbout.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { injectIntl, FormattedMessage } from 'react-intl';

class DialogAlertMenuConfiguration extends React.Component {

    close() {
        this.props.HideAlert();
    }

    paste() {
        this.props.ConfirmPaste()
    }

    renderAlertSaveConfiguration() {
        let idMessage;
        if (this.props.CloneDataSave.Name === '')
            idMessage = 'alert_save_name'
        else
            idMessage = 'alert_save_data'
        if (this.props.Option === 'saveConfiguration')
            return (

                <div id='divDialogAlert'>
                    <h3 id='titleAlert'><FormattedMessage id='warning' /></h3>

                    <div id='messageAlert'>
                        <span><FormattedMessage id={idMessage} /></span>
                    </div>
                    <div className='row divBtnAlert'>
                        <div className='col-xs-12'>
                            <ButtonComponent id='brnAbt' onClick={this.close.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                        </div>
                    </div>
                </div>
            )

        if (this.props.Option === 'pasteConfiguration')
            idMessage = 'alert_paste';
        return (

            <div id='divDialogAlert'>
                <h3 id='titleAlert'><FormattedMessage id='warning' /></h3>

                <div id='messageAlert'>
                    <span><FormattedMessage id={idMessage} />{' ' + this.props.NameConfiguration + ' ?'}</span>
                </div>
                <div className='row divBtnAlert'>
                    <div className='col-xs-6'>
                        <ButtonComponent id='brnAbt' onClick={this.close.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div className='col-xs-6'>
                        <ButtonComponent id='brnAbt' onClick={this.paste.bind(this)}><FormattedMessage id='confirm' /></ButtonComponent>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className="dialog">
                    {this.renderAlertSaveConfiguration()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        )
    }

}

DialogAlertMenuConfiguration.propTypes = {
    HideAlert: PropTypes.func,
    CloneDataSave: PropTypes.any,
    Option: PropTypes.string,
    ConfirmPaste: PropTypes.func,
    NameConfiguration: PropTypes.string

};
export default injectIntl(DialogAlertMenuConfiguration);