// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divParamsConfiguration #divMenuTop .Button {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divParamsConfiguration #divMenuTop.ButtonEmpty {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divParamsConfiguration #divMenuTop .ButtonEmpty {\n    text-align: left;\n    font-size: 12px;\n    width: 100%;\n    height: 40px;\n}\n\n#paramsConfigurationGrid .e-headercelldiv {\n    font-weight: 500;\n    font-size: 12px;\n    color: var(--text-color);\n}\n\n#paramsConfigurationGrid .e-rowcell {\n    font-size: 12px;\n    font-weight: 400;\n    color: var(--text-color);\n}\n\n#renderParamsConfiguration{\n    padding-top: 20px ;\n}", "",{"version":3,"sources":["webpack://./src/styles/manage/paramsConfiguration.css"],"names":[],"mappings":"AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["@import \"../app.css\";\n\n#divParamsConfiguration #divMenuTop .Button {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divParamsConfiguration #divMenuTop.ButtonEmpty {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divParamsConfiguration #divMenuTop .ButtonEmpty {\n    text-align: left;\n    font-size: 12px;\n    width: 100%;\n    height: 40px;\n}\n\n#paramsConfigurationGrid .e-headercelldiv {\n    font-weight: 500;\n    font-size: 12px;\n    color: var(--text-color);\n}\n\n#paramsConfigurationGrid .e-rowcell {\n    font-size: 12px;\n    font-weight: 400;\n    color: var(--text-color);\n}\n\n#renderParamsConfiguration{\n    padding-top: 20px ;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
