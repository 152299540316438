import React from 'react';
import PropTypes from 'prop-types';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Inject, CommandColumn } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, SwitchComponent } from '@syncfusion/ej2-react-buttons';
import ProgressBar from '../../components/ProgressBar';
import '../../styles/manage/cyclesConfiguration.css';
import { IDCyclesConfigurationTemplate } from '../templates/IDCyclesConfigurationTemplate';
import { injectIntl, FormattedMessage } from 'react-intl';
import { convertFahrenheit, convertCelsius } from '../../components/utils/TemperatureConversion';
import isEqual from 'react-fast-compare';
import DialogAlertCycle from '../../components/DialogAlertCycle';

class CyclesConfiguration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            SelectedCycle: null,
            Detail: props.IsConfigurationMenu ? props.Detail : null,
            hideDialogAlertCycle: true,
            EnableAddCycle: true,
            EnableItemCycle: true
        };

    }

    menuCloseClick() {
        this.gridCyclesInstance?.endEdit();
        this.props.MenuCloseClick();
    }

    menuSaveClick() {

        this.gridCyclesInstance?.endEdit();

        let cycles = this.state.Detail.Cycles;

        if (this.props.UmTemperature === 'F') {
            for (let i = 0; i < cycles?.length; i++) {
                for (let j = 0; j < cycles[i]?.CycleItems?.length; j++) {
                    cycles[i].CycleItems[j].TFridge = convertCelsius(cycles[i].CycleItems[j].TFridge);
                    cycles[i].CycleItems[j].TOven = convertCelsius(cycles[i].CycleItems[j].TOven);
                }
            }
        }

        this.props.SetCycles(cycles);
        this.props.MenuCloseClick();
    }

    shouldComponentUpdate(nextProps, nextState) {
        let bShouldUpdate = true;
        if (nextProps?.Selected?.Id === this.state?.Detail?.Id) bShouldUpdate = false;
        if (nextProps?.UmTemperature !== this.props?.UmTemperature) bShouldUpdate = true;
        if (nextState?.SelectedCycle !== this.state?.SelectedCycle) bShouldUpdate = true;
        if (nextState?.hideDialogAlertCycle !== this.state?.hideDialogAlertCycle) bShouldUpdate = true;
        if (!isEqual(nextState?.Detail?.Cycles, this.state?.Detail?.Cycles)) bShouldUpdate = true;

        if (nextState.EnableAddCycle !== this.state.EnableAddCycle) return true;
        if (nextState.EnableItemCycle !== this.state.EnableItemCycle) return true;

        return bShouldUpdate;
    }

    componentDidUpdate(_prevProps, prevState) {

        if ((this.props?.Selected !== undefined) && (this.props?.Selected?.Id !== this.props?.Detail?.Id)) {
            if (this.state.Detail !== null) {
                this.setState({ Detail: null });
                this.dropdownCycles.dataSource = null;
                this.gridCyclesInstance.dataSource = null;
            }
            return;
        }
        else {
            let bSetDetail = (this.state.Detail === null);

            if (!bSetDetail)
                bSetDetail = (this.props.Detail.Id !== this.state.Detail.Id);

            if (bSetDetail) {

                let d = this.props.Detail;
                if (this.props.UmTemperature === 'F') {
                    for (let i = 0; i < d?.Cycles?.length; i++)
                        for (let j = 0; j < d?.Cycles[i]?.CycleItems?.length; j++) {
                            d.Cycles[i].CycleItems[j].TFridge = convertFahrenheit(d.Cycles[i].CycleItems[j].TFridge);
                            d.Cycles[i].CycleItems[j].TOven = convertFahrenheit(d.Cycles[i].CycleItems[j].TOven);
                        }
                }

                this.setState({ SelectedCycle: null, Detail: d });
                return;
            }
        }

        if (this?.gridCyclesInstance)
            if (this.state?.SelectedCycle !== prevState?.SelectedCycle)
                if (this.state?.SelectedCycle === null) {
                    this.gridCyclesInstance.dataSource = null;
                    this.dropdownCycles.updateDataSource();
                }

        if (prevState?.Detail?.Cycles?.length !== this.state?.Detail?.Cycles?.length) {
            this.dropdownCycles.updateDataSource();
            this.dropdownCycles.value = this.state?.SelectedCycle;
        }

        if (prevState?.Detail?.Cycles?.length !== this.state?.Detail?.Cycles?.length)
            if (prevState.EnableCycle === this.state.EnableCycle)
                this.setState({ EnableCycle: (this.state?.Detail?.Cycles?.length < this.props?.Detail?.MaxCycles) });


        if (this.state?.Detail?.Cycles !== null)
            if (this.state?.Detail?.Cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.CycleItems !== undefined)
                if (prevState.EnableItemCycle === this.state.EnableItemCycle)
                    this.setState({ EnableItemCycle: (this.state?.Detail?.Cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.CycleItems?.length < this.props?.Detail?.MaxItemCycles) });

    }

    dropdownCyclesChanged(args) {
        if (args.isInteracted)
            this.setState({ SelectedCycle: this.dropdownCycles.value });
    }

    cycleTypeChanged() {
        let cycleIndex = this.state?.Detail?.Cycles?.findIndex(c => c.ID == this.state.SelectedCycle);
        let tmp = JSON.parse(JSON.stringify(this.state.Detail));

        tmp.Cycles[cycleIndex].Type = (this.switchCycleType.checked) ? 1 : 0;

        this.setState({ Detail: tmp });
    }

    cyclePreferredChanged() {

        if (this.switchCyclePreferred.checked === true)
            this.switchCyclePreferred.checked = false;
        else
            this.switchCyclePreferred.checked = true;

        let cycleIndex = this.state?.Detail?.Cycles?.findIndex(c => c.ID == this.state.SelectedCycle);
        let tmp = JSON.parse(JSON.stringify(this.state.Detail));

        tmp.Cycles[cycleIndex].IsPreferred = this.switchCyclePreferred.checked;

        this.setState({ Detail: tmp });
    }

    cycleDescriptionChanged() {
        let tmp = JSON.parse(JSON.stringify(this.state.Detail));

        let cycle = tmp?.Cycles.filter(c => c.ID == this.state.SelectedCycle)[0];

        if (cycle !== undefined) {
            cycle.Description = this.textCycleDescription.value;
            this.setState({ Detail: tmp });
        }
    }

    renderAddCycle() {
        if (this.state?.Detail?.DeviceVersion !== 'v3')
            return null;

        return (
            <ButtonComponent className='ButtonEmpty' iconCss='e-icons e-add-new' disabled={!this.state.EnableAddCycle} onClick={() => this.addCycle()} />
        )
    }

    renderDeleteCycle() {
        if (this.state?.Detail?.DeviceVersion !== 'v3')
            return null;

        return (
            <ButtonComponent className='ButtonEmpty' iconCss='e-icons e-FB_Delete' onClick={() => this.deleteCycle()} />
        )
    }

    addCycle() {
        let cycles = JSON.parse(JSON.stringify(this.state?.Detail?.Cycles));
        let empty = JSON.parse(JSON.stringify(this.state?.Detail?.EmptyCycle));


        let newCycleId = cycles.length + 1;

        for (let i = 0; i < cycles.length; i++)
            if (cycles[i]?.ID !== i + 1) {
                newCycleId = i + 1;
                break;
            }

        empty.ID = newCycleId;
        empty.IDCycle = newCycleId;
        empty.Name = 'N.' + newCycleId;

        empty.CycleItems[0].ID = newCycleId;

        if (this.props.UmTemperature === 'F') {
            empty.CycleItems[0].TOven = convertFahrenheit(empty.CycleItems[0].TOven);
            empty.CycleItems[0].TFridge = convertFahrenheit(empty.CycleItems[0].TFridge);
        }

        cycles.push(empty);

        cycles.sort(function (a, b) {
            return parseInt(a.ID) - parseInt(b.ID)
        });

        this.setState({ Detail: { ...this.state.Detail, Cycles: cycles }, SelectedCycle: newCycleId });
    }

    hideAlertCycle() {
        this.setState({ hideDialogAlertCycle: true })
    }

    renderDialogAlert() {
        if (!this.state.hideDialogAlertCycle)
            return (
                <DialogAlertCycle HideAlertCycle={this.hideAlertCycle.bind(this)} />
            );
    }

    deleteCycle() {

        if (this.state?.Detail?.Starts.filter(s => s.Cycle === this.state.SelectedCycle).length !== 0) {
            this.setState({ hideDialogAlertCycle: false });
        }
        else {
            if (this.state?.SelectedCycle !== null) {
                let cycles = JSON.parse(JSON.stringify(this.state?.Detail?.Cycles));
                cycles = cycles.filter(c => c.ID !== this.state.SelectedCycle);

                this.dropdownCycles.resetSelection();
                this.setState({ Detail: { ...this.state.Detail, Cycles: cycles }, SelectedCycle: null });
            }
        }
    }

    renderAddCycleItem() {
        if (this.state?.Detail?.DeviceVersion !== 'v3')
            return null;

        if (this.state?.SelectedCycle === null)
            return null;

        return (
            <ButtonComponent className='ButtonEmpty' iconCss='e-icons e-add-new' disabled={!this.state.EnableItemCycle} onClick={() => this.addCycleItem()} />
        )
    }

    addCycleItem() {
        let cycles = JSON.parse(JSON.stringify(this.state?.Detail?.Cycles));
        let emptyItem = JSON.parse(JSON.stringify(this.state?.Detail?.EmptyCycle?.CycleItems[0]));

        emptyItem.ID = this.state?.SelectedCycle;

        let items = cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.CycleItems;

        if (this.props.UmTemperature === 'F') {
            emptyItem.TOven = convertFahrenheit(emptyItem.TOven);
            emptyItem.TFridge = convertFahrenheit(emptyItem.TFridge);
        }

        items.push(emptyItem);

        for (let i = 0; i < cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.CycleItems.length; i++)
            items[i].IDCycleItem = i + 1;

        cycles.filter(c => c.ID === this.state?.SelectedCycle)[0].CycleItems = items;

        this.setState({ Detail: { ...this.state.Detail, Cycles: cycles } });
    }

    renderCyclePreferred() {
        let cyclePreferred;
        let selectCyclePreferred = <img id='yellowStar' src={'/images/icons/starYellow.svg'} onClick={this.cyclePreferredChanged.bind(this)}></img>
        let unselectCyclePreferred = <img id='whiteStar' src={'/images/icons/starWhite.svg'} onClick={this.cyclePreferredChanged.bind(this)}></img>
        try {

            if (this.state.Detail.DeviceVersion !== 'v3')
                return null;

            cyclePreferred = this.state.Detail.Cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.IsPreferred;

            if (cyclePreferred === undefined)
                return null;

            return (
                <div id='divCyclePreferred'>
                    <div className='row'>
                        <div className='col-md-3'><span className='lblValue'><FormattedMessage id='cycle_preferred' /></span></div>
                        <div id='colSwitchCyclePreferred' className='col-md-9'>
                            {cyclePreferred === true ? (selectCyclePreferred) : (unselectCyclePreferred)}
                            <div id='hiddenSwitch'>
                                <SwitchComponent id='switchCyclePreferred'
                                    ref={sw => this.switchCyclePreferred = sw}
                                    checked={cyclePreferred}
                                ></SwitchComponent> </div>
                        </div>
                    </div>
                </div>
            )
        }
        catch (ex) {
            return null;
        }
    }

    renderCycleDescription() {
        try {
            if (this.state.Detail.DeviceVersion == 'v1')
                return null;

            if (this.state.Detail.DeviceVersion == 'v2')
                if ((!this.state.Detail.IsEvo) || (this.state.SelectedCycle < 9))
                    return null;

            let cycleDescription = this.state.Detail.Cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.Description;

            if (cycleDescription === undefined)
                return null;

            return (
                <div id='divCycleDescription'>
                    <TextBoxComponent id='textCycleDescription'
                        ref={text => this.textCycleDescription = text}
                        cssClass='e-outline'
                        floatLabelType='Auto'
                        value={cycleDescription}
                        change={this.cycleDescriptionChanged.bind(this)} />
                </div>
            )
        }
        catch (ex) {
            return null;
        }
    }

    renderCycleType() {
        let cycleType;
        try {
            if (this.state.Detail.DeviceVersion === 'v1')
                return null;

            cycleType = this.state.Detail.Cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.Type;

            if (cycleType === undefined)
                return null;

            return (
                <div id='divCycleType'>
                    <div className='row'>
                        <div className='col-md-3'><span className='lblValue'><FormattedMessage id='cycle_type' /></span></div>
                        <div id='colSwitchCycleType' className='col-md-9'>
                            <SwitchComponent id='switchCycleType'
                                ref={sw => this.switchCycleType = sw}
                                offLabel={this.props.intl.formatMessage({ id: 'cycle_normal' })} onLabel={this.props.intl.formatMessage({ id: 'cycle_jolly' })}
                                checked={cycleType}
                                change={this.cycleTypeChanged.bind(this)}></SwitchComponent>
                        </div>
                    </div>
                </div>
            )
        }
        catch (ex) {
            return null;
        }
    }

    renderColdColumn() {

        let minT = 0;
        let maxT = 50;
        let umTemp = this.props.UmTemperature;
        if (umTemp === 'F') {
            maxT = convertFahrenheit(maxT)
            minT = convertFahrenheit(minT)
        }

        if ((this.state.Detail.DeviceVersion === 'v1') && (this.state.SelectedCycle < 4))
            return null;

        return (
            <ColumnDirective field='TFridge' headerText={this.props.intl.formatMessage({ id: 'tfridge' })} editType='numericedit' format='N'
                edit={{ params: { min: minT, max: maxT, decimals: 0, format: 'N' } }}
                template={this.umTemplateTFridge} UmTemperature={this.props.UmTemperature}
            ></ColumnDirective>
        )
    }

    enableTemplate(props) {
        if (props.EnableFan)
            return (
                <span><img id='fan' src={'/images/icons/fan.svg'} ></img></span>
            )
        else {
            return (
                <span><img id='fanStop' src={'/images/icons/fanStop.svg'} ></img></span>
            )
        }
    }

    renderEnableFanColumn() {
        if (this.state?.Detail?.DeviceVersion !== 'v3')
            return null;

        return (
            <ColumnDirective field='EnableFan' headerText={this.props.intl.formatMessage({ id: 'enablefan' })}
                displayAsCheckBox={false} template={this.enableTemplate} editType='booleanedit' />
        )
    }

    renderDeleteCycleItemColumn() {
        try {
            if (this.state?.Detail?.DeviceVersion !== 'v3')
                return null;

            if (this.state?.Detail?.Cycles.filter(c => c.ID === this.state?.SelectedCycle)[0].CycleItems?.length <= 1)
                return null;

            return (
                <ColumnDirective field='' headerText=' ' commands={[{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]}></ColumnDirective>
            )
        }
        catch (ex) {
            return null;
        }
    }

    deleteCycleItem(args) {
        args.cancel = true;

        let cycles = JSON.parse(JSON.stringify(this.state?.Detail?.Cycles));

        let items = cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.CycleItems;

        items = items.filter(i => i.IDCycleItem !== args.rowData.IDCycleItem);

        for (let i = 0; i < items.length; i++) {
            items[i].IDCycleItem = i + 1;
        }

        items.sort(function (a, b) {
            return parseInt(a.IDCycleItem) - parseInt(b.IDCycleItem)
        });

        cycles.filter(c => c.ID === this.state?.SelectedCycle)[0].CycleItems = items;

        this.setState({ Detail: { ...this.state.Detail, Cycles: cycles } });
    }

    umTemplateTOven(props) {
        if (props.EnableFan === false)
            return (
                <span id='spanEnableToven'>---</span>
            )
        else
            return (
                <span>{props.TOven}°{props.column.UmTemperature}</span>
            )
    }

    umTemplateTFridge(props) {
        return (
            <span>{props.TFridge}°{props.column.UmTemperature}</span>
        )
    }

    renderCyclesConfigurationGrid() {

        let minT = 0;
        let maxT = 130;
        let umTemp = this.props.UmTemperature;
        if (umTemp === 'F') {
            maxT = convertFahrenheit(maxT)
            minT = convertFahrenheit(minT)
        }

        if (this.state.Detail === null)
            return (
                <ProgressBar Id='CyclesConfiguration' Option={this.props.Option} Message={this.props.intl?.formatMessage({ id: 'waiting' })} />
            )

        return (

            <div id='divCyclesBottom'>

                <div id='divListCycles'>
                    <DropDownListComponent id='listCycles'
                        dataSource={this.state?.Detail?.Cycles}
                        ref={(dropdownlist) => { this.dropdownCycles = dropdownlist; }}
                        fields={{ value: 'ID', text: 'Name' }}
                        popupHeight='200px'
                        placeholder={this.props.intl?.formatMessage({ id: 'select_cycle' })}
                        change={this.dropdownCyclesChanged.bind(this)}
                    />
                    <div className='row'>
                        <div className='col-xs-12 btnCycleV3'>


                            {this.renderAddCycle()}
                            {this.renderDeleteCycle()}
                        </div>
                    </div>
                </div>
                {this.renderCycleDescription()}
                {this.renderCyclePreferred()}
                {this.renderCycleType()}

                <div className='row'>
                    <div className='col-xs-12 btnCycleV3'>


                        {this.renderAddCycleItem()}
                    </div>
                </div>
                {this.state?.Detail?.Cycles?.filter(c => c.ID === this.state?.SelectedCycle)[0]?.CycleItems && <GridComponent id='cyclesConfigurationGrid'
                    ref={grid => this.gridCyclesInstance = grid}
                    editSettings={{ allowEditing: true, allowAdding: this.state?.Detail?.DeviceVersion === 'v3', allowDeleting: this.state?.Detail?.DeviceVersion === 'v3', mode: 'Dialog' }}
                    commandClick={this.deleteCycleItem.bind(this)}
                    allowSorting={false}
                    allowSelection={false}
                    dataSource={this.state.Detail.Cycles.filter(c => c.ID === this.state?.SelectedCycle)[0]?.CycleItems}
                    className='comm-grid'>
                    <ColumnsDirective>
                        <ColumnDirective field='IDCycleItem' headerText={this.props.intl.formatMessage({ id: 'id' })} allowEditing={false} template={IDCyclesConfigurationTemplate} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective field='Minutes' headerText={this.props.intl.formatMessage({ id: 'minutes' })}
                            editType='numericedit' format='N' edit={{ params: { min: 0, max: 90, decimals: 0, format: 'N' } }}></ColumnDirective>
                        <ColumnDirective field='TOven' headerText={this.props.intl.formatMessage({ id: 'toven' })} editType='numericedit'
                            edit={{ params: { min: minT, max: maxT, decimals: 0, format: 'N' } }}
                            template={this.umTemplateTOven} UmTemperature={this.props.UmTemperature}
                        ></ColumnDirective>
                        {this.renderColdColumn()}
                        {this.renderEnableFanColumn()}
                        {this.renderDeleteCycleItemColumn()}
                    </ColumnsDirective>
                    <Inject services={[Edit, CommandColumn]} />
                </GridComponent>
                }
            </div>
        );
    }

    render() {
        let idTxtSave = 'save';
        let btnId = 'configBtn';
        let btnIdSave = 'configBtnSave'
        if (this.props.Option === 'ConfigurationPage') idTxtSave = 'confirm', btnId = 'configBtnPage', btnIdSave = 'configBtnSavePage';
        return (
            <div className='divConfiguration'>
                <div id=' divConfigurationTop'>
                    <div id='cycleConfig'>
                        <span className='lblValue'><FormattedMessage id='configuration_cycles' /></span>
                    </div>
                    <div id={btnId}>
                        <ButtonComponent
                            id='btnCloseConfigurations'
                            onClick={() => this.menuCloseClick()}
                            iconCss='e-icons e-FT_close'
                            className='ButtonEmpty'><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div id={btnIdSave}>
                        <ButtonComponent
                            id='btnSaveConfigurations'
                            onClick={() => this.menuSaveClick()}
                            disabled={(this.props.Option === 'MonitorPage' && this.state?.Detail?.Status !== 1)}
                            iconCss='e-icons e-FB_PV_Save'
                            className='Button'><FormattedMessage id={idTxtSave} /></ButtonComponent>
                    </div>
                </div >
                {this.renderDialogAlert()}
                {this.renderCyclesConfigurationGrid()}
            </div>
        );
    }
}

CyclesConfiguration.propTypes = {
    IsConfigurationMenu: PropTypes.bool,
    Selected: PropTypes.object,
    Detail: PropTypes.object,
    MenuCloseClick: PropTypes.func,
    SetCycles: PropTypes.func,
    intl: PropTypes.object,
    UmTemperature: PropTypes.string,
    Option: PropTypes.string
};

export default injectIntl(CyclesConfiguration);