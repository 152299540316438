import React from 'react';
import PropTypes from 'prop-types';
import ImageModel from './ImageModel'

export function AvatarModel(props) {
    return (
        <div id="DeviceIDImage" className="e-avatar e-avatar-circle"  >
            <ImageModel theme={props.theme} model={props.model} version={props.version} width={props.width} height={props.height} />
        </div>);

}


AvatarModel.propTypes = {
    model: PropTypes.number,
    version: PropTypes.string,
    width:PropTypes.number,
    height:PropTypes.number,
    theme:PropTypes.string
  };
  
export default AvatarModel;
