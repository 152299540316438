import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import SidebarManage from '../components/SidebarManage';
import SidebarHistoryUSB from '../components/SidebarHistoryUSB';
import '../styles/usbPage.css';

class USBPage extends React.Component {

    constructor() {
        super(...arguments);
        this.data = ['C:', 'D:', 'N: ', 'W: '];
        this.state = {
            SidebarHistoryVisible: 0,
            SidebarManageVisible: 0
        };
    }

    openManageUSB() {
        if (this.state.SidebarManageVisible === 0) {
            this.setState({ SidebarManageVisible: 2 });
        }
        else {
            this.setState({ SidebarManageVisible: 0 });
        }
    }

    renderManageUSB() {
        if (this.state.SidebarManageVisible === 2)
            return (
                <div id='divBlockSideBarManageUSB'>
                    <SidebarManage
                        Option='USBPage'
                        SidebarManageVisible={this.state.SidebarManageVisible}
                        openManageUSB={this.openManageUSB.bind(this)} />
                </div>
            )
    }

    saveDataUSB() {
        <div><span>Data Save</span></div>
    }

    openHistoryUSB() {
        if (this.state.SidebarHistoryVisible === 0) {
            this.setState({ SidebarHistoryVisible: 1 });
        }
        else {
            this.setState({ SidebarHistoryVisible: 0 });
        }
    }

    renderHistoryUSB() {
        if (this.state.SidebarHistoryVisible === 1)
            return (
                <SidebarHistoryUSB
                    SidebarHistoryVisible={this.state.SidebarHistoryVisible}
                    openHistoryUSB={this.openHistoryUSB.bind(this)}
                />
            )
    }

    installProgram() {
        let sistOp;

        if (this === 1) sistOp = 'windows'
        if (this === 2) sistOp = 'linux_deb'
        if (this === 3) sistOp = 'linux_rpm'
        if (this === 4) sistOp = 'mac'

        return (
            
            <div><span>{sistOp}</span></div>
            
        )
    }

    close() {
        this.props.HideUsb()
    }

    renderUSBPage() {

        return (
            <div id='divRenderUSB'>
                <div className='row'>
                    <div className='col-sm-10'></div>
                    <div className='col-sm-2' >
                        <ButtonComponent id='btn' onClick={this.close.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                </div>
                <div id='availableDisks'>
                    <h1 id='titleDisks'><FormattedMessage id='disk_drive_available' /></h1>
                    <ListViewComponent id='list' dataSource={this.data}></ListViewComponent>

                </div>

                <div className='row' id='divBtnConfig'>
                    <div className='col-sm-6'>
                        <ButtonComponent id='btnConfig' onClick={this.openHistoryUSB.bind(this)}><FormattedMessage id='storico' /></ButtonComponent>
                    </div>
                    <div className='col-sm-6'>
                        <ButtonComponent id='btnConfig' onClick={this.openManageUSB.bind(this)}><FormattedMessage id='configurazione' /></ButtonComponent>
                    </div>
                </div>

                <div className='row' id='divBtnSystemOp'>
                   
                    <div className='col-sm-3'>
                        <div className='row' id='opSystem'>
                            <div className='col-sm-12'>
                                <img id='logoSystem' src={'/images/icons/windows.png'} />
                            </div>
                            <div className='col-sm-12'>
                                <ButtonComponent id='btnSystem' onClick={this.installProgram.bind(1)} iconCss='e-icons e-F_Download_usb' ><FormattedMessage id='Windows' /><p id='subTitle'>Windows 7, 8, 10</p></ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='row' id='opSystem'>
                            <div className='col-sm-12'>
                                <img id='logoSystem' src={'/images/icons/linux.png'} />
                            </div>
                            <div className='col-sm-12'>
                                <ButtonComponent id='btnSystemLnx' onClick={this.installProgram.bind(2)} iconCss='e-icons e-F_Download_usb' ><FormattedMessage id='.deb' /><p id='subTitle'>Debian, Ubuntu</p></ButtonComponent>
                                <ButtonComponent id='btnSystemLnx' onClick={this.installProgram.bind(3)} iconCss='e-icons e-F_Download_usb' ><FormattedMessage id='.rpm' /><p id='subTitle'>Red hat, Fedora, SUSE</p></ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='row' id='opSystem'>
                            <div className='col-sm-12'>
                                <img id='logoSystem' src={'/images/icons/mac.png'} />
                            </div>
                            <div className='col-sm-12'>
                                <ButtonComponent id='btnSystem' onClick={this.installProgram.bind(4)} iconCss='e-icons e-F_Download_usb' ><FormattedMessage id='Mac' /><p id='subTitle'>macOS 10.10+</p></ButtonComponent>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )

    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className="dialogUSB">
                    {this.renderHistoryUSB()}
                    {this.renderManageUSB()}
                    {this.renderUSBPage()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        )
    }

}
USBPage.propTypes = {
    intl: PropTypes.object,
    HideUsb: PropTypes.func
};

export default injectIntl(USBPage);