// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divBase {\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\n#title {\n    color: var(--toolbar-color-light);\n}\n\n@media only screen and (max-width: 400px) {\n    #divBase .lblInfo{\n        font-size: x-small;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/base.css"],"names":[],"mappings":"AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["@import \"app.css\";\n#divBase {\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\n#title {\n    color: var(--toolbar-color-light);\n}\n\n@media only screen and (max-width: 400px) {\n    #divBase .lblInfo{\n        font-size: x-small;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
