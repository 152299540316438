import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/historyAlarmsPage.css';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, VirtualScroll, Sort, ExcelExport } from '@syncfusion/ej2-react-grids';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import SidebarHistoryAlarms from '../components/SidebarHistoryAlarms.js';

import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';


import CommonDataManager from '../components/CommonDataManager'
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class HistoryAlarmsPage extends React.Component {

    constructor(props) {
        super(props);

        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            HistoryAlarmsData: [],
            SelectedAlarm: [],
            SidebarHistoryAlarmsVisible: 0,
            FromDate: this.today,
            ToDate: this.today,
        };

        let initialState = { Language: this.state.Language, Filter: { FromDate: this.state.FromDate, ToDate: this.state.ToDate } };
        this.vm = dotnetify.react.connect('HistoryAlarms', this, { vmArg: initialState });
    }

    updateLanguage() {
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: this.commonData.getLanguage(), Messages: this.commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('HistoryAlarmsPage');

        if (this.daterangepickerInstance !== undefined) {
            this.daterangepickerInstance.startDate = this.state.FromDate;
            this.daterangepickerInstance.endDate = this.state.ToDate;
        }
    }
    componentWillUnmount() {
        this.vm?.$destroy();

        this.commonData?.unsubscribe(this.updateLanguage);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState?.RouteInfo !== this.state?.RouteInfo) {

            if (this.state?.RouteInfo === 'LastAlarms') {
                let filtriDashboard = -5;
                let newDate = new Date(new Date().toDateString());
                newDate.setDate(newDate.getDate() + filtriDashboard);
                this.daterangepickerInstance.startDate = newDate;
                this.daterangepickerInstance.endDate = this.state.ToDate;
            }
        }
    }

    filterDateAlarmsHistory() {
        if (this.daterangepickerInstance.startDate !== undefined)
            if (this.daterangepickerInstance.endDate !== undefined) {
                this.vm?.$dispatch({ Filter: { FromDate: this.daterangepickerInstance.startDate, ToDate: this.daterangepickerInstance.endDate } });
                this.clearSelection();
            }
    }

    exportAlarmsHistory() {
        let pathExcelExport = this.intl.formatMessage({ id: 'alarm' }) + '_' + this.intl.formatDate(this.daterangepickerInstance.startDate) + '_' + this.intl.formatDate(this.daterangepickerInstance.endDate) + '.xlsx';
        let cloneColumns = JSON.parse(JSON.stringify(this.gridInstance.columns));
        this.gridInstance?.excelExport({ includeHiddenColumn: true, columns: cloneColumns.filter(c => c.exportexcel), fileName: pathExcelExport });
    }

    clearSelection() {
        let cloneAlarms = JSON.parse(JSON.stringify(this.state.HistoryAlarmsData));

        for (let i = 0; i < cloneAlarms.length; i++)
            cloneAlarms[i].Selected = false;

        this.setState({ HistoryAlarmsData: cloneAlarms, SidebarHistoryAlarmsVisible: 0, SelectedAlarm: [] });
    }

    changeAlarmHistory(direction) {

        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state?.SelectedAlarm?.Id);

        if (direction == -1)
            selectedRowIndex--;

        if (direction == 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) return;

        if (selectedRowIndex > pageRows.length - 1) return;

        let newId = pageRows[selectedRowIndex].Id;

        let cloneHistoryAlarmsData = JSON.parse(JSON.stringify(this.state.HistoryAlarmsData));

        for (let i = 0; i < cloneHistoryAlarmsData.length; i++)
            cloneHistoryAlarmsData[i].Selected = cloneHistoryAlarmsData[i].Id === newId;

        this.setState({ HistoryAlarmsData: cloneHistoryAlarmsData, SelectedAlarm: cloneHistoryAlarmsData.filter(h => h.Selected)[0] });
    }

    rowAlarmSelected() {
        var selected;
        var id = -1;

        if (this.gridInstance.getSelectedRecords().length == 1) {
            id = this.gridInstance.getSelectedRecords()[0].Id;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        if (selected == undefined)
            id = -1;
        else
            if (selected.Selected)
                id = -1;

        var cloneAlarms = JSON.parse(JSON.stringify(this.state)).HistoryAlarmsData;

        for (let i = 0; i < cloneAlarms.length; i++) {
            cloneAlarms[i].Selected = (cloneAlarms[i].Id == id);
        }

        this.setState({ HistoryAlarmsData: cloneAlarms, SidebarHistoryAlarmsVisible: (id != -1) ? 1 : 0, SelectedAlarm: selected });
    }

    rowTemplate(props) {
        return (
            <tr className='templateRow'>
                <td className='deviceId'>
                    <table className='deviceText' cellPadding={3} cellSpacing={2}>
                        <colgroup>
                            <col style={{ width: '50%' }} />
                            <col style={{ width: '50%' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className='historyAlarm'><b>{this.intl.formatMessage({ id: 'alarm' })}</b></td>
                                <td id='testo'>{props.AlarmCode} </td>
                            </tr>

                            <tr>
                                <td className='detailsTrolley'><b>{this.intl.formatMessage({ id: 'id_trolley' })}</b></td>
                                <td id='testo'> {props.DeviceID} </td>
                            </tr>
                        </tbody>
                    </table>
                </td>

                <td className='deviceDetails'>
                    <table className='deviceText' cellPadding={3} cellSpacing={2}>
                        <colgroup>
                            <col style={{ width: '50%' }} />
                            <col style={{ width: '50%' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className='detailsdate'><b>{this.intl.formatMessage({ id: 'date' })}</b></td>
                                <td id='testo'>{this.intl.formatDate(props.DateTime)} {this.intl.formatTime(props.DateTime)}</td>
                            </tr>
                            <tr>
                                <td className='detailsdate'><b>{this.intl.formatMessage({ id: 'site' })}</b></td>
                                <td id='testo'>{props.Site} </td>
                            </tr>

                            <tr>
                                <td className='Department'><b>{this.intl.formatMessage({ id: 'department' })}</b></td>
                                <td id='testo'>{props.Department} </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    }

    gridTemplate() {
        if (window.innerWidth < this.commonData.getMobileWidth())

            return (
                <div>
                    <GridComponent id='historyAlarmsGrid' ref={grid => this.gridInstance = grid}
                        dataSource={this.state.HistoryAlarmsData} enableHover={true} enableVirtualization={false}
                        allowFiltering={true} enableToggle={true}
                        allowExcelExport={true}
                        filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                        allowSorting={true} allowSelection={true}
                        delayUpdate={true}
                        rowTemplate={this.rowTemplate.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field='Id' visible={false} isPrimaryKey={true}></ColumnDirective>
                            <ColumnDirective field='AlarmCode' headerText={this.intl.formatMessage({ id: 'alarm' })} width='40%' textAlign='Center' exportexcel={true}></ColumnDirective>
                            <ColumnDirective headerText={this.intl.formatMessage({ id: 'details' })} width='60%' allowFiltering={false} allowSorting={false} />
                            <ColumnDirective field='DateTime' headerText={this.intl.formatMessage({ id: 'date' })} width='10%' type='datetime' format={{ type: 'dateTime', skeleton: 'short' }} visible={false} exportexcel={true}></ColumnDirective>
                            <ColumnDirective field='DeviceID' headerText={this.intl.formatMessage({ id: 'id_trolley' })} visible={false} exportexcel={true}></ColumnDirective>
                            <ColumnDirective field='Site' headerText={this.intl.formatMessage({ id: 'site' })} visible={false} exportexcel={true} />
                            <ColumnDirective field='DeviceSN' visible={false} exportexcel={true} />
                            <ColumnDirective field='Department' headerText={this.intl.formatMessage({ id: 'department' })} visible={false} exportexcel={true}></ColumnDirective>

                        </ColumnsDirective>
                        <Inject services={[Filter, Sort, ExcelExport]} />
                    </GridComponent>

                </div>

            )
        else

            return (
                <div id='divHistoryAlarmsGrid'>
                    <GridComponent id='historyAlarmsGrid' ref={grid => this.gridInstance = grid}
                        dataSource={this.state.HistoryAlarmsData} enableHover={true} enableVirtualization={false}
                        allowFiltering={true} enableToggle={true}
                        allowExcelExport={true}
                        filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                        allowSorting={true} allowSelection={true}
                        delayUpdate={true}
                        rowSelected={this.rowAlarmSelected.bind(this)}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='Id' visible={false} isPrimaryKey={true}></ColumnDirective>
                            <ColumnDirective field='Selected' headerText='' width='50px' displayAsCheckBox={true} allowFiltering={false}></ColumnDirective>
                            <ColumnDirective field='DateTime' headerText={this.intl.formatMessage({ id: 'date' })} width='10%' type='datetime' format={{ type: 'dateTime', skeleton: 'short' }} exportexcel={true}></ColumnDirective>
                            <ColumnDirective field='AlarmCode' headerText={this.intl.formatMessage({ id: 'alarm' })} width='10%' textAlign='Center' exportexcel={true}></ColumnDirective>
                            <ColumnDirective field='DeviceID' headerText={this.intl.formatMessage({ id: 'id_trolley' })} width='20%' exportexcel={true}></ColumnDirective>
                            <ColumnDirective field='Site' headerText={this.intl.formatMessage({ id: 'site' })} width='30%' exportexcel={true} />
                            <ColumnDirective field='DeviceSN' visible={false} exportexcel={true} />
                            <ColumnDirective field='Department' headerText={this.intl.formatMessage({ id: 'department' })} width='30%' exportexcel={true}></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Filter, Sort, VirtualScroll, ExcelExport]} />
                    </GridComponent>
                    <SidebarHistoryAlarms
                        SidebarHistoryAlarmsVisible={this.state.SidebarHistoryAlarmsVisible}
                        SelectedAlarm={this.state.SelectedAlarm}
                        ClearSelection={this.clearSelection.bind(this)}
                        ChangeAlarmHistory={this.changeAlarmHistory.bind(this)}>
                    </SidebarHistoryAlarms>

                </div>
            )
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divToolbarHistoryAlarms' >
                <BasePage title={this.intl.formatMessage({ id: 'menuheader.alarms' })} navigation={this.intl.formatMessage({ id: 'menuheader.alarms' })}></BasePage>
                    <div id='divTestToolbarHistoryCell' className='row' >
                        <div className='testToolbarHistoryCell col-sm-12 col-md-10 col-lg-10'><span id='textFilter'><FormattedMessage id='filter' /></span>
                            <DateRangePickerComponent id='datarangeFilter' format={{ type: 'date', skeleton: 'short' }}
                                startDate={this.state.FromDate} endDate={this.state.ToDate}
                                allowEdit={false} delayUpdate={true}
                                change={this.filterDateAlarmsHistory.bind(this)}
                                ref={calendar => this.daterangepickerInstance = calendar}>
                                <PresetsDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'today' })} start={this.today} end={this.today}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'this_week' })} start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'this_month' })} start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'last_month' })} start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'last_year' })} start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                </PresetsDirective>
                            </DateRangePickerComponent>
                        </div>
                        <div className='testBtnExport col-sm-12 col-md-2 col-lg-2'>
                            <ButtonComponent id='btnExport' className='Button'
                                disabled={this.state?.HistoryAlarmsData.length <= 0}
                                delayUpdate={true}
                                onClick={() => this.exportAlarmsHistory()}><FormattedMessage id='export' /></ButtonComponent>
                        </div>
                    </div>
                    <div>
                        {this.gridTemplate()}
                    </div>
                </div>

            </IntlProvider >
        )
    }
}

HistoryAlarmsPage.propTypes = {
};

export default HistoryAlarmsPage;