import React from 'react';
import LoginPage from './LoginPage';
import AppLayout from './AppLayout';
import dotnetify from 'dotnetify';
import auth from '../auth';

//dotnetify.hubServerUrl='http://comm.rpwebs.net:8843';
//dotnetify.hubServerUrl='http://localhost:5555';
//dotnetify.debug=true;
dotnetify.hub.reconnectRetry = 10;
dotnetify.hub.reconnectDelay = [5, 30];

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Authenticated: auth.hasAccessToken(),
            Connected: true,
            ConnectionRetry: 0
        };

        dotnetify.connectionStateHandler = (connectionState) => {

            if (connectionState == 'disconnected')
                this.setState({ ConnectionRetry: this.state.ConnectionRetry + 1 });

            if (connectionState == 'connected')
                this.setState({ ConnectionRetry: 0 });

            if (this.state.ConnectionRetry > 5)
                this.setState({ Connected: false });
            else
                this.setState({ Connected: true });
        };
    }


    authenticate()
    {
        this.setState({ Authenticated: true });
    }


    render() {
        if (this.state?.Authenticated)
            if (this.state?.Connected)
                return (
                    <AppLayout />
                )

        return (
            <LoginPage Authenticate={this.authenticate.bind(this)} />
        )
    }
}

App.propTypes = {
};

export default App;