import * as React from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import '../styles/dialogUploader.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import AvatarTemplate from '../components/templates/AvatarTemplate';

class DialogUploader extends React.Component {

    constructor(props) {
        super(props);

        this.allowedExtensions = '.jpg,.png,.jpeg';
        this.state = { UserImage: this.props.UserImage, UserName: this.props.UserName };
    }

    imgPreviewAvatar() {
        return (
            <div>
                <AvatarTemplate
                    UserImage={this.state.UserImage}
                    UserName={this.state.UserName}
                    Option='dialogUp'
                    IsNewUser={this.props.IsNewUser}
                ></AvatarTemplate>
                <div id='divImgAvatar' className='e-avatar e-avatar-xsmall e-avatar-circle' onClick={this.browseClick.bind(this)}>
                    <img id='browse' src={'/images/icons/folder.svg'} ></img>

                </div>
                <div id='divImgDelete' className='e-avatar e-avatar-xsmall e-avatar-circle' onClick={this.onRemoveFile.bind(this)} >
                    <img id='imgDelete' src={'/images/icons/delete.svg'} ></img>
                </div>
                <div className='btnBrowser'>
                    <UploaderComponent type='file'
                        allowedExtensions={this.allowedExtensions}
                        asyncSettings={this.asyncSettings} autoUpload={true} multiple={false}
                        minFileSize={1000} maxFileSize={1000000}
                        selected={this.onSelect.bind(this)}
                        removing={this.onRemoveFile.bind(this)}
                    ></UploaderComponent>
                </div>
            </div>
        )
    }

    dialogUploaderClose() {
        this.props.HideUploader();
    }

    dialogUploaderSave() {
        let imgUser = { UserImage: this.state.UserImage };
        this.props.HideAndSaveUploader(imgUser);
    }

    onSelect(args) {
        let file = args.filesData[0].rawFile;
        let reader = new FileReader();

        reader.addEventListener('load', () => { this.setState({ UserImage: reader.result }); }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    onRemoveFile() {
        this.setState({ UserImage: null });
    }

    browseClick() {
        document.getElementById('browse').onclick = () => {
            document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
            return false;
        }
    }

    renderUploader() {
        return (
            <div id='divDialogUploader'>
                <div id='titleDialog'>
                    <span className='titleDialog'>Avatar</span>
                </div>
                <div id='imageAvatar'>
                    {this.imgPreviewAvatar()}
                </div>

                <div className='row divBtnUploader'>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnUploader' onClick={this.dialogUploaderClose.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnUploader' onClick={this.dialogUploaderSave.bind(this)}><FormattedMessage id='confirm' /></ButtonComponent>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className='dialog'>
                    {this.renderUploader()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        )
    }
}

DialogUploader.propTypes = {
    UserImage: PropTypes.any,
    HideUploader: PropTypes.func,
    IsNewUser: PropTypes.bool,
    HideAndSaveUploader: PropTypes.func,
    Option: PropTypes.string,
    UserName: PropTypes.string,
    intl: PropTypes.object
};

export default injectIntl(DialogUploader);