// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divDialogAbout{\n    width: 100%;\n}\n\n.logo{\n    width: 150px;\n}\n\n#instalInfo1{\n    text-align: initial;\n    line-height: 1.5;\n    word-spacing: 4px;\n}\n\n#instalInfo2{\n    text-align: initial;\n    line-height: 1.5;\n    word-spacing: 4px;\n}\n\n#divBtnAbt{\n    padding-top: 110px;\n}\n\n#divText{\n    padding: 30px;\n    line-height: 23px;\n}", "",{"version":3,"sources":["webpack://./src/styles/dialogAbout.css"],"names":[],"mappings":"AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["@import \"app.css\";\n\n#divDialogAbout{\n    width: 100%;\n}\n\n.logo{\n    width: 150px;\n}\n\n#instalInfo1{\n    text-align: initial;\n    line-height: 1.5;\n    word-spacing: 4px;\n}\n\n#instalInfo2{\n    text-align: initial;\n    line-height: 1.5;\n    word-spacing: 4px;\n}\n\n#divBtnAbt{\n    padding-top: 110px;\n}\n\n#divText{\n    padding: 30px;\n    line-height: 23px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
