import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/historyAlarmsPage.css';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, VirtualScroll, Sort, ExcelExport } from '@syncfusion/ej2-react-grids';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { L10n, setCulture } from '@syncfusion/ej2-base';
import { loadCldr } from '@syncfusion/ej2-base';


import CommonDataManager from '../components/CommonDataManager'
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class AdminLoginAuditLogsPage extends React.Component {

    constructor(props) {
        super(props);

        this.today = new Date(new Date().toDateString());
        this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
        this.weekEnd = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
        this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
        this.monthEnd = this.today;
        this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
        this.lastEnd = this.today;
        this.yearStart = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
        this.yearEnd = this.today;

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            LoginAuditLogs: [],
            FromDate: this.today,
            ToDate: this.today
        };

        let initialState = { Filter: { FromDate: this.state.FromDate, ToDate: this.state.ToDate } };
        this.vm = dotnetify.react.connect('AdminLoginAuditLogs', this, { vmArg: initialState });
    }

    updateLanguage() {
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: this.commonData.getLanguage(), Messages: this.commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('AdminLoginAuditLogsPage');

        if (this.daterangepickerInstance != undefined) {
            this.daterangepickerInstance.startDate = this.state.FromDate;
            this.daterangepickerInstance.endDate = this.state.ToDate;
        }
    }
    componentWillUnmount() {
        if (this.vm != undefined)
            this.vm.$destroy();

        if (this.commonData != undefined)
            this.commonData.unsubscribe(this.updateLanguage);
    }

    filterDateAlarmsHistory() {
        if (this.daterangepickerInstance.startDate != undefined)
            if (this.daterangepickerInstance.endDate != undefined)
                this.vm.$dispatch({ Filter: { FromDate: this.daterangepickerInstance.startDate, ToDate: this.daterangepickerInstance.endDate } });
    }

    exportAlarmsHistory() {
        let pathExcelExport = this.intl.formatMessage({ id: 'menuadminheader.loginauditlogs' }) + '_' + this.intl.formatDate(this.daterangepickerInstance.startDate) + '_' + this.intl.formatDate(this.daterangepickerInstance.endDate) + '.xlsx';
        if (this.gridInstance != undefined)
            this.gridInstance.excelExport({ fileName: pathExcelExport });
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divLoginAuditLogs'>
                    <BasePage title={this.intl.formatMessage({ id: 'menuadminheader.loginauditlogs' })} navigation={this.intl.formatMessage({ id: 'menuadminheader.loginauditlogs' })}></BasePage>
                    <div id='divTestToolbarHistoryCell' className='row'>
                        <div className=' testToolbarHistoryCell col-sm-12 col-md-10 col-lg-10'><span id='textFilter'><FormattedMessage id='filter' /></span>
                            <DateRangePickerComponent id='datarangeFilter' format='dd/MM/yyyy'
                                startDate={this.state.FromDate} endDate={this.state.ToDate}
                                allowEdit={false}
                                change={this.filterDateAlarmsHistory.bind(this)}
                                ref={calendar => this.daterangepickerInstance = calendar}>
                                <PresetsDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'today' })} start={this.today} end={this.today}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'this_week' })} start={this.weekStart} end={this.weekEnd}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'this_month' })} start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'last_month' })} start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                    <PresetDirective label={this.intl.formatMessage({ id: 'last_year' })} start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                </PresetsDirective>
                            </DateRangePickerComponent>
                        </div>
                        <div className='testBtnExport col-sm-12 col-md-2 col-lg-2'>
                            <ButtonComponent id='btnExport' className='Button'
                                disabled={this.state.LoginAuditLogs.length <= 0}
                                onClick={() => this.exportAlarmsHistory()}><FormattedMessage id='export' /></ButtonComponent>
                        </div>
                    </div>
                    <div id='divLoginAuditLogsGrid'>
                        <GridComponent id='loginAuditLogsGrid' ref={grid => this.gridInstance = grid}
                            dataSource={this.state.LoginAuditLogs} enableHover={true} enableVirtualization={false}
                            allowFiltering={true} selectionSettings={{ persistSelection: true, checkboxOnly: true }}
                            allowExcelExport={true}
                            filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                            allowSorting={true} allowSelection={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='DateTime' headerText={this.intl.formatMessage({ id: 'date' })} width='3%' type='datetime' format={{ format: 'dd/MM/yyyy HH:mm', type: 'datatime' }} ></ColumnDirective>
                                <ColumnDirective field='Username' headerText={this.intl.formatMessage({ id: 'username' })} width='5%'></ColumnDirective>
                                <ColumnDirective field='Name' headerText={this.intl.formatMessage({ id: 'name' })} width='5%'></ColumnDirective>
                                <ColumnDirective field='Surname' headerText={this.intl.formatMessage({ id: 'surname' })} width='5%' />
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort, VirtualScroll, ExcelExport]} />
                        </GridComponent>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

AdminLoginAuditLogsPage.propTypes = {
};

export default AdminLoginAuditLogsPage;