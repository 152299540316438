import React from 'react';
import PropTypes from 'prop-types';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/sidebarHistoryAlarms.css';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

class SideBarHistoryAlarms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            LocalSidebarManageVisible: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.SidebarHistoryAlarmsVisible !== this.props?.SidebarHistoryAlarmsVisible) {
            this.setState({ LocalSidebarManageVisible: this.props.SidebarHistoryAlarmsVisible });
        }
    }

    renderAlarmsDetail() {
        if ((!this.props?.SidebarHistoryAlarmsVisible) || (this.props?.SelectedAlarm === null))
            return null;
        else
            return (
                <div id='divAlarm'>

                    <div className=' divAlarmHeader'>
                        <div id='testDivAlarm'>
                            <div className='rowInfo' id='testAlarm'>
                                <span className='lblTitle'>{this.props.SelectedAlarm.AlarmCode} - <FormattedDate value={new Date(this.props.SelectedAlarm.DateTime)} /> <FormattedTime value={new Date(this.props.SelectedAlarm.DateTime)} /></span>
                            </div>
                            <div className='rowInfo' id='testAlarm'>
                                <span className='lblValue'>{this.props.SelectedAlarm.DeviceID} {this.props.SelectedAlarm.DeviceSN}</span>
                            </div>
                            <div className='rowInfo' id='testAlarm'>
                                <span className='lblValue'>{this.props.SelectedAlarm.Site}</span>
                            </div>
                            <div className='rowInfo' id='testAlarm'>
                                <span className='lblValue'>{this.props.SelectedAlarm.Department}</span>
                            </div>
                        </div>
                        <div id='divHistoryAlarmDetailTest'>
                            <div id='divHistoryAlarmDetail'>
                                <span className='lblDescription'><FormattedMessage id='alarm_description' /></span>
                                <TextBoxComponent className='txtEdit' type='text' name='Description' multiline={true} readOnly={true}
                                    floatLabelType='Auto' cssClass='sample'
                                    value={this.props.SelectedAlarm.Description || ''} id='txtDescription' placeholder='' />
                            </div>
                            <div id='divHistoryAlarmDetail'>
                                <span className='lblDescription'><FormattedMessage id='custom_alarm_description' /> </span>
                                <TextBoxComponent className='txtEdit' type='text' name='CustomDescription' multiline={true} readOnly={true}
                                    floatLabelType='Auto'
                                    value={this.props.SelectedAlarm.CustomDescription || ''} id='txtCustomDescription' placeholder='' />
                            </div>
                        </div>
                    </div>
                </div>
            );
    }

    renderButtonsSidebarManage() {
        return (
            <div id='divButtonsSidebar'>
                <div id='divButtonClear'>
                    <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                </div>
                <div id="divButtonPrev">
                    <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeAlarmHistory(-1)} iconCss='e-icons e-sidebarprev' />
                </div>
                <div id="divButtonNext">
                    <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeAlarmHistory(+1)} iconCss='e-icons e-sidebarnext' />
                </div>
            </div>
        );
    }

    renderContentSidebarHistoryAlarms() {
        if (this.state?.LocalSidebarManageVisible === 1)
            return (
                <div>
                    {this.renderButtonsSidebarManage()}
                    {this.renderAlarmsDetail()}
                </div>
            )
    }

    render() {
        let widthSidebar = { width: '0px' };

        if (this.state?.LocalSidebarManageVisible !== 0)
            widthSidebar = { width: '50%' };

        return (
            <div id='sidebarHistoryAlarms' style={widthSidebar}>
                {this.renderContentSidebarHistoryAlarms()}
            </div>
        );

    }
}

SideBarHistoryAlarms.propTypes = {
    SidebarHistoryAlarmsVisible: PropTypes.number,
    SelectedAlarm: PropTypes.any,
    ClearSelection: PropTypes.func,
    ChangeAlarmHistory: PropTypes.func,
};

export default injectIntl(SideBarHistoryAlarms);