import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/dialogSet.css';

class DialogDeleteSetup extends React.Component {

    confirmeDelete() {
        this.props.ConfirmDelete()
    }

    close() {
        this.props.HideAlertDelete()
    }

    renderDeleteSetup() {
        return (
            <div id='divDialogAlert'>
                <h3 id='titleAlert'><FormattedMessage id='warning' /></h3>
                <div id='messageAlert'>
                    <span><FormattedMessage id='configuration_delete' />{' '}{this.props.NameConfiguration} {' ?'}</span>
                </div>
                <div className='row divBtnAlert'>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnSet' onClick={this.close.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnSet' onClick={this.confirmeDelete.bind(this)}><FormattedMessage id='confirm' /></ButtonComponent>
                    </div>
                </div>
            </div>
        )

    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className="dialog">
                    {this.renderDeleteSetup()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        )
    }
}

DialogDeleteSetup.propTypes = {
    HideAlertDelete: PropTypes.func,
    ConfirmDelete: PropTypes.func,
    NameConfiguration: PropTypes.any
};

export default injectIntl(DialogDeleteSetup);