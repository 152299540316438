import React from 'react';
import PropTypes from 'prop-types';
import { IDParamsConfigurationTemplate } from '../templates/IDParamsConfigurationTemplate';
import '../../styles/manage/paramsConfiguration.css'
import ProgressBar from '../../components/ProgressBar';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { convertFahrenheit, convertCelsius } from '../../components/utils/TemperatureConversion';

import { injectIntl, FormattedMessage } from 'react-intl';

class RefrigerationConfiguration extends React.Component {

    constructor(props) {
        super(props);

        if (props.IsConfigurationMenu)
            this.state = { Detail: props.Detail };
        else
            this.state = { Detail: null };
    }

    menuCloseClick() {
        this.gridParamsInstance?.endEdit();
        this.props.MenuCloseClick();
    }

    menuSaveClick() {

        this.gridParamsInstance?.endEdit();
        let refrigerationsT = this.state.Detail.Refrigerations;

        if (this.props.UmTemperature === 'F') {
            for (let i = 0; i < refrigerationsT.length; i++) {
                if (refrigerationsT[i].Type === 'T')
                    refrigerationsT[i].Value = convertCelsius(refrigerationsT[i].Value);
            }
        }

        this.props.SetParams(refrigerationsT);
        this.props.MenuCloseClick();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps?.Selected?.Id === this.state?.Detail?.Id)
            return false;

        return true;
    }

    componentDidUpdate() {
        if ((this.props?.Selected !== undefined) && (this.props?.Selected?.Id !== this.props?.Detail?.Id)) {
            if (this.state.Detail !== null)
                this.setState({ Detail: null });
            return;
        }
        else {
            let bSetDetail = (this.state.Detail == null);

            if (!bSetDetail)
                bSetDetail = (this.props.Detail.Id !== this.state.Detail.Id);

            if (bSetDetail) {

                let d = this.props.Detail;

                if (this.props.UmTemperature === 'F') {
                    for (let i = 0; i < d?.Refrigerations?.length; i++)
                        if (d?.Refrigerations[i]?.Type === 'T') {
                            d.Refrigerations[i].Value = convertFahrenheit(d?.Refrigerations[i].Value);
                            d.Refrigerations[i].MinValue = convertFahrenheit(d?.Refrigerations[i].MinValue);
                            d.Refrigerations[i].MaxValue = convertFahrenheit(d?.Refrigerations[i].MaxValue);
                        }
                }

                this.setState({ SelectedCycle: null, Detail: d });
            }
        }
    }

    valueTemplateParam(props) {
        if (props.Type === 'T') {
            return (<span>{props.Value}°{this.props.UmTemperature}</span>);
        }

        return null;
    }

    editTemplateParam(props) {

        if (props.Type === 'T'){
            return (
                <NumericTextBoxComponent id='Value' format='n0' min={props.MinValue} max={props.MaxValue} value={props.Value} />
            );
        }

        return null;
    }

    renderRefrigerationConfigurationGrid() {

        if (this.state.Detail === null)
            return (
                <ProgressBar  Id='RefrigerationConfiguration' Option={this.props.Option} Message={this.props.intl?.formatMessage({ id: 'waiting' })} />
            )
        else
            return (
                <div id='renderParamsConfiguration'>
                <GridComponent id='paramsConfigurationGrid' ref={grid => this.gridParamsInstance = grid}
                    dataSource={(this.state.Detail === null) ? null : this.state.Detail.Refrigerations}
                    editSettings={{ allowEditing: true, allowAdding: false, allowDeleting: false, mode: 'Dialog' }}
                    allowSorting={false} allowSelection={false} className='comm-grid'
                >
                    <ColumnsDirective>
                        <ColumnDirective field='Name' headerText={this.props.intl.formatMessage({ id: 'id' })} allowEditing={false} template={IDParamsConfigurationTemplate.bind(this)} editTemplate={IDParamsConfigurationTemplate.bind(this)} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective field='Value' headerText={this.props.intl.formatMessage({ id: 'value' })}
                            editTemplate={this.editTemplateParam.bind(this)} template={this.valueTemplateParam.bind(this)}>
                        </ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Edit]} />
                </GridComponent>
                </div>
            );
    }

    render() {
        let idTxtSave = 'save';
        let btnId ='configBtn';
        let btnIdSave='configBtnSave'
        if(this.props.Option === 'ConfigurationPage') idTxtSave ='confirm' , btnId='configBtnPage', btnIdSave='configBtnSavePage';
        return (
            <div className='divConfiguration'>
                <div id='divConfigurationTop'>
                    <div id='paramsConfig'>
                        <span className='lblValue'><FormattedMessage id='configuration_refrigeration' /></span>
                    </div>
                    <div id={btnId}>
                        <ButtonComponent
                            id='btnCloseConfigurations'
                            onClick={() => this.menuCloseClick()}
                            iconCss='e-icons e-FT_close'
                            className='ButtonEmpty'><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div id={btnIdSave}>
                        <ButtonComponent
                            id='btnSaveConfigurations'
                            onClick={() => this.menuSaveClick()}
                            disabled={(this.props.Option==='MonitorPage' && this.state?.Detail?.Status !== 1)}
                            iconCss='e-icons e-FB_PV_Save'
                            className='Button'><FormattedMessage id={idTxtSave} /></ButtonComponent>
                    </div >
                </div>
                {this.renderRefrigerationConfigurationGrid()}
            </div>
        );
    }
}

RefrigerationConfiguration.propTypes = {
    Selected: PropTypes.object,
    Detail: PropTypes.object,
    SetParams: PropTypes.func,
    MenuCloseClick: PropTypes.func,
    intl: PropTypes.object,
    UmTemperature: PropTypes.string,
    IsConfigurationMenu: PropTypes.bool,
    Option:PropTypes.string
};

export default injectIntl(RefrigerationConfiguration);