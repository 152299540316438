import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/manage/menuConfigurations.css';

import DialogAlertMenuConfiguration from '../DialogAlertMenuConfiguration';
import CyclesConfiguration from './CyclesConfiguration';
import DeviceDataConfiguration from './DeviceDataConfiguration';
import ParamsConfiguration from './ParamsConfiguration';
import StartsConfiguration from './StartsConfiguration';
import RefrigerationConfiguration from './RefrigerationConfiguration';
import ProgressBar from '../../components/ProgressBar';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort, Search } from '@syncfusion/ej2-react-grids';

import { injectIntl, FormattedMessage } from 'react-intl';
import isEqual from 'react-fast-compare';

class MenuConfigurations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            SelectedMenu: -1,
            CyclesEnabled: this.props?.Detail?.CyclesEnabled,
            StartsEnabled: this.props?.Detail?.StartsEnabled,
            ParamsEnabled: this.props?.Detail?.ParamsEnabled,
            RefrigerationsEnabled: this.props?.Detail?.RefrigerationsEnabled,
            SelectedConfiguration: [],
            hideDialogAlert: true,
            CloneDataSave: [],
            LoadEnabled: false,
            ConfigurationsLoad: null,
            Option: ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.Detail, this.props?.Detail)) {
            this.setState({
                CyclesEnabled: this.props?.Detail?.CyclesEnabled,
                StartsEnabled: this.props?.Detail?.StartsEnabled,
                ParamsEnabled: this.props?.Detail?.ParamsEnabled,
                RefrigerationsEnabled: this.props?.Detail?.RefrigerationsEnabled
            });
        }


        if (this.state.ConfigurationsLoad === null)
            this.setState({ ConfigurationsLoad: this.props?.Configurations })

        if (prevProps?.Configurations !== this.props?.Configurations) {
            this.setState({ ConfigurationsLoad: this.props?.Configurations })
        }

        if (prevState?.SelectedMenu !== this.state?.SelectedMenu)
            if (this.props.MenuSelected !== undefined)
                this.props.MenuSelected(this.state?.SelectedMenu === -1);

    }

    menuClick(index) {
        this.setState({ SelectedMenu: index });
    }

    menuCloseClick() {
        this.setState({ SelectedMenu: -1 });
    }

    menuCloseClear() {
        let cloneConfigurations = JSON.parse(JSON.stringify(this.props?.Configurations));

        for (let i = 0; i < cloneConfigurations.length; i++)
            cloneConfigurations[i].Selected = false;

        this.setState({ ConfigurationsLoad: cloneConfigurations, SelectedMenu: -1, SelectedConfiguration: [], LoadEnabled: false });
    }

    btnConfigEnable(e) {
        this.setState({ [e.target.name]: e.target.checked })
        this.props.SetFlagsEnabled(e.target.name, e.target.checked);
    }

    renderMenuConfigurations() {
        let visibileBtnRefrigeration = false;

        if (this.props.Selected !== null && this.props.Option === 'MonitorPage')
            if (this.props.Selected.filter(d => d.DeviceVersion === 'v3').length > 0 || this.props.Selected.filter(d => d.DeviceVersion === 'v2').length > 0)
                visibileBtnRefrigeration = true;
        if (this.props.Option === 'ConfigurationPage' && this.props.IsNewConfiguration)
            if (this.props?.Detail?.DeviceVersion === 'v2' || this.props?.Detail?.DeviceVersion === 'v3')
                visibileBtnRefrigeration = true;

        if (this.props.Option === 'ConfigurationPage' && !this.props.IsNewConfiguration)
            if (this.props?.Detail?.DeviceVersion === 'v2' || this.props?.Detail?.DeviceVersion === 'v3')
                visibileBtnRefrigeration = true;

        let pasteMenuEnabled = (this.props?.CurrentConfiguration);

        if (this.state.SelectedMenu === -1)
            return (
                <div id='divMenuConfigurations'>
                    {!this.props.IsConfigurationMenu ?
                        <div id='divMenuTop'>

                            <div className={'row headerConfig' + this.props.Option}>
                                <div className={this.props.Option}>
                                    <ButtonComponent id='btnMenuConfiguration' onClick={() => this.menuClick(1000)} className='ButtonEmpty'><FormattedMessage id='configuration_copy' /></ButtonComponent>
                                </div>
                                <div className={this.props.Option}>
                                    <ButtonComponent id='btnMenuConfiguration' onClick={() => this.menuClick(1001)} className='ButtonEmpty' disabled={!pasteMenuEnabled}><FormattedMessage id='configuration_paste' /></ButtonComponent>
                                </div>
                                <div className={this.props.Option}>
                                    <ButtonComponent id='btnMenuConfiguration' onClick={() => this.menuClick(1002)} className='ButtonEmpty'><FormattedMessage id='configuration_load' /></ButtonComponent>
                                </div>
                                <div className={this.props.Option}>
                                    <ButtonComponent id='btnMenuConfiguration' onClick={() => this.menuClick(1003)} className='Button' iconCss='e-icons e-FB_PV_Save'><FormattedMessage id='configuration_save' /></ButtonComponent>
                                </div>
                            </div>
                        </div> : null
                    }
                    {!this.props.IsConfigurationMenu ?
                        <div id='divMenuBottom'>

                            <div className='row'>
                                <ButtonComponent id='btnDeviceConfiguration' onClick={() => this.menuClick(0)} className='ButtonEmpty'><FormattedMessage id='configuration_trolley_data' /></ButtonComponent>
                            </div>
                            <div className='row'>
                                <ButtonComponent id='btnCyclesConfiguration' onClick={() => this.menuClick(1)} className='ButtonEmpty'><FormattedMessage id='configuration_cycles' /></ButtonComponent>
                            </div>
                            <div className='row'>
                                <ButtonComponent id='btnStartsConfiguration' onClick={() => this.menuClick(2)} className='ButtonEmpty'><FormattedMessage id='configuration_starts' /></ButtonComponent>
                            </div>
                            <div className='row'>
                                <ButtonComponent id='btnParamsConfiguration' onClick={() => this.menuClick(3)} className='ButtonEmpty'><FormattedMessage id='configuration_params' /></ButtonComponent>
                            </div>
                            {visibileBtnRefrigeration ?
                                <div className='row'>
                                    <ButtonComponent id='btnRefrigerationConfiguration' onClick={() => this.menuClick(4)} className='ButtonEmpty'><FormattedMessage id='configuration_refrigeration' /></ButtonComponent>
                                </div> : null
                            }
                        </div>
                        : <div id='divMenuBottom'>

                            <div className='row'>
                                <div className='col-sm-1'>
                                    <CheckBoxComponent name='CyclesEnabled' checked={this.state.CyclesEnabled} onChange={this.btnConfigEnable.bind(this)} />
                                </div>
                                <div className='col-sm-11'>
                                    <ButtonComponent id='btnCyclesConfiguration' disabled={!this.state.CyclesEnabled} onClick={() => this.menuClick(1)} className='ButtonEmpty'><FormattedMessage id='configuration_cycles' /></ButtonComponent>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-1'>
                                    <CheckBoxComponent name='StartsEnabled' checked={this.state.StartsEnabled} onChange={this.btnConfigEnable.bind(this)} />
                                </div>
                                <div className='col-sm-11'>
                                    <ButtonComponent id='btnStartsConfiguration' disabled={!this.state.StartsEnabled} onClick={() => this.menuClick(2)} className='ButtonEmpty'><FormattedMessage id='configuration_starts' /></ButtonComponent>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-1'>
                                    <CheckBoxComponent name='ParamsEnabled' checked={this.state.ParamsEnabled} onChange={this.btnConfigEnable.bind(this)} />
                                </div>
                                <div className='col-sm-11'>
                                    <ButtonComponent id='btnParamsConfiguration' disabled={!this.state.ParamsEnabled} onClick={() => this.menuClick(3)} className='ButtonEmpty'><FormattedMessage id='configuration_params' /></ButtonComponent>
                                </div>

                            </div>
                            {visibileBtnRefrigeration ?
                                <div className='row'>
                                    <div className='col-sm-1'>
                                        <CheckBoxComponent name='RefrigerationsEnabled' checked={this.state.RefrigerationsEnabled} onChange={this.btnConfigEnable.bind(this)} />
                                    </div>
                                    <div className='col-sm-11'>
                                        <ButtonComponent id='btnRefrigerationConfiguration' disabled={!this.state.RefrigerationsEnabled} onClick={() => this.menuClick(4)} className='ButtonEmpty'><FormattedMessage id='configuration_refrigeration' /></ButtonComponent>
                                    </div>
                                </div> : null
                            }
                        </div>
                    }
                </div >
            )
    }

    renderDeviceDataConfiguration() {
        let s = undefined;

        if (Array.isArray(this.props.Selected)) {
            if (this.props.Selected.length > 0)
                s = this.props.Selected[0];
        }
        else
            s = this.props.Selected;

        if (this.state.SelectedMenu === 0)
            return (
                <DeviceDataConfiguration
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    Selected={s}
                    Detail={this.props.Detail}
                    SetData={this.props.SetData}
                    ListSites={this.props.ListSites}
                    ListDepartments={this.props.ListDepartments}
                    Option={this.props.Option} />
            )
    }

    renderCyclesConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.Selected)) {
                if (this.props.Selected.length > 0)
                    s = this.props.Selected[0];
            }
            else
                s = this.props.Selected;
        if (this.state.SelectedMenu === 1)
            return (
                <CyclesConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    Selected={s}
                    Detail={this.props.Detail}
                    SetCycles={this.props.SetCycles}
                    UmTemperature={this.props.UmTemperature}
                    Option={this.props.Option} />
            )
    }

    renderStartsConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.Selected)) {
                if (this.props.Selected.length > 0)
                    s = this.props.Selected[0];
            }
            else
                s = this.props.Selected;

        if (this.state.SelectedMenu === 2)
            return (
                <StartsConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    Selected={s}
                    Detail={this.props.Detail}
                    SetStarts={this.props.SetStarts}
                    Option={this.props.Option} />
            )
    }

    renderParamsConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.Selected)) {
                if (this.props.Selected.length > 0)
                    s = this.props.Selected[0];
            }
            else
                s = this.props.Selected;

        if (this.state.SelectedMenu === 3)
            return (
                <ParamsConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    Selected={s}
                    Detail={this.props.Detail}
                    SetParams={this.props.SetParams}
                    UmTemperature={this.props.UmTemperature}
                    Option={this.props.Option} />
            )
    }

    renderRefrigerationConfiguration() {
        let s = undefined;

        if (!this.props.IsConfigurationMenu)
            if (Array.isArray(this.props.Selected)) {
                if (this.props.Selected.length > 0)
                    s = this.props.Selected[0];
            }
            else
                s = this.props.Selected;

        if (this.state.SelectedMenu === 4)
            return (
                <RefrigerationConfiguration
                    IsConfigurationMenu={this.props.IsConfigurationMenu}
                    MenuCloseClick={this.menuCloseClick.bind(this)}
                    Selected={s}
                    Detail={this.props.Detail}
                    SetParams={this.props.SetRefrigerations}
                    UmTemperature={this.props.UmTemperature}
                    Option={this.props.Option} />
            )
    }

    copyConfiguration() {
        let data = {
            Name: this.props.intl?.formatMessage({ id: 'copy_trolley_configuration' }) + ' ' + this.props?.Detail?.DeviceID + '\r\n',
            Description: this.props.intl?.formatMessage({ id: 'site' }) + ' ' + this.props?.Detail?.Site + '\r\n' +
                this.props.intl?.formatMessage({ id: 'department' }) + ' ' + this.props?.Detail?.Department + '\r\n' +
                this.props.intl?.formatMessage({ id: 'model' }) + ' ' + this.props?.Detail?.Model + '\r\n' +
                this.props.intl?.formatMessage({ id: 'at_time' }) + ' ' + this.props.intl.formatDate(this.props?.Detail?.DeviceDate) + ' ' + this.props.intl.formatTime(this.props?.Detail?.DeviceDate),
            CyclesEnabled: this.chkBoxCopyCycles.checked,
            StartsEnabled: this.chkBoxCopyStarts.checked,
            ParamsEnabled: this.chkBoxCopyParams.checked,
            RefrigerationsEnabled: this.chkBoxCopyRefrigerations.checked
        };
        this.props.CopyConfiguration(data);
        this.menuCloseClick();
    }

    renderCopyConfiguration() {

        if (this.state.SelectedMenu === 1000)
            if (this.props?.Detail === null)
                return (
                    <ProgressBar Id='CopyConfiguration' Message={this.props.intl?.formatMessage({ id: 'waiting' })} />
                )
            else
                return (
                    <div id={'divCopyConfig' + this.props.Option}>
                        <div className='headerMenuConfiguration'>
                            <div id='divConfigurationTop'>
                                <span className='lblDescription'><FormattedMessage id='configuration_copy' /></span>

                            </div>
                            <div id={'divBtnTop' + this.props.Option} >
                                <div id='configBtnClose'>
                                    <ButtonComponent id='btnCloseCopy' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClick()}><FormattedMessage id='close' /></ButtonComponent>
                                </div>
                                <div id='configBtns'>
                                    <ButtonComponent id='btnCopy' className='Button' iconCss='e-icons e-FB_copy' onClick={() => this.copyConfiguration()}><FormattedMessage id='copy' /></ButtonComponent>
                                </div>
                            </div>
                        </div>
                        <div className='row divSelectCopyConfiguration'>
                            <div className='col-xs-12' id='titleCopyConfiguration'>
                                <span className='lblDescription'><FormattedMessage id='select_data_copy' /></span>
                            </div>
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxCopyCycles = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_cycles' })} />
                            </div>
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxCopyStarts = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_starts' })} />
                            </div>
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxCopyParams = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_params' })} />
                            </div>
                            <div className='col-xs-12' id='checkboxMenu'>
                                <CheckBoxComponent ref={chkbox => this.chkBoxCopyRefrigerations = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_refrigeration' })} />
                            </div>
                        </div>
                    </div>
                )
    }


    pasteConfiguration() {
        this.setState({ hideDialogAlert: false, Option: 'pasteConfiguration' })
    }

    confirmPaste() {
        this.props.PasteConfiguration();
        this.setState({ hideDialogAlert: true })
        this.menuCloseClear();
    }

    onCreate() {
        this.txtDescription.respectiveElement.style.height = 'auto';
        this.txtDescription.respectiveElement.style.height = (this.txtDescription.respectiveElement.scrollHeight) + 'px';
    }
    onInput() {
        this.txtDescription.respectiveElement.style.height = 'auto';
        this.txtDescription.respectiveElement.style.height = (this.txtDescription.respectiveElement.scrollHeight) + 'px';
    }

    renderPasteConfiguration() {
        if (this.state.SelectedMenu === 1001)
            return (
                <div id={'divPasteConfiguration' + this.props.Option}>
                    <div className='headerMenuConfiguration'>
                        <div id='divConfigurationTop'>
                            <span className='lblDescription'><FormattedMessage id='configuration_paste' /></span>

                        </div>
                        <div id={'divBtnTop' + this.props.Option}>
                            <div id='configBtnClose'>
                                <ButtonComponent id='btnClosePaste' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClick()}><FormattedMessage id='close' /></ButtonComponent>
                            </div>
                            <div id='configBtns'>
                                <ButtonComponent id='btnPaste' className='Button' iconCss='e-icons e-FB_copy' onClick={() => this.pasteConfiguration()}><FormattedMessage id='paste' disabled={this.state.PasteEnabled} /></ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <div className='row divSelectCopyConfiguration'>
                        <div className='col-xs-12' id='titleCopyConfiguration'>
                            <span className='lblDescription'><FormattedMessage id='select_data_paste' /></span>
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_cycles' })} disabled={!this.props?.CurrentConfiguration?.CyclesEnabled} checked={this.props?.CurrentConfiguration?.CyclesEnabled} />
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_starts' })} disabled={!this.props?.CurrentConfiguration?.StartsEnabled} checked={this.props?.CurrentConfiguration?.StartsEnabled} />
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_params' })} disabled={!this.props?.CurrentConfiguration?.ParamsEnabled} checked={this.props?.CurrentConfiguration?.ParamsEnabled} />
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'trolley_refrigeration' })} disabled={!this.props?.CurrentConfiguration?.RefrigerationsEnabled} checked={this.props?.CurrentConfiguration?.RefrigerationsEnabled} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12' id='titlePasteData'>
                            <span className='lblDescription'><FormattedMessage id='data_origin' /></span>
                        </div>
                        <div className='col-xs-12'>
                            <TextBoxComponent className='lblDescription'
                                value={this.props?.CurrentConfiguration?.Name + this.props?.CurrentConfiguration?.Description}
                                input={this.onInput = this.onInput.bind(this)} created={this.onCreate = this.onCreate.bind(this)}
                                multiline={true} readOnly={true} ref={txt => { this.txtDescription = txt }} floatLabelType='Auto'
                            />
                        </div>
                    </div>
                </div>
            )
    }

    rowConfigurationSelected() {
        var selected;
        var id = -1;

        if (this.gridInstance.getSelectedRecords().length === 1) {
            id = this.gridInstance.getSelectedRecords()[0].Id;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        if (selected === undefined)
            id = -1;
        else
            if (selected.Selected)
                id = -1;

        let cloneConfigurations = JSON.parse(JSON.stringify(this.props)).Configurations;
        for (let i = 0; i < cloneConfigurations.length; i++) {
            cloneConfigurations[i].Selected = (cloneConfigurations[i].Id === id);
        }
        this.setState({ SelectedConfiguration: selected, LoadEnabled: (id !== -1) ? true : false, ConfigurationsLoad: cloneConfigurations });
    }

    loadConfiguration() {
        this.props.LoadConfiguration(this.state.SelectedConfiguration);
        this.menuCloseClear();
    }
    renderLoadConfiguration() {

        if (this.state.SelectedMenu === 1002)
            return (
                <div id={'divLoadConfiguration' + this.props.Option}>
                    <div className='headerMenuConfiguration'>

                        <div id='divConfigurationTop'>
                            <span className='lblDescription'><FormattedMessage id='configuration_load' /></span>

                        </div>
                        <div id={'divBtnTop' + this.props.Option}>
                            <div id='configBtnClose'>
                                <ButtonComponent id='btnLoadClose' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClear()}><FormattedMessage id='close' /></ButtonComponent>
                            </div>
                            <div id='configBtns'>
                                <ButtonComponent id='btnLoadConfiguration' className='Button' iconCss='e-icons e-F_PV_Download' disabled={!this.state.LoadEnabled} onClick={() => this.loadConfiguration()}><FormattedMessage id='load' /></ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <div id='divLoadConfigurationsGrid'>
                        <GridComponent id='gridLoadConfiguration' ref={grid => this.gridInstance = grid}
                            dataSource={this.state.ConfigurationsLoad}
                            allowFiltering={true} enableToggle={true} enableHover={true}
                            allowSorting={true} allowSelection={true}
                            filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                            rowSelected={this.rowConfigurationSelected.bind(this)} >
                            <ColumnsDirective>
                                <ColumnDirective field='Selected' headerText='' width='35px' displayAsCheckBox={true} allowFiltering={false}  ></ColumnDirective>
                                <ColumnDirective field='Id' visible={false} isPrimaryKey={true}></ColumnDirective>
                                <ColumnDirective field='Name' headerText={this.props.intl.formatMessage({ id: 'name' })} width='25%' ></ColumnDirective>
                                <ColumnDirective field='Description' headerText={this.props.intl.formatMessage({ id: 'description' })} visible={true} ></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort, Search]} />
                        </GridComponent>
                    </div>
                </div>
            )
    }

    hideAlert() {
        this.setState({ hideDialogAlert: true })
    }

    renderDialogAlert() {

        if (!this.state.hideDialogAlert)
            return (
                <DialogAlertMenuConfiguration
                    HideAlert={this.hideAlert.bind(this)}
                    CloneDataSave={this.state.CloneDataSave}
                    Option={this.state.Option}
                    ConfirmPaste={this.confirmPaste.bind(this)}
                    NameConfiguration={this.props?.CurrentConfiguration?.Name}
                />
            );
    }

    saveConfiguration() {

        let data = {
            Name: this.txtConfigurationName.value,
            Description: this.txtConfigurationDescription.value,
            CyclesEnabled: this.chkBoxSaveCycles.checked,
            StartsEnabled: this.chkBoxSaveStarts.checked,
            ParamsEnabled: this.chkBoxSaveParams.checked,
            RefrigerationsEnabled: this.chkBoxSaveRefrigerations.checked
        };

        if (data.Name === '' || !data.CyclesEnabled && !data.StartsEnabled && !data.ParamsEnabled && !data.RefrigerationsEnabled) {
            this.setState({ hideDialogAlert: false, Option: 'saveConfiguration', CloneDataSave: data })
        } else {
            this.props.SaveConfiguration(data);
            this.menuCloseClick();
        }
    }

    renderSaveConfiguration() {
        if (this.state.SelectedMenu === 1003)
            return (
                <div id={'divCopyConfig' + this.props.Option}>
                    <div className='headerMenuConfig'>
                        <div id='divConfigurationTop'>
                            <span className='lblDescription'><FormattedMessage id='configuration_save' /></span>
                        </div>
                        <div id={'divBtnTop' + this.props.Option}>
                            <div id='configBtnClose'>
                                <ButtonComponent id='btnSaveClose' className='ButtonEmpty' iconCss='e-icons e-FT_close' onClick={() => this.menuCloseClick()}><FormattedMessage id='close' /></ButtonComponent>
                            </div>
                            <div id='configBtns'>
                                <ButtonComponent className='Button' iconCss='e-icons e-FB_PV_Save' onClick={() => this.saveConfiguration()}><FormattedMessage id='save' /></ButtonComponent>
                            </div>
                        </div>
                    </div>

                    <div id='divDescriptionConfiguration' >
                        <div className='titleDescription'>
                            <span className='lblDescription'><FormattedMessage id='configuration_name' /></span>
                            <input ref={txt => this.txtConfigurationName = txt} className='e-input' required placeholder={this.props.intl.formatMessage({ id: 'holder_name_config' })} />
                        </div>
                        <div className='titleDescription'>
                            <span className='lblDescription'>{this.props.intl.formatMessage({ id: 'description' }).toUpperCase()}</span>
                            <input ref={txt => this.txtConfigurationDescription = txt} className='e-input' placeholder={this.props.intl.formatMessage({ id: 'holder_save_config' })} />
                        </div>
                    </div>

                    <div className='row divSelectSaveConfiguration'>
                        <div className='col-xs-12' id='titleSaveConfiguration'>
                            <span className='lblDescription'><FormattedMessage id='select_data_save' /></span>
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent ref={chkbox => this.chkBoxSaveCycles = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_cycles' })} />
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent ref={chkbox => this.chkBoxSaveStarts = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_starts' })} />
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent ref={chkbox => this.chkBoxSaveParams = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_params' })} />
                        </div>
                        <div className='col-xs-12' id='checkboxMenu'>
                            <CheckBoxComponent ref={chkbox => this.chkBoxSaveRefrigerations = chkbox} label={this.props.intl.formatMessage({ id: 'trolley_refrigeration' })} />
                        </div>
                    </div>
                </div>
            )
    }

    render() {
        return (
            <div>
                {this.renderMenuConfigurations()}
                {this.renderDeviceDataConfiguration()}
                {this.renderCyclesConfiguration()}
                {this.renderStartsConfiguration()}
                {this.renderParamsConfiguration()}
                {this.renderCopyConfiguration()}
                {this.renderPasteConfiguration()}
                {this.renderLoadConfiguration()}
                {this.renderSaveConfiguration()}
                {this.renderRefrigerationConfiguration()}
                {this.renderDialogAlert()}
            </div>

        );
    }
}

MenuConfigurations.propTypes = {
    IsConfigurationMenu: PropTypes.bool,
    Selected: PropTypes.any,
    Detail: PropTypes.any,
    intl: PropTypes.object,
    SetData: PropTypes.func,
    SetCycles: PropTypes.func,
    SetStarts: PropTypes.func,
    SetParams: PropTypes.func,
    SetRefrigerations: PropTypes.func,
    SetFlagsEnabled: PropTypes.func,
    Configurations: PropTypes.array,
    CurrentConfiguration: PropTypes.object,
    LoadConfiguration: PropTypes.func,
    SaveConfiguration: PropTypes.func,
    CopyConfiguration: PropTypes.func,
    PasteConfiguration: PropTypes.func,
    ListSites: PropTypes.any,
    ListDepartments: PropTypes.any,
    Option: PropTypes.string,
    UmTemperature: PropTypes.string,
    IsNewConfiguration: PropTypes.bool,
    RowConfigurationSelected: PropTypes.func,
    MenuSelected: PropTypes.func
};

export default injectIntl(MenuConfigurations);