import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/maintenancePage.css';
import BasePage from '../components/BasePage';
import { ScheduleComponent, ViewsDirective, ViewDirective, Month, Inject } from '@syncfusion/ej2-react-schedule';

import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';


import CommonDataManager from '../components/CommonDataManager'
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';

class MaintenancePage extends React.Component {

    constructor(props) {
        super(props);

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages()
        };

        this.vm = dotnetify.react.connect('Maintenance', this);
    }

    updateLanguage() {
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguage());
        loadCldr(this.commonData.getNumberingSystems(), this.commonData.getCalendar(), this.commonData.getNumbers(), this.commonData.getTimeZoneNames(), this.commonData.getWeekData());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: this.commonData.getLanguage(), Messages: this.commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData.setCurrentView('MaintenancePage');
    }

    componentWillUnmount() {
        if (this.vm != undefined)
            this.vm.$destroy();

        if (this.commonData != undefined)
            this.commonData.unsubscribe(this.updateLanguage);
    }


    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divMaintenance'>
                    <BasePage title={this.intl.formatMessage({ id: 'menuheader.maintenance' })} navigation={this.intl.formatMessage({ id: 'menuheader.maintenance' })}></BasePage>
                    <ScheduleComponent id='scheduleMaintenance' ref={schedule => this.scheduleObj = schedule}
                        eventSettings={{ dataSource: this.data }}>
                        <ViewsDirective>
                            <ViewDirective option='Month' />
                        </ViewsDirective>
                        <Inject services={[Month]} />
                    </ScheduleComponent>
                </div>
            </IntlProvider >
        );
    }
}

MaintenancePage.propTypes = {
};

export default MaintenancePage;