import React from 'react';
import PropTypes from 'prop-types';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import '../styles/sidebarMenuMobile.css';
import { injectIntl } from 'react-intl';

class SidebarMenuMobile extends React.Component {

    constructor(props) {
        super(props);

    }

    templateSideBarMenuMobile(e) {
        let iconSrc = '/images/icons/' + e.Route.TemplateId.toLowerCase() + '.svg';
        return (

            <table className="templateList ">
                <tr>
                    <td id='iconMobile' className='e-avatar e-avatar-xsmall e-avatar-circle'   ><img id='imgList' src={iconSrc}></img></td>
                    <td className="templateSideBarMenuMobile">{e.Title}</td>
                </tr>
            </table>
        )
    }

    informationInstall() {
        return (
            <div id='divInstallInfo'>
                <p >
                    {this.props.InstallationInfo1}
                </p>
                <p >
                    {this.props.InstallationInfo2}
                </p>
            </div>
        )

    }

    render() {
        let widthSidebar = '0px';

        if (this.props.SidebarMenuMobileVisible == 1)
            widthSidebar = '50%';
        let menuMobile = [];
        for (let i = 0; i < this.props.MenuHeader.length; i++)
            if (this.props.MenuHeader[i].Mobile === true)
                menuMobile.push(this.props.MenuHeader[i])
        for (let i = 0; i < menuMobile.length; i++)
            menuMobile[i].Title = this.props.intl.formatMessage({ id: menuMobile[i].Title });

        return (
            <div id='divBlockSidebarmenuMobile'>
                <div id='divSidebarmenuMobile' className='slide-in-left'
                    style={{ width: widthSidebar }} >
                    <ListViewComponent
                        id="menuList"
                        dataSource={menuMobile}
                        fields={{ text: 'Title' }}
                        select={this.props.MenuMobileSelect}
                        template={this.templateSideBarMenuMobile.bind(this)}
                    />
                    {this.informationInstall()}
                </div>
            </div>
        )

    }
}



SidebarMenuMobile.propTypes = {
    SidebarMenuMobileVisible: PropTypes.bool,
    MenuHeader: PropTypes.object,
    intl: PropTypes.object,
    MenuMobileSelect: PropTypes.func,
    InstallationInfo1: PropTypes.string,
    InstallationInfo2: PropTypes.string
};

export default injectIntl(SidebarMenuMobile);