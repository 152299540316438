import * as React from 'react';
import dotnetify from 'dotnetify';

import '../styles/setupUsersPage.css';

import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import SidebarSetupUsers from '../components/SidebarSetupUsers';

import { L10n, setCulture } from '@syncfusion/ej2-base';
import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class SetupUsersPage extends React.Component {

    constructor(props) {
        super(props);

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);


        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            Users: [],
            SelectedUser: [],
            SidebarSetupUsersVisible: 0,
            IsNewUser: false
        };

        this.vm = dotnetify.react.connect('SetupUsers', this);
    }

    updateLanguage() {
        let commonData = CommonDataManager.getInstance();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData?.setCurrentView('SetupUsersPage');
    }

    componentWillUnmount() {
        this.vm?.$destroy();
        this.commonData?.unsubscribe(this.updateLanguage);
    }


    clearSelection() {
        let cloneUsers = JSON.parse(JSON.stringify(this.state.Users));
        for (let i = 0; i < cloneUsers.length; i++)
            cloneUsers[i].Selected = false;
        this.setState({ Users: cloneUsers, SidebarSetupUsersVisible: 0, SelectedUser: [], IsNewUser: false });
    }

    changeUser(direction) {

        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state?.SelectedUser?.Id);

        if (direction == -1)
            selectedRowIndex--;

        if (direction == 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) return;

        if (selectedRowIndex > pageRows.length - 1) return;

        let newId = pageRows[selectedRowIndex].Id;

        let cloneUsers = JSON.parse(JSON.stringify(this.state.Users));

        for (let i = 0; i < cloneUsers.length; i++)
            cloneUsers[i].Selected = cloneUsers[i].Id === newId;

        this.setState({ Users: cloneUsers, SelectedUser: cloneUsers.filter(h => h.Selected)[0] });
    }

    refreshUsers() {
        this.vm?.$dispatch({ RefreshUsers: '' });
    }

    newUser() {
        this.setState({ SidebarSetupUsersVisible: 1, IsNewUser: true });
    }

    rowUserSelected() {
        var selected;
        var id = -1;

        if (this.gridInstance.getSelectedRecords().length == 1) {
            id = this.gridInstance.getSelectedRecords()[0].Id;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        if (selected == undefined)
            id = -1;
        else
            if (selected.Selected)
                id = -1;

        var cloneUsers = JSON.parse(JSON.stringify(this.state)).Users;

        for (let i = 0; i < cloneUsers.length; i++) {
            cloneUsers[i].Selected = (cloneUsers[i].Id == id);
        }

        this.setState({ Users: cloneUsers, SidebarSetupUsersVisible: (id != -1) ? 1 : 0, SelectedUser: selected, IsNewUser: false });
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divSetup'>
                    <BasePage title={this.intl.formatMessage({ id: 'menusetupheader.users' })} navigation={this.intl.formatMessage({ id: 'menusetupheader.users' })}></BasePage>
                    <div id='divToolbarSetupUsers' className='row'>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                        </div>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                            <ButtonComponent id='btnNewUser' className='Button' onClick={() => this.newUser()}><FormattedMessage id='setup_users.new_user' /></ButtonComponent>
                        </div>
                    </div>
                    <div id='divSetupUsersGrid'>
                        <GridComponent id='setupUsersGrid' ref={grid => this.gridInstance = grid}
                            dataSource={this.state.Users} enableHover={true}
                            allowFiltering={true} enableToggle={true}
                            filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                            allowSorting={true} allowSelection={true}
                            rowSelected={() => this.rowUserSelected()}>
                            <ColumnsDirective>
                                <ColumnDirective field='Selected' headerText='' width='50px' displayAsCheckBox={true} allowFiltering={false}></ColumnDirective>
                                <ColumnDirective field='Id' visible={false} isPrimaryKey={true}></ColumnDirective>
                                <ColumnDirective field='UserName' headerText={this.intl.formatMessage({ id: 'username' })}></ColumnDirective>
                                <ColumnDirective field='Name' headerText={this.intl.formatMessage({ id: 'name' })}></ColumnDirective>
                                <ColumnDirective field='Surname' headerText={this.intl.formatMessage({ id: 'surname' })}></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort]} />
                        </GridComponent>
                        <SidebarSetupUsers
                            IsNewUser={this.state.IsNewUser}
                            SidebarSetupUsersVisible={this.state.SidebarSetupUsersVisible}
                            SelectedUser={this.state.SelectedUser}
                            ClearSelection={this.clearSelection.bind(this)}
                            RefreshUsers={this.refreshUsers.bind(this)}
                            ChangeUser={this.changeUser.bind(this)}>
                        </SidebarSetupUsers>
                        
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

SetupUsersPage.propTypes = {
};

export default SetupUsersPage;