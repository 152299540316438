// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divDialogAlert{\n    width: 100%;\n}\n\n.divBtnAlert{\n    padding-top: 153px;\n}\n\n#messageAlert{\n    padding-top: 90px;\n}\n\n#titleAlert{\n    padding-top: 12px;\n    font-size: medium;\n    color:red;\n}", "",{"version":3,"sources":["webpack://./src/styles/dialogAlert.css"],"names":[],"mappings":"AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,SAAS;AACb","sourcesContent":["@import \"app.css\";\n\n#divDialogAlert{\n    width: 100%;\n}\n\n.divBtnAlert{\n    padding-top: 153px;\n}\n\n#messageAlert{\n    padding-top: 90px;\n}\n\n#titleAlert{\n    padding-top: 12px;\n    font-size: medium;\n    color:red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
