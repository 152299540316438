import React from 'react';
import { convertFahrenheit } from '../../components/utils/TemperatureConversion';
import CommonDataManager from '../../components/CommonDataManager';


function visibleTemperature(props) {
    switch (props.Status) {
        case 0: return -1;
        case 1: return -1;
        case 2: return 0;
        case 3: return 0;
        case 4: return 0;
        case 5: return 0;
        case 6: return -1;

        default: return -1;
    }

}

export function tOvenTemplate(props) {

    this.commonData = CommonDataManager.getInstance();
    this.umTemperature = this.commonData.getUmTemperature();

    try {

        let tOven = props.TOven;
        if ((tOven === null) || (visibleTemperature(props) === -1))
            return (
                <div></div>
            )
        else
            if (this.umTemperature === 'F')
                tOven = convertFahrenheit(tOven)
        return (
            <div>{tOven}°{this.umTemperature}</div>
        );

    }
    catch (exp) {
        return (
            <div></div>
        )
    }

}

export function tFridgeTemplate(props) {

    this.commonData = CommonDataManager.getInstance();
    this.umTemperature = this.commonData.getUmTemperature();

    try {

        let tFridge = props.TFridge;
        if ((tFridge === null) || (visibleTemperature(props) === -1))
            return (
                <div></div>
            )
        else
            if (this.umTemperature === 'F')
                tFridge = convertFahrenheit(tFridge)
        return (
            <div>{tFridge}°{this.umTemperature}</div>
        );

    }
    catch (exp) {
        return (
            <div></div>
        )
    }
}