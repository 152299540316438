import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import '../styles/dialogAlert.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';


class DialogAlert extends React.Component {

    constructor() {
        super(...arguments);
    }

    dialogClose() {
        this.props.HideAlert();
    }

    dialogConfirm() {
        this.props.Confirm(this.props.Option);

    }

    renderAlert() {
        let idMessage = 'alert_cycle_progress';

        if (this.props.Select.length > 1)
            idMessage = 'alert_multi_select';

        return (
            <div id='divDialogAlert'>
                <h3 id='titleAlert'><FormattedMessage id='warning' /></h3>

                <div id='messageAlert'>
                    <span><FormattedMessage id={idMessage} /></span>
                </div>

                <div className='row divBtnAlert'>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnSet' onClick={this.dialogClose.bind(this)}><FormattedMessage id='cancel' /></ButtonComponent>
                    </div>

                    <div className='col-xs-6'>
                        <ButtonComponent id='btnSet' onClick={this.dialogConfirm.bind(this)}><FormattedMessage id='confirm' /></ButtonComponent>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className="dialog">
                    {this.renderAlert()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        );
    }
}

DialogAlert.propTypes = {
    Detail: PropTypes.any,
    intl: PropTypes.object,
    HideAlert: PropTypes.func,
    Confirm: PropTypes.func,
    Option: PropTypes.string,
    Select: PropTypes.any
};

export default injectIntl(DialogAlert);