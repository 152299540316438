import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';
import '../styles/progressBar.css';

class ProgressBar extends React.Component {

    render() {
        let widthProgress = '90%';
        if(this.props.Option ==='dashBoard' || this.props.Option === 'USBPage') {widthProgress='70%'}
        return (
            <div id='divProgressbar'>
                <ProgressBarComponent id={this.props?.Id} className='progressBar' type='Circular' cornerRadius='Round' isIndeterminate={true} value={20}
                    trackThickness={10}
                    progressThickness={10}
                    showProgressValue={true}
                    enableRtl={false}
                    height='160px'
                    width={widthProgress}
                    trackColor='var(--main-color)'
                    progressColor='var(--progressBar-color)'
                    radius='100%'
                    animation={{
                        enable: true,
                        duration: 5000,
                        delay: 0
                    }}
                />
                <div className='lblValue' id='spanWaitMessage' >{this.props?.Message}</div>
            </div>
        )
    }
}

ProgressBar.propTypes = {
    Id:PropTypes.string,
    Message: PropTypes.string,
    Option:PropTypes.string
};
export default ProgressBar;