import React from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Inject, VirtualScroll } from '@syncfusion/ej2-react-grids';
import '../styles/sidebarSetupUsers.css';
import dotnetify from 'dotnetify';
import Toast from '../components/Toast';
import AvatarTemplate from '../components/templates/AvatarTemplate';
import DialogUploader from '../components/DialogUploader';
import Toolbar from '../components/Toolbar';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

import { injectIntl, FormattedMessage } from 'react-intl';

class SidebarSetupUsers extends React.Component {

    constructor(props) {
        super(props);

        this.vm = dotnetify.react.connect('SetupUserDetail', this);

        this.state = {
            UserDetail: [],
            NewUser: [],
            Notification: [],
            hideDialogUploader: true,
            SelectedTabPermission: 'site',
        };

    }

    componentWillUnmount() {
        this.vm?.$destroy();
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.SidebarSetupUsersVisible !== this.props?.SidebarSetupUsersVisible) {
            this.setState({ LocalSidebarManageVisible: this.props.SidebarSetupUsersVisible });
        }

        if (prevProps?.IsNewUser != this.props?.IsNewUser)
            if (this.props.IsNewUser)
                this.setState({ UserDetail: this.state.NewUser });

        if (prevProps?.SelectedUser?.Id != this.props?.SelectedUser?.Id) {
            this.vm?.$dispatch({ UserID: this.props?.SelectedUser?.Id });
        }
    }

    changeUserDetail(e) {
        this.setState({
            UserDetail: { ...this.state.UserDetail, [e.target.name]: e.target.value }
        })
    }

    changeSetupPermission(e) {
        this.setState({
            UserDetail: { ...this.state.UserDetail, [e.target.name]: e.target.checked }
        })
    }

    changeEnableUser(e) {
        this.setState({
            UserDetail: { ...this.state.UserDetail, [e.target.name]: e.target.checked }
        })
    }


    saveUser() {
        this.gridSitesPermissionsInstance?.endEdit();
        this.gridDepartmentsPermissionsInstance?.endEdit();
        this.vm?.$dispatch({ SaveUser: this.state.UserDetail });
    }

    changePassword() {
        this.vm?.$dispatch({ ChangePassword: this.state.UserDetail });
    }

    renderContentSitesPermissions() {
        return (
            <div>
                <GridComponent id='sitesPermissionsGrid'
                    dataSource={this.state?.UserDetail?.SitesPermissions} enableHover={true} enableVirtualization={false}
                    allowSorting={false} allowSelection={false} editSettings={{ allowEditing: true, allowAdding: false, allowDeleting: false, mode: 'Normal' }}
                    ref={grid => this.gridSitesPermissionsInstance = grid}>
                    <ColumnsDirective>
                        <ColumnDirective field='SiteId' headerText='ID' visible={false} allowEditing={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective field='SiteName' headerText={this.props.intl.formatMessage({ id: 'site' })} allowEditing={false}></ColumnDirective>
                        <ColumnDirective field='PermissionMonitor' headerText={this.props.intl.formatMessage({ id: 'permission_monitor' })} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                        <ColumnDirective field='PermissionRemoteControl' headerText={this.props.intl.formatMessage({ id: 'permission_remote_control' })} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                        <ColumnDirective field='PermissionManage' headerText={this.props.intl.formatMessage({ id: 'permission_manage' })} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                        <ColumnDirective field='PermissionHistory' headerText={this.props.intl.formatMessage({ id: 'permission_history' })} displayAsCheckBox={true} editType='booleanedit'></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[VirtualScroll, Edit]} />
                </GridComponent>
            </div>
        );
    }

    renderContentDepartmentsPermissions() {
        return (
            <div>
                <GridComponent id='departmentsPermissionsGrid'
                    dataSource={this.state?.UserDetail?.DeparmentsPermissions} enableHover={true} enableVirtualization={false}
                    allowSorting={false} allowSelection={false} editSettings={{ allowEditing: true, allowAdding: false, allowDeleting: false, mode: 'Normal' }}
                    ref={grid => this.gridDepartmentsPermissionsInstance = grid}
                    heightAdjustMode='Auto'>
                    <ColumnsDirective>
                        <ColumnDirective field='DepartmentId' headerText='ID' visible={false} allowEditing={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective field='DepartmentName' headerText={this.props.intl.formatMessage({ id: 'department' })} allowEditing={false}></ColumnDirective>
                        <ColumnDirective field='PermissionMonitor' headerText={this.props.intl.formatMessage({ id: 'permission_monitor' })} displayAsCheckBox={true} editType='booleanedit' ></ColumnDirective>
                        <ColumnDirective field='PermissionRemoteControl' headerText={this.props.intl.formatMessage({ id: 'permission_remote_control' })} displayAsCheckBox={true} editType='booleanedit' ></ColumnDirective>
                        <ColumnDirective field='PermissionManage' headerText={this.props.intl.formatMessage({ id: 'permission_manage' })} displayAsCheckBox={true} editType='booleanedit' ></ColumnDirective>
                        <ColumnDirective field='PermissionHistory' headerText={this.props.intl.formatMessage({ id: 'permission_history' })} displayAsCheckBox={true} editType='booleanedit' ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[VirtualScroll, Edit]} />
                </GridComponent>
            </div>
        );
    }


    openDialogUploader() {
        this.setState({ hideDialogUploader: false })
    }

    hideUploader() {
        this.setState({ hideDialogUploader: true })
    }

    hideAndSaveUploader(e) {
        this.setState({ hideDialogUploader: true, UserDetail: { ...this.state.UserDetail, UserImage: e.UserImage } });
    }

    renderUploader() {
        if (!this.state.hideDialogUploader)
            return (
                <DialogUploader
                    UserImage={this.state.UserDetail.UserImage}
                    UserName={this.props.SelectedUser.UserName}
                    HideUploader={this.hideUploader.bind(this)}
                    HideAndSaveUploader={this.hideAndSaveUploader.bind(this)}
                    IsNewUser={this.props.IsNewUser}
                    Option='setupUsers'
                />

            )
    }

    selectedTabPermission(args) {
        this.setState({ SelectedTabPermission: args });

    }
    renderUserDetail() {
        if ((!this.props.SidebarSetupUsersVisible) || (this.state.UserDetail == null))
            return (null);
        else
            return (
                <div id='divUser'>
                    <Toast
                        Notification={this.state.Notification}
                        Refresh={this.props.RefreshUsers}
                        Option='users'
                    />
                    <div className='row rowHeader'>
                        <div className='col-xs-2' id='divImgTemplate'>
                            <AvatarTemplate
                                UserImage={this.state.UserDetail.UserImage}
                                UserName={this.props.SelectedUser.UserName}
                                Option='setupUsers'
                                IsNewUser={this.props.IsNewUser}
                            ></AvatarTemplate>

                            <div id='divImgCam' className='e-avatar e-avatar-xsmall e-avatar-circle'>
                                <img id='imgCam' src={'/images/icons/camera.svg'} onClick={this.openDialogUploader.bind(this)}></img>
                            </div>


                        </div>
                        <div className='col-xs-10' id='divInfo'>
                            <div className='row rowInfo'>
                                <span className='lblTitle'>{this.props.SelectedUser.UserName}</span>
                            </div>
                            <div className='row rowInfo'>
                                <span className='lblValue'>{this.props.SelectedUser.Name} {this.props.SelectedUser.Surname}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='divUserTop'>
                            <span className='lblValue'><FormattedMessage id='setup_user' /></span>
                            <div id='divSaveUser' className='row'>
                                <div className='col-xs-6'>
                                </div>
                                <div className='col-xs-6'>
                                    <ButtonComponent id='btnSaveUser' className='Button' onClick={() => this.saveUser()}><FormattedMessage id='save_user' /></ButtonComponent>
                                </div>
                            </div>
                        </div>

                        <div id='divUserDetail'>
                            <div className='row'>
                                <div className='col-xs-6'>
                                    <span className='lblDescription'><FormattedMessage id='username' /></span>
                                    <input className='e-input' type='text' name='UserName' value={this.state.UserDetail.UserName || ''} onChange={this.changeUserDetail.bind(this)}  id='txtUserName' placeholder='' />
                                </div>

                                <div className='col-xs-12' id='boxPermissions'>
                                    <span className='lblDescription'><FormattedMessage id='user_enabled' /></span>
                                </div>
                                <div className='col-xs-12' id='boxPermissions'>
                                    <CheckBoxComponent name='Enabled' checked={this.state?.UserDetail?.Enabled} onChange={this.changeEnableUser.bind(this)} />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-xs-6'>
                                    <span className='lblDescription'><FormattedMessage id='password' /></span>
                                    <input className='e-input' type='password' name='Password' value={this.state.UserDetail.Password || ''} onChange={this.changeUserDetail.bind(this)} id='txtPassword' placeholder='' />
                                </div>
                                <div className='col-xs-6'>
                                    <span className='lblDescription'><FormattedMessage id='confirm_password' /></span>
                                    <input className='e-input' type='password' name='ConfirmPassword' value={this.state.UserDetail.ConfirmPassword || ''} onChange={this.changeUserDetail.bind(this)} id='txtConfirmPassword' placeholder='' />
                                </div>
                            </div>
                            <div className='row'>
                                <div id='divChangePassword' className='col-xs-12'>
                                    <ButtonComponent id='btnChangePassword' className='Button' onClick={() => this.changePassword()}><FormattedMessage id='change_password' /></ButtonComponent>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-6'>
                                    <span className='lblDescription'><FormattedMessage id='name' /></span>
                                    <input className='e-input' type='text' name='Name' value={this.state.UserDetail.Name || ''} onChange={this.changeUserDetail.bind(this)} id='txtName' placeholder='' />
                                </div>
                                <div className='col-xs-6'>
                                    <span className='lblDescription'><FormattedMessage id='surname' /></span>
                                    <input className='e-input' type='text' name='Surname' value={this.state.UserDetail.Surname || ''} onChange={this.changeUserDetail.bind(this)} id='txtSurname' placeholder='' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-6'>
                                    <span className='lblDescription'><FormattedMessage id='email' /></span>
                                    <input className='e-input' type='text' name='Email' value={this.state.UserDetail.Email || ''} onChange={this.changeUserDetail.bind(this)} id='txtEmail' placeholder='' />
                                </div>
                                <div className='col-xs-6'>
                                    <span className='lblDescription'><FormattedMessage id='mobile' /></span>
                                    <input className='e-input' type='text' name='Mobile' value={this.state.UserDetail.Mobile || ''} onChange={this.changeUserDetail.bind(this)} id='txtMobile' placeholder='' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12' id='boxPermissions'>
                                    <span className='lblDescription'><FormattedMessage id='permissions' /></span>
                                </div>
                                <div className='col-xs-12' id='boxPermissions'>
                                    <CheckBoxComponent label={this.props.intl.formatMessage({ id: 'setup_permission' })} name='SetupPermission' checked={this.state?.UserDetail?.SetupPermission} onChange={this.changeSetupPermission.bind(this)} />
                                </div>
                            </div>
                            <div className="control-pane" id='toolbartabPermission'>
                                <div className="control-section tab-control-section">

                                    <Toolbar
                                        Option='setupUsers'
                                        Items={[{ Title: 'site' }, { Title: 'department' }]}
                                        ToolbarSelect={this.selectedTabPermission.bind(this)}
                                    />
                                    {this.state?.SelectedTabPermission === 'site' && this.renderContentSitesPermissions()}
                                    {this.state?.SelectedTabPermission === 'department' && this.renderContentDepartmentsPermissions()}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            );
    }

    renderButtonsSidebarManage() {
        return (
            <div id='divButtonsSidebar'>
                <div id='divButtonClear'>
                    <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                </div>
                <div id="divButtonPrev">
                    <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeUser(-1)} iconCss='e-icons e-sidebarprev' />
                </div>
                <div id="divButtonNext">
                    <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeUser(+1)} iconCss='e-icons e-sidebarnext' />
                </div>
            </div>
        );
    }

    renderContentSidebarSetupUsers() {
        if (this.props.SidebarManageVisible === 0)
            return null;

        let stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            return (
                <div>
                    {this.renderUploader()}
                    {this.renderButtonsSidebarManage()}
                    {this.renderUserDetail()}
                </div>
            )
    }

    render() {
        var widthSidebar = { width: '0px' };
        var stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            widthSidebar = { width: '50%' };


        return (
            <div ref={Sidebar => this.sidebarobj = Sidebar}
                id='sidebarSetupUsers' style={widthSidebar}>
                {this.renderContentSidebarSetupUsers()}
            </div>
        );
    }
}

SidebarSetupUsers.propTypes = {
    intl: PropTypes.object,
    SidebarManageVisible: PropTypes.bool,
    SidebarSetupUsersVisible: PropTypes.number,
    IsNewUser: PropTypes.bool,
    SelectedUser: PropTypes.any,
    RefreshUsers: PropTypes.func,
    ClearSelection: PropTypes.func,
    ChangeUser: PropTypes.func,
    UserImage: PropTypes.any,
};

export default injectIntl(SidebarSetupUsers);