import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/manage/remoteControl.css'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import isEqual from 'react-fast-compare';
import DialogAlert from '../../components/DialogAlert';
import { injectIntl, FormattedMessage } from 'react-intl';

class RemoteControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideDialogAlert: true,
            SelectedCycle: null,
            Option: ''
        };
    }

    remoteControlDisable() {
        this.enableOFF = false;
        this.enableON = false;
        this.enableMUTE = false;
        this.enableC1 = false;
        this.enableC2 = false;
        this.enableC3 = false;
        this.enableC4 = false;
        this.enableC5 = false;
        this.enableC6 = false;
        this.enableC7 = false;
        this.enableC8 = false;
        this.enableR_ = false;
        this.enableLB1 = false;
        this.enableLB2 = false;
        this.enableWP = false;
    }

    shouldComponentUpdate(nextProps) {

        //aggiorna solo se il numero di dispositivi selezionati è diverso o se i dispositivi selezionati sono differenti
        let equals = nextProps?.SelectedDevices?.length === this.props?.SelectedDevices?.length &&
            nextProps?.SelectedDevices.every((e) => this.props.SelectedDevices?.filter((s) => s.Id === e.Id).length === 1);

        //se i dispositivi selezionati non sono cambiati,e se ne è stato selezionato solo uno, verifica se aggiornare solo se c'e' stata una modifica nei dati del carrello
        if (equals)
            if (this.props?.SelectedDevices?.length === 1)
                equals = !isEqual(nextProps?.SelectedDevices[0]?.Detail, this.props?.SelectedDevices[0]?.Detail);


        //se non ci sono modifiche,aggiorna lo stato dei bottoni di controllo remoto
        if (!equals) {
            if (nextProps?.SelectedDevices?.length === 1) {
                let r = nextProps?.SelectedDevices[0]?.Remotes;

                if (r !== undefined) {
                    this.enableOFF = (r.filter(x => x.RemoteCmd === 'OFF').length === 1 ? r.filter(x => x.RemoteCmd === 'OFF')[0].Enabled : false);
                    this.enableON = (r.filter(x => x.RemoteCmd === 'ON').length === 1 ? r.filter(x => x.RemoteCmd === 'ON')[0].Enabled : false);
                    this.enableC1 = (r.filter(x => x.RemoteCmd === 'C_1').length === 1 ? r.filter(x => x.RemoteCmd === 'C_1')[0].Enabled : false);
                    this.enableC2 = (r.filter(x => x.RemoteCmd === 'C_2').length === 1 ? r.filter(x => x.RemoteCmd === 'C_2')[0].Enabled : false);
                    this.enableC3 = (r.filter(x => x.RemoteCmd === 'C_3').length === 1 ? r.filter(x => x.RemoteCmd === 'C_3')[0].Enabled : false);
                    this.enableC4 = (r.filter(x => x.RemoteCmd === 'C_4').length === 1 ? r.filter(x => x.RemoteCmd === 'C_4')[0].Enabled : false);
                    this.enableC5 = (r.filter(x => x.RemoteCmd === 'C_5').length === 1 ? r.filter(x => x.RemoteCmd === 'C_5')[0].Enabled : false);
                    this.enableC6 = (r.filter(x => x.RemoteCmd === 'C_6').length === 1 ? r.filter(x => x.RemoteCmd === 'C_6')[0].Enabled : false);
                    this.enableC7 = (r.filter(x => x.RemoteCmd === 'C_7').length === 1 ? r.filter(x => x.RemoteCmd === 'C_7')[0].Enabled : false);
                    this.enableC8 = (r.filter(x => x.RemoteCmd === 'C_8').length === 1 ? r.filter(x => x.RemoteCmd === 'C_8')[0].Enabled : false);
                    this.enableR_ = (r.filter(x => x.RemoteCmd === 'R_').length === 1 ? r.filter(x => x.RemoteCmd === 'R_')[0].Enabled : false);
                    this.enableLB1 = (r.filter(x => x.RemoteCmd === 'EVO_LB_1').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_LB_1')[0].Enabled : false);
                    this.enableLB2 = (r.filter(x => x.RemoteCmd === 'EVO_LB_2').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_LB_2')[0].Enabled : false);
                    this.enableWP = (r.filter(x => x.RemoteCmd === 'EVO_WP').length === 1 ? r.filter(x => x.RemoteCmd === 'EVO_WP')[0].Enabled : false);
                    this.enableMUTE = (r.filter(x => x.RemoteCmd === 'MUTE').length === 1 ? r.filter(x => x.RemoteCmd === 'MUTE')[0].Enabled : false);
                }
            }
            else {
                this.enableOFF = true;
                this.enableON = true;
                this.enableC1 = true;
                this.enableC2 = true;
                this.enableC3 = true;
                this.enableC4 = true;
                this.enableC5 = true;
                this.enableC6 = true;
                this.enableC7 = true;
                this.enableC8 = true;
                this.enableR_ = true;
                this.enableLB1 = true;
                this.enableLB2 = true;
                this.enableWP = true;
                this.enableMUTE = true;
            }
        }

        return !equals;
    }

    cycleStart() {
        let startCycle = 'C_' + this.state.SelectedCycle;
        this.props.RemoteControl(startCycle)
    }

    cycleStartPrefer(cicle) {
        let startCyclePrefer = 'C_' + cicle;
        this.props.RemoteControl(startCyclePrefer)
    }

    dropdownCyclesChanged(args) {
        if (args.isInteracted)
            this.setState({ SelectedCycle: this.dropdownCycles.value });
    }

    renderRemoteControlV3() {

        let cicle = [];
        let btn = [];
        btn.length = 4;
        let listPreferred = this.props?.Detail?.Cycles?.filter(item => item.IsPreferred === true);
        let visibilecyclesPreferred = null;

        if (listPreferred !== undefined || this.props?.Detail?.Cycles?.filter(item => item.IsPreferred === true).length > 0) {
            for (let i = 0; i < listPreferred.length; i++)
                cicle[i] = listPreferred[i].ID;
            if (listPreferred.length > 4)
                for (let i = 0; i < btn.length; i++)

                    btn[i] = <ButtonComponent className='e-flat btnRemote' onClick={() => this.cycleStartPrefer(cicle[i])}><FormattedMessage id='remote_cycle' /> {cicle[i]}</ButtonComponent>
            else

                for (let i = 0; i < listPreferred.length; i++)
                    btn[i] = <ButtonComponent className='e-flat btnRemote' onClick={() => this.cycleStartPrefer(cicle[i])}><FormattedMessage id='remote_cycle' /> {cicle[i]}</ButtonComponent>
        }
        if (this.props?.Detail?.Cycles?.filter(item => item.IsPreferred === true).length > 0)
            visibilecyclesPreferred = (
                <div>
                    <span id='lblInfoRemote'><FormattedMessage id='cycles_preferred' /></span>
                    {btn}
                </div>
            )


        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 0)

            return (
                <div id='divControlV3'>
                    <div >
                        {visibilecyclesPreferred}
                        <span id='lblInfoRemote'><FormattedMessage id='remote_cycles' /></span>
                        <div id='listCycle'>
                            <DropDownListComponent className='listCycle' width='280px'
                                ref={dropdown => this.dropdownCycles = dropdown}
                                fields={{ value: 'ID', text: 'Name' }}
                                dataSource={this.props?.Detail?.Cycles}
                                change={this.dropdownCyclesChanged.bind(this)} />
                            <ButtonComponent className='e-flat btnStartCycle' disabled={this.state.SelectedCycle === null} onClick={() => this.cycleStart()}><FormattedMessage id='start_cycle' /></ButtonComponent>
                        </div>
                    </div>
                </div>)
    }

    renderRemoteControlCYCLE() {
        let multiCycle = false;
        let visibileCyclesV3;

        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 0) { visibileCyclesV3 = true }
        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length > 1 || this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 1) { multiCycle = true }
        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length > 0 && this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 0) { multiCycle = true }

        if (this.props?.SelectedDevices === undefined)
            return null;

        let extraCycle = null;
        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length === 0)
            if (this.props.SelectedDevices.filter(d => d.HasColdCycles === false).length === 0)
                extraCycle = (
                    <div>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableC5} onClick={() => this.props.RemoteControl('C_5')}><FormattedMessage id='remote_cycle' /> 5</ButtonComponent>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableC6} onClick={() => this.props.RemoteControl('C_6')}><FormattedMessage id='remote_cycle' /> 6</ButtonComponent>
                    </div>
                );
            else
                extraCycle = (<ButtonComponent className='e-flat btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>);

        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v1').length === 0)
            extraCycle = (<ButtonComponent className='e-flat btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>);

        if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v1').length !== 0)
            if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v2').length !== 0)
                if (this.props.SelectedDevices.filter(d => d.HasColdCycles === false).length === 0)
                    extraCycle = (<ButtonComponent className='e-flat btnRemote' disabled={!this.enableC4} onClick={() => this.props.RemoteControl('C_4')}><FormattedMessage id='remote_cycle' /> 4</ButtonComponent>);


        return (
            <div> {!multiCycle &&
                <div>
                    {!visibileCyclesV3 &&
                        <div>
                            <span id='lblInfoRemote'><FormattedMessage id='remote_cycle' /></span>
                            <ButtonComponent className='e-flat btnRemote' immediateRender='true' disabled={!this.enableC1} onClick={() => this.props.RemoteControl('C_1')}><FormattedMessage id='remote_cycle' /> 1</ButtonComponent>
                            <ButtonComponent className='e-flat btnRemote' immediateRender='true' disabled={!this.enableC2} onClick={() => this.props.RemoteControl('C_2')}><FormattedMessage id='remote_cycle' /> 2</ButtonComponent>
                            <ButtonComponent className='e-flat btnRemote' immediateRender='true' disabled={!this.enableC3} onClick={() => this.props.RemoteControl('C_3')}><FormattedMessage id='remote_cycle' /> 3</ButtonComponent>
                            {extraCycle}
                        </div>
                    }
                    {visibileCyclesV3 &&
                        <div>
                            {this.renderRemoteControlV3()}
                        </div>
                    }
                </div>
            }
            </div>
        );
    }

    renderRemoteControlMUTE() {
        if (this.props?.SelectedDevices !== undefined)
            if (this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v1').length > 0 || this.props.SelectedDevices.filter(d => d.DeviceVersion === 'v3').length > 0)
                return null;

        return (
            <ButtonComponent className='e-flat btnRemoteMute' disabled={!this.enableMUTE} onClick={() => this.props.RemoteControl('MUTE')}><FormattedMessage id='remote_mute' /></ButtonComponent>
        );
    }

    recipeStart() {
        let startRecipe = { IDCycle: this.dropDownReceipt };
        let recipeStartTest = 'R_' + startRecipe;
        this.props.RemoteControl(recipeStartTest)
    }

    renderRemoteControlEVO() {

        //comandi cicli evomulti visibili solo se non ci sono dispositivi evomulti selezionati
        let visibileRemoteCmdEvo = this.props?.SelectedDevices?.filter(d => !d.IsEvo).length === 0;

        //comando ricetta visibile solo se è stato selezionato un singolo dispositivo ed è un modello evomulti
        //(come è possibile fare partire simultaneamente l'avvio di ricette di più evomulti? potrebbero avere ricette totalmente differenti...)
        let visibileRemoteReceiptEvo = this.props?.SelectedDevices?.filter(d => d.IsEvo).length === 1;


        //elabora il nome della ricette aggiungendo il numero della ricetta con il suo relativo nome 
        let listRecipes = this.props?.Detail?.Cycles?.filter(item => item.ID > 8);
        if (listRecipes !== undefined)
            for (let i = 0; i < listRecipes.length; i++) {
                listRecipes[i].CompleteName = listRecipes[i].IDCycle + ' ' + listRecipes[i].Name;
            }

        return (
            visibileRemoteCmdEvo &&
            <div id='divRemoteEVO'>
                {visibileRemoteCmdEvo &&
                    <div>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableC5} onClick={() => this.props.RemoteControl('C_5')}><FormattedMessage id='remote_cycle' /> 5</ButtonComponent>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableC6} onClick={() => this.props.RemoteControl('C_6')}><FormattedMessage id='remote_cycle' /> 6</ButtonComponent>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableC7} onClick={() => this.props.RemoteControl('C_7')}><FormattedMessage id='remote_cycle' /> 7</ButtonComponent>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableC8} onClick={() => this.props.RemoteControl('C_8')}><FormattedMessage id='remote_cycle' /> 8</ButtonComponent>
                    </div>
                }
                {visibileRemoteReceiptEvo &&
                    <div>
                        <span id='lblInfoRemote'><FormattedMessage id='recipes' /></span>
                        <div id='listRecipe'>
                            <DropDownListComponent className='listRecipe' width='280px'
                                ref={dropdown => this.dropDownReceipt = dropdown}
                                enabled={this.enableR_}
                                fields={{ id: 'IDCycle', text: 'CompleteName' }}
                                dataSource={listRecipes}
                            />
                            <ButtonComponent className='e-flat btnRecipe' disabled={!this.enableR_} onClick={this.recipeStart.bind(this)}><FormattedMessage id='start_recipe' /></ButtonComponent>
                        </div>
                    </div>}
                {visibileRemoteCmdEvo &&
                    <div>
                        <span id='lblInfoRemote'><FormattedMessage id='actions' /></span>
                        <div>
                            <ButtonComponent className='e-flat btnRemoteActions' disabled={!this.enableLB1} onClick={() => this.props.RemoteControl('EVO_LB_1')}><FormattedMessage id='remote_light' /> 1</ButtonComponent>
                            <ButtonComponent className='e-flat btnRemoteActions' disabled={!this.enableLB2} onClick={() => this.props.RemoteControl('EVO_LB_2')}><FormattedMessage id='remote_light' /> 2</ButtonComponent>
                            <ButtonComponent className='e-flat btnRemoteActionsPlate' disabled={!this.enableWP} onClick={() => this.props.RemoteControl('EVO_WP')}><FormattedMessage id='remote_plate_warmer' /></ButtonComponent>
                        </div>
                    </div>
                }
            </div>
        );
    }

    hideAlert() {
        this.setState({ hideDialogAlert: true })
    }

    confirm(option) {
        this.props.RemoteControl(option)
        this.setState({ hideDialogAlert: true })
    }

    renderDialogAlert() {
        if (!this.state.hideDialogAlert)
            return (
                <DialogAlert
                    HideAlert={this.hideAlert.bind(this)}
                    Confirm={this.confirm.bind(this)}
                    Option={this.state.Option}
                    Select={this.props.SelectedDevices}
                    Detail={this.props.Detail}
                />
            )
    }

    offRemote() {
        if (this.props?.Detail?.CycleNumber === null || this.props?.Detail?.CycleNumber === 0) {
            this.props.RemoteControl('OFF')
        }
        else {
            this.setState({ hideDialogAlert: false })
            this.setState({ Option: 'OFF'})
        }
    }

    onRemote() {
        if (this.props?.SelectedDevices?.length === 1) {
            this.props.RemoteControl('ON')
        }
        else
            if (this.props?.SelectedDevices?.length > 1)
                this.setState({ hideDialogAlert: false })
                this.setState({ Option: 'ON'})
               
               
    }

    render() {
        if (this.props?.SelectedDevices?.length === 0)
            return null;

        return (
            <div id='divRemoteControl'>
                <div id='divRemote'>
                    <span id='lblTitleRemote'><FormattedMessage id='remote_controls' /></span>
                    <span id='lblInfoRemote'><FormattedMessage id='status' /></span>
                    <div>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableON} onClick={() => this.onRemote()}><FormattedMessage id='remote_on' /></ButtonComponent>
                        <ButtonComponent className='e-flat btnRemote' disabled={!this.enableOFF} onClick={() => this.offRemote()}><FormattedMessage id='remote_off' /></ButtonComponent>
                        {this.renderRemoteControlMUTE()}
                        {this.renderDialogAlert()}
                    </div>

                    {this.renderRemoteControlCYCLE()}
                </div>
                {this.renderRemoteControlEVO()}
            </div>
        );
    }
}

RemoteControl.propTypes = {
    Detail: PropTypes.any,
    SelectedDevices: PropTypes.any,
    RemoteControl: PropTypes.func,
    CycleNumber: PropTypes.any
};

export default injectIntl(RemoteControl);