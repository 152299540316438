import React from 'react';
import dotnetify from 'dotnetify';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SidebarMenuMobile from '../components/SidebarMenuMobile';
import auth from '../auth';

import { L10n, setCulture } from '@syncfusion/ej2-base';

import CommonDataManager from '../components/CommonDataManager'
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';
import DialogSet from '../components/DialogSet';
import DialogAbout from '../components/DialogAbout';
import USBPage from '../views/USBPage';
import DialogSetupProfile from '../components/DialogSetupProfile';

import isEqual from 'react-fast-compare';

class AppLayout extends React.Component {


  constructor(props) {
    super(props);
    this.fields = { value: 'id', text: 'text' };

    this.vm = dotnetify.react.connect('AppLayout', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: () => auth.logout()
    });
    this.vm.onRouteEnter = (path, template) => (template.Target = 'Content');

    this.languages = [{ id: 'fr', text: 'french' }, { id: 'en', text: 'english' }, { id: 'de', text: 'german' },
    { id: 'es', text: 'spanish' }, { id: 'fi', text: 'finnish' }, { id: 'it', text: 'italian' }, { id: 'nl', text: 'dutch' }];

    this.umTemperatures = [{ id: 'C', text: 'Celsius', }, { id: 'F', text: 'Fahrenheit' }];

    this.themes = [{ id: 'theme1', text: 'theme1' }, { id: 'theme2', text: 'theme2' }];

    this.commonData = CommonDataManager.getInstance();
    this.commonData.subscribe(this.updateLanguage.bind(this));


    this.state = {
      Language: this.commonData.getLanguage(),
      Messages: this.commonData.getMessages(),
      MobileMenuEnabled: (window.innerWidth < this.commonData.getMobileWidth()),
      MenuMobileOpen: false,
      MenuHeader: [],
      MenuSetupHeader: [],
      hideDialogSettings: true,
      hideDialogAbout: true,
      hideUSBPage: true,
      hideSetupProfile: true,
      SidebarMenuMobileVisible: 0,
    };


    L10n.load(this.commonData.getMessages());
    this.intlCache = createIntlCache();
    setCulture(this.commonData.getLanguageExt());
    this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);


  }

  updateLanguage() {
    let commonData = CommonDataManager.getInstance();
    this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
  }

  resizeEvent = () => {
    this.setState({ MobileMenuEnabled: (window.innerWidth < this.commonData.getMobileWidth()) });
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeEvent);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.AppSettings?.Language !== this.state?.AppSettings?.Language)
      this.commonData.setLanguage(this.state.AppSettings.Language);

    if (prevState?.AppSettings?.UmTemperature !== this.state?.AppSettings?.UmTemperature)
      this.commonData.setUmTemperature(this.state.AppSettings.UmTemperature);

    if (prevState?.AppSettings?.Theme !== this.state?.AppSettings?.Theme)
      this.commonData.setTheme(this.state.AppSettings.Theme);

    if (!isEqual(prevState?.Alarms, this.state?.Alarms))
      this.commonData.setAlarms(this.state.Alarms);
  }

  componentWillUnmount() {
    if (this.vm != undefined)
      this.vm.$destroy();

    if (this.commonData != undefined)
      this.commonData.unsubscribe(this.updateLanguage);

    window.removeEventListener('resize', this.resizeEvent);
  }

  openMenuMobile() {
    if (this.state.SidebarMenuMobileVisible === 0)
      this.setState({ SidebarMenuMobileVisible: 1 });
    else
      this.setState({ SidebarMenuMobileVisible: 0 });
  }

  menuMobileSelect(e) {
    this.vm.$routeTo(e.data.Route);
    this.setState({ SidebarMenuMobileVisible: 0 });
  }

  change() {
    this.setState({ MenuMobileOpen: this.sidebarobj.isOpen });
  }

  openDialogSettings() {
    this.setState({ SidebarMenuMobileVisible: 0 });
    this.setState({ hideDialogSettings: false })
  }

  hideAndSaveSet(e) {
    this.setState({ hideDialogSettings: true })
    this.vm.$dispatch({ SaveSettings: { UmTemperature: e.UmTemperature, Language: e.Language, Theme: e.Theme } })
  }

  hideSet() {
    this.setState({ hideDialogSettings: true })
  }

  renderDialogSetting() {
    if (!this.state.hideDialogSettings)
      return (
        <DialogSet
          HideSet={this.hideSet.bind(this)}
          HideAndSaveSet={this.hideAndSaveSet.bind(this)}
          AppSettings={this.state.AppSettings}
          Languages={this.languages}
          UmTemperatures={this.umTemperatures}
          Themes={this.themes}
        />
      )
  }

  openDialogAbout() {
    this.setState({ SidebarMenuMobileVisible: 0 });
    this.setState({ hideDialogAbout: false })
  }

  hideAbout() {
    this.setState({ hideDialogAbout: true })
  }

  renderDialogAbout() {
    if (!this.state.hideDialogAbout)
      return (
        <DialogAbout HideAbout={this.hideAbout.bind(this)}
          InstallationInfo1={this.state.InstallationInfo1}
          InstallationInfo2={this.state.InstallationInfo2}
        />
      );
  }

  renderSidebarMenuMobileOpen() {
    if (this.state.SidebarMenuMobileVisible === 1)
      return (
        <SidebarMenuMobile
          SidebarMenuMobileVisible={this.state.SidebarMenuMobileVisible}
          MenuHeader={this.state.MenuHeader}
          MenuMobileSelect={this.menuMobileSelect.bind(this)}
          InstallationInfo1={this.state.InstallationInfo1}
          InstallationInfo2={this.state.InstallationInfo2}
        />
      )
  }

  hideUsb() {
    this.setState({ hideUSBPage: true })
  }

  UsbPage() {
    if (!this.state.hideUSBPage)
      return (
        <USBPage
          HideUsb={this.hideUsb.bind(this)}
        />
      )
  }

  openUSB() {
    this.setState({ hideUSBPage: false })
  }

  hideSetupProfile() {
    this.setState({ hideSetupProfile: true })
  }

  openSetupProfile() {
    this.setState({ hideSetupProfile: false })
  }

  hideAndSaveProfile(e) {
    this.setState({ hideSetupProfile: true });
    this.vm?.$dispatch({ SaveUser: e });
  }

  renderSetupProfile() {
    if (!this.state.hideSetupProfile)
      return (
        <DialogSetupProfile
          HideSetupProfile={this.hideSetupProfile.bind(this)}
          HideAndSaveProfile={this.hideAndSaveProfile.bind(this)}
          UserDetail={this.state.UserDetail}
        />
      )
  }

  render() {
    if (this.state.AppSettings === undefined)
      return null;
    else
      return (
        <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
          <div id="divApp" className={this.state.AppSettings.Theme}>
            <Header
              vm={this.vm}
              MobileMenuEnabled={this.state.MobileMenuEnabled}
              MenuHeader={this.state.MenuHeader}
              MenuSetupHeader={this.state.MenuSetupHeader}
              MenuMobileOpen={this.state.MenuMobileOpen}
              MenuEventsHeader={this.state.MenuEventsHeader}
              SidebarMenuMobileVisible={this.state.SidebarMenuMobileVisible}
              OpenMenuMobile={this.openMenuMobile.bind(this)}
              OpenDialogSettings={this.openDialogSettings.bind(this)}
              OpenDialogAbout={this.openDialogAbout.bind(this)}
              OpenSetupProfile={this.openSetupProfile.bind(this)}
              OpenUSB={this.openUSB.bind(this)}
              UserName={this.state?.UserDetail?.UserName}
              UserImage={this.state?.UserDetail?.UserImage}
              InstallationInfo1={this.state.InstallationInfo1}
              InstallationInfo2={this.state.InstallationInfo2}
            />
            <div>
              {this.renderDialogSetting()}
              {this.renderDialogAbout()}
              {this.UsbPage()}
              {this.renderSetupProfile()}
            </div>

            <div id="divContent">
              {this.renderSidebarMenuMobileOpen()}

              <div id="Content" />
            </div>
            <Footer />
          </div>
        </IntlProvider>
      );
  }
}

export default AppLayout;