import React from 'react';
import dotnetify from 'dotnetify';
import BasePage from '../components/BasePage';
import { DashboardLayoutComponent, PanelsDirective, PanelDirective } from '@syncfusion/ej2-react-layouts';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import '../styles/dashBoard.css';
import ImageModel from '../components/model/ImageModel';
import { statusTemplate } from '../components/templates/StatusTemplate';

import { setSpinner } from '@syncfusion/ej2-popups';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import CommonDataManager from '../components/CommonDataManager'

import { IntlProvider, createIntl, createIntlCache } from 'react-intl';
import isEqual from 'react-fast-compare';
import ProgressBar from '../components/ProgressBar';

class DashBoard extends React.Component {

    constructor(props) {
        super(props);

        setSpinner({ template: '<div style="width:100%;height:100%" className="custom-rolling"><div></div></div>' });

        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(this.updateLanguage.bind(this));
        this.commonData.subscribeTheme(this.updateTheme.bind(this));

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.state = {
            Theme: this.commonData.getTheme(),
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            ConnectedDevices: null,
            InAlarmDevices: null,
            InCycleDevices: null,
            TotalDevices: null,
            PerModelDevicesConnected: null,
            LastAlarms: null,
            NextStartsDevices: null
        };

        let initialState = { Language: this.state.Language };
        this.vm = dotnetify.react.connect('DashBoard', this, { vmArg: initialState });
    }

    shouldComponentUpdate(_nextProps, nextState) {
        let bShouldUpdate = true;

        if (nextState.Theme !== this.commonData.getTheme()) return true;
        if (isEqual(nextState, this.state)) return false;

        return bShouldUpdate;
    }

    updateLanguage() {
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: this.commonData.getLanguage(), Messages: this.commonData.getMessages() });
    }

    updateTheme() {
        this.setState({ Theme: this.commonData.getTheme() });
    }

    renderPanels() {
        if (window.innerWidth < this.commonData.getMobileWidth()) {

            return (
                <PanelsDirective>
                    <PanelDirective sizeX={16} sizeY={4} row={0} col={0} content={this.cardTotalDevices.bind(this)} />
                    <PanelDirective sizeX={16} sizeY={4} row={4} col={0} content={this.cardConnectedDevices.bind(this)} />
                    <PanelDirective sizeX={16} sizeY={4} row={8} col={0} content={this.cardInAlarmDevices.bind(this)} />
                    <PanelDirective sizeX={16} sizeY={4} row={12} col={0} content={this.cardInCycleDevices.bind(this)} />
                    <PanelDirective sizeX={16} sizeY={8} row={35} col={0} content={this.cardPerModelDevicesConnected.bind(this)} />
                    <PanelDirective sizeX={16} sizeY={8} row={28} col={0} content={this.cardLastAlarms.bind(this)} />
                    <PanelDirective sizeX={16} sizeY={8} row={20} col={0} content={this.cardNextStartsDevices.bind(this)} />
                </PanelsDirective>
            )
        }
        else {
            return (
                <PanelsDirective>
                    <PanelDirective sizeX={2} sizeY={2} row={0} col={0} content={this.cardTotalDevices.bind(this)} />
                    <PanelDirective sizeX={2} sizeY={2} row={0} col={2} content={this.cardConnectedDevices.bind(this)} />
                    <PanelDirective sizeX={2} sizeY={2} row={0} col={4} content={this.cardInAlarmDevices.bind(this)} />
                    <PanelDirective sizeX={2} sizeY={2} row={0} col={6} content={this.cardInCycleDevices.bind(this)} />
                    <PanelDirective sizeX={8} sizeY={4} row={2} col={2} content={this.cardPerModelDevicesConnected.bind(this)} />
                    <PanelDirective sizeX={8} sizeY={3} row={2} col={8} content={this.cardLastAlarms.bind(this)} />
                    <PanelDirective sizeX={8} sizeY={3} row={2} col={8} content={this.cardNextStartsDevices.bind(this)} />
                </PanelsDirective>
            )
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState?.Update !== this.state?.Update)
            for (let i = 0; i < this.dashBoardInstance?.panels?.length; i++)
                this.dashBoardInstance.updatePanel(i);


        this.Alarms = this.commonData.getAlarms();
    }

    resizeEvent() {
        clearTimeout(this.updateTimeout);
        setTimeout(() => this.setState({ Update: 0 + (Math.random() * (1000 - 0)) }), 2000);
    }

    timer() {
        if (this.lastAlarmsGrid !== undefined)
            if (this.lastAlarmsGrid !== null)
                this.lastAlarmsGrid.dataSource = this.state.LastAlarms;
        if (this.nextStartDevicesGrid !== undefined)
            if (this.nextStartDevicesGrid !== null)
                this.nextStartDevicesGrid.dataSource = this.state.NextStartsDevices;
    }

    componentDidMount() {
        if (!this.intervalId) this.intervalId = setInterval(this.timer.bind(this), 2000);
        window.addEventListener('resize', this.resizeEvent.bind(this));
        this.commonData.setCurrentView('DashBoard');
    }


    componentWillUnmount() {
        if (this.vm != undefined)
            this.vm.$destroy();

        if (this.commonData != undefined)
            this.commonData.unsubscribe(this.updateLanguage);

        if (this.intervalId) clearInterval(this.intervalId);
        window.removeEventListener('resize', this.resizeEvent);
    }

    cardTotalDevices() {
        let title = this.intl.formatMessage({ id: 'dashboard.total_trolleys' });
        let value = this.state.TotalDevices;
        let containerVisible = value === 0 ? 'False' : 'True';

        if (value === null) return (<ProgressBar Option='dashBoard' Id='TotalDevices' Message={this.intl.formatMessage({ id: 'waiting' })} />);

        return (
            <div className='row rowCard'>
                <div className={'e-dashboardlayoute-control e-panel e-panel-container' + containerVisible} onClick={() => value !== 0 ? this.vm.$routeTo(this.state?.LinkTotalDevices) : null}>
                    <div className='card-content text col-xs-9 col-md-12'>
                        <span >{title}</span>
                    </div>
                    <div className='card-content number col-xs-3 col-md-12' >
                        {value}
                    </div>
                </div>
            </div>
        );
    }

    cardConnectedDevices() {
        let title = this.intl.formatMessage({ id: 'dashboard.connected_trolleys' });
        let value = this.state.ConnectedDevices;
        let containerVisible = value === 0 ? 'False' : 'True';

        if (value === null) return (<ProgressBar Option='dashBoard' Id='ConnectedDevices' Message={this.intl.formatMessage({ id: 'waiting' })} />);

        return (
            <div className='row rowCard'>
                <div className={'e-dashboardlayoute-control e-panel e-panel-container' + containerVisible} onClick={() => value !== 0 ? this.vm.$routeTo(this.state?.LinkConnectedDevices) : null}>
                    <div className='card-content text col-xs-9 col-md-12' >
                        <span >{title}</span>
                    </div>
                    <div className='card-content number col-xs-3 col-md-12'>
                        <span>{value}</span>
                    </div>
                </div>
            </div>
        );
    }

    cardInAlarmDevices() {
        let title = this.intl.formatMessage({ id: 'dashboard.in_alarm_trolleys' });
        let value = this.state.InAlarmDevices;
        let containerVisible = value === 0 ? 'False' : 'True';

        if (value === null) return (<ProgressBar Option='dashBoard' Id='InAlarmDevices' Message={this.intl.formatMessage({ id: 'waiting' })} />);
        return (
            <div className='row rowCard'>
                <div className={'e-dashboardlayoute-control e-panel e-panel-container' + containerVisible} onClick={() => value !== 0 ? this.vm.$routeTo(this.state?.LinkTInAlarmDevices) : null}>
                    <div className='card-content text col-xs-9 col-md-12'>
                        <span >{title}</span>
                    </div>
                    <div className='card-content number col-xs-3 col-md-12'>
                        <span >{value}</span>
                    </div>
                </div>
            </div>
        );
    }

    cardInCycleDevices() {
        let title = this.intl.formatMessage({ id: 'dashboard.in_cycle_trolleys' });
        let value = this.state.InCycleDevices;
        let containerVisible = value === 0 ? 'False' : 'True';

        if (value === null) return (<ProgressBar Option='dashBoard' Id='InCycleDevices' Message={this.intl.formatMessage({ id: 'waiting' })} />);

        return (
            <div className='row rowCard'>
                <div className={'e-dashboardlayoute-control e-panel e-panel-container' + containerVisible} onClick={() => value !== 0 ? this.vm.$routeTo(this.state?.LinkInCycleDevices) : null}>
                    <div className='card-content text col-xs-9 col-md-12'>
                        <span>{title}</span>
                    </div>
                    <div className='card-content number col-xs-3 col-md-12'>
                        <span>{value}</span>
                    </div>
                </div>
            </div>
        );
    }

    cardPerModelDevicesConnected() {
        let title = this.intl.formatMessage({ id: 'dashboard.connected_models' });

        if (this.state.PerModelDevicesConnected === null) return (<ProgressBar Option='dashBoard' Id='PerModelDevicesConnected' Message={this.intl.formatMessage({ id: 'waiting' })} />);

        const l = this.state.PerModelDevicesConnected.map(d =>
            <div key={d.CodModel} className='col-xs-3'>
                <div>
                    <ImageModel theme={this.state.Theme} model={d.CodModel} version={d.DeviceVersion} Dashboard={true} />
                </div>
                <div className='row'>
                    <div className='col-xs-8'>
                        <span className='card-content text-model'>{d.Model}</span>
                    </div>
                    <div className='col-xs-4'>
                        <span className='card-content number-model'>{d.Count}</span>
                    </div>
                </div>
            </div>);

        return (
            <div>
                <div className='cardTitle'>
                    {title}
                </div>
                <div className='rowCardPerModelDevicesConnected card'>
                    {l}
                </div>
            </div>
        );
    }

    cardLastAlarms() {
        let title = this.intl.formatMessage({ id: 'dashboard.last_alarms' });
        let containerVisible = this.state?.LastAlarms?.length === 0 ? 'False' : 'True';

        if (this.state.LastAlarms === null) return (<ProgressBar Option='dashBoard' Id='LastAlarms' Message={this.intl.formatMessage({ id: 'waiting' })} />);

        return (
            <div className={'e-dashboardlayoute-control e-panel e-panel-container' + containerVisible} onClick={() => this.vm.$routeTo(this.state?.LinkHistoryAlarms)}>
                <div className='cardTitle'>
                    {title}
                </div>
                <GridComponent id='lastAlarmsGrid' className='comm-grid' dataSource={this.state.LastAlarms} ref={grid => this.lastAlarmsGrid = grid}
                    enableHover={false} enableToggle={false} allowFiltering={false} allowSorting={false} allowSelection={false}>
                    <ColumnsDirective>
                        <ColumnDirective field='DateTime' headerText={this.intl.formatMessage({ id: 'date' })} width='20%' type='datetime' format={{ format: 'dd/MM/yyyy HH:mm', type: 'datatime' }}></ColumnDirective>
                        <ColumnDirective field='AlarmCode' headerText={this.intl.formatMessage({ id: 'alarm' })} width='20%'></ColumnDirective>
                        <ColumnDirective field='DeviceID' headerText={this.intl.formatMessage({ id: 'id_trolley' })} width='20%'></ColumnDirective>
                        <ColumnDirective field='Site' headerText={this.intl.formatMessage({ id: 'site' })} width='20%'></ColumnDirective>
                        <ColumnDirective field='Department' headerText={this.intl.formatMessage({ id: 'department' })} width='20%'></ColumnDirective>
                    </ColumnsDirective>
                </GridComponent>
            </div>
        );
    }

    cardNextStartsDevices() {
        let title = this.intl.formatMessage({ id: 'dashboard.next_automatic_starts' });

        if (this.state.NextStartsDevices === null) return (<div><ProgressBar Option='dashBoard' Id='NextStartsDevices' Message={this.intl.formatMessage({ id: 'waiting' })} /></div>);


        return (
            <div>
                <div className='cardTitle'>
                    {title}
                </div>
                <GridComponent id='nextStartDevicesGrid' className='comm-grid' dataSource={this.state.NextStartsDevices} ref={grid => this.nextStartDevicesGrid = grid}
                    enableHover={false} enableToggle={false} allowFiltering={false} allowSorting={false} allowSelection={false}>
                    <ColumnsDirective>
                        <ColumnDirective field='Id' isPrimaryKey={true} visible={false}></ColumnDirective>
                        <ColumnDirective field='NextStart' headerText={this.intl.formatMessage({ id: 'start_time' })} width='20%' type='datetime' format={{ format: 'HH:mm', type: 'datatime' }}></ColumnDirective>
                        <ColumnDirective field='Status' headerText={this.intl.formatMessage({ id: 'status' })} width='20%' alarms={this.Alarms} template={statusTemplate.bind(this)}></ColumnDirective>
                        <ColumnDirective field='DeviceID' headerText={this.intl.formatMessage({ id: 'id_trolley' })} width='20%'></ColumnDirective>
                        <ColumnDirective field='Site' headerText={this.intl.formatMessage({ id: 'site' })} width='20%'></ColumnDirective>
                        <ColumnDirective field='Department' headerText={this.intl.formatMessage({ id: 'department' })} width='20%'></ColumnDirective>
                    </ColumnsDirective>
                </GridComponent>
            </div>
        );
    }


    render() {
        let col = 16;
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div>
                    <BasePage title={this.intl.formatMessage({ id: 'menuheader.dashboard' })} navigation={this.intl.formatMessage({ id: 'menuheader.dashboard' })} />
                    <div id='divDashboard'>
                        <DashboardLayoutComponent id='dashboard'
                            ref={dashBoard => this.dashBoardInstance = dashBoard}
                            mediaQuery='max-width:0px'
                            columns={col}
                            allowDragging={false}
                            allowFloating={true}>
                            {this.renderPanels()}
                        </DashboardLayoutComponent>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

DashBoard.propTypes = {
}
export default DashBoard;