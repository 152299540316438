import * as React from 'react';
import PropTypes from 'prop-types';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/dialogSet.css';
import { injectIntl, FormattedMessage } from 'react-intl';

class DialogSet extends React.Component {

  constructor() {
    super(...arguments);
    this.fields = { text: 'text', value: 'id' };

  }

  dialogSetCancel() {
    this.props.HideSet();
  }

  dialogSetSave() {
    if (this.comboLanguage.itemData === undefined) { alert('Language is not defined *') }
    else {
      let settings = { Language: this.comboLanguage.itemData.id, UmTemperature: this.comboTemperature.itemData.id, Theme: this.comboTheme.itemData.id };
      this.props.HideAndSaveSet(settings);
    }
  }

  flagTemplate(props) {
    var flag = '/images/flags/' + props.id + '.png';

    return (
      <div className='row'>
        <div className='col-xs-8'>
          <span className='textLanguage' >{props.text}</span>
        </div>
        <div className='col-xs-4'>
          <img className='imagesFlags' src={flag} />
        </div>
      </div>
    )
  }

  renderSettings() {
    let tmpLang =  this.props.Languages;
    for(let i = 0; i < tmpLang.length; i++)
    tmpLang[i].text = this.props.intl.formatMessage({id:tmpLang[i].text});

    let themes = this.props.Themes;
    for(let i = 0; i < themes.length; i ++)
    themes[i].text = this.props.intl.formatMessage({id:themes[i].text})

    return (
      <div id='renderSetting'>
        <div>
          <span className='titleDialog'><FormattedMessage id='menuheader.setup' /></span>
        </div>
        <div className='row language'>
          <div className='col-xs-3' id='lblDescription'>
            <span className='lblDescription'><FormattedMessage id='language' /></span>
          </div>
          <div className='col-xs-8'>
            <DropDownListComponent id='language'
              ref={combo => this.comboLanguage = combo}
              fields={this.fields}
              dataSource={tmpLang}
              value={this.props.AppSettings.Language}
              itemTemplate={this.itemTemplate = this.flagTemplate.bind(this)}
              placeholder="Select language" />
          </div>
        </div>

        <div className='row temperature'>
          <div className='col-xs-3' id='lblDescription'>
            <span className='lblDescription' ><FormattedMessage id='temperature' /></span>
          </div>
          <div className='col-xs-8'>
            <DropDownListComponent id='temperatura'
              ref={combo => this.comboTemperature = combo}
              fields={this.fields}
              dataSource={this.props.UmTemperatures} popupHeight="100px" popupWidth="150px"
              value={this.props.AppSettings.UmTemperature}
              placeholder="Select temperature" />
          </div>
        </div>

        <div className='row theme'>
          <div className='col-xs-3' id='lblDescription'>
            <span className='lblDescription'><FormattedMessage id='themes' /></span>
          </div>
          <div className='col-xs-8'>
            <DropDownListComponent id='theme'
              ref={combo => this.comboTheme = combo}
              fields={this.fields}
              dataSource={themes} popupHeight="100px" popupWidth="150px"
              value={this.props.AppSettings.Theme}
              placeholder="Select theme" />
          </div>
        </div>

        <div className='row divBtnSet'>
          <div className='col-xs-6' >
            <ButtonComponent id="btnSet" onClick={this.dialogSetCancel.bind(this)}><FormattedMessage id='cancel' /></ButtonComponent>
          </div>
          <div className='col-xs-6' >
            <ButtonComponent id="btnSet" onClick={this.dialogSetSave.bind(this)}><FormattedMessage id='save' /></ButtonComponent>
          </div>
        </div>
      </div>

    )
  }

  render() {
    return (
      <div className='divBlockDialog'>
        <div className="dialog">
          {this.renderSettings()}
        </div>
        <div className='e-dlg-overlay' />
      </div>
    );
  }
}


DialogSet.propTypes = {
  HideSet: PropTypes.func,
  HideAndSaveSet: PropTypes.func,
  Languages: PropTypes.array,
  UmTemperatures: PropTypes.array,
  intl: PropTypes.object,
  Themes: PropTypes.array,
  AppSettings: PropTypes.object
};

export default injectIntl(DialogSet);