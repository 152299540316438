// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divDeviceDataBottom {\n    padding-top: 20px;\n    padding-left: 20px;\n    padding-right: 20px;\n    text-align: left;\n    font-size: 12px;\n    width: 100%;\n    height: 40px;\n}\n\n.e-switch-wrapper {\n    height: 25px;\n    width: 90px;\n}\n\n.e-switch-wrapper.e-switch-handle {\n    width: 20px;\n    height: 16px;\n}\n\n.e-switch-wrapper .e-switch-inner, .e-switch-wrapper .e-switch-handle {\n    border-radius: 0;\n}\n\n.rowDeviceDataConfiguration {\n    padding-top: 10px;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/styles/manage/deviceDataConfiguration.css"],"names":[],"mappings":"AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf","sourcesContent":["@import \"../app.css\";\n\n#divDeviceDataBottom {\n    padding-top: 20px;\n    padding-left: 20px;\n    padding-right: 20px;\n    text-align: left;\n    font-size: 12px;\n    width: 100%;\n    height: 40px;\n}\n\n.e-switch-wrapper {\n    height: 25px;\n    width: 90px;\n}\n\n.e-switch-wrapper.e-switch-handle {\n    width: 20px;\n    height: 16px;\n}\n\n.e-switch-wrapper .e-switch-inner, .e-switch-wrapper .e-switch-handle {\n    border-radius: 0;\n}\n\n.rowDeviceDataConfiguration {\n    padding-top: 10px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
