// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divSidebarUSB{\n    position: absolute;\n    z-index: 1000;\n    right: 0px;\n    top: 0px;\n    height: 100%;\n    background-color: white;\n    box-shadow: -5px 0px #838383cb;\n    -webkit-box-shadow: -2px 0px 5px 0px #838383cb;\n    -moz-box-shadow: -2px 0px 5px 0px #838383cb;\n}\n\n#renderSidebarUSB{\n    height: 100%;\n}\n\n#divBlockSidebarUSB{\n    background: #9292928c;\n    z-index: 1001;\n    position:absolute;\n    top: 0px;\n    left: 0;\n    height:100%;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/styles/sidebarHistoryUSB.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,uBAAuB;IACvB,8BAA8B;IAC9B,8CAA8C;IAC9C,2CAA2C;AAC/C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,iBAAiB;IACjB,QAAQ;IACR,OAAO;IACP,WAAW;IACX,WAAW;AACf","sourcesContent":["@import \"app.css\";\n\n#divSidebarUSB{\n    position: absolute;\n    z-index: 1000;\n    right: 0px;\n    top: 0px;\n    height: 100%;\n    background-color: white;\n    box-shadow: -5px 0px #838383cb;\n    -webkit-box-shadow: -2px 0px 5px 0px #838383cb;\n    -moz-box-shadow: -2px 0px 5px 0px #838383cb;\n}\n\n#renderSidebarUSB{\n    height: 100%;\n}\n\n#divBlockSidebarUSB{\n    background: #9292928c;\n    z-index: 1001;\n    position:absolute;\n    top: 0px;\n    left: 0;\n    height:100%;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
