import React from 'react';
import PropTypes from 'prop-types';
import '../styles/base.css';

const BasePage = props => {
  const { title, navigation } = props;

  return (
    <div id="divBase" className="row">
      <div className="col-xs-3" align="left">
      <h3 id="title">{title}</h3>
      </div>
      <div className="col-xs-9" align="right">
        <span className="lblInfo">COMMUNICATOR PLUS  &gt;  </span><span  className="lblInfo">{navigation}</span>
      </div>
    </div>
  );
};

BasePage.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.string
};

export default BasePage;
