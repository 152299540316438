import * as React from 'react';
import dotnetify from 'dotnetify';
import '../styles/setupDepartmentsPage.css';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import BasePage from '../components/BasePage';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import SidebarSetupDepartments from '../components/SidebarSetupDepartments';

import { L10n, setCulture } from '@syncfusion/ej2-base';
import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';

class SetupDepartmentsPage extends React.Component {


    constructor(props) {
        super(props);


        this.vm = dotnetify.react.connect('SetupDepartments', this);

        let updateLanguage = this.updateLanguage.bind(this);
        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(updateLanguage);

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            Departments: [],
            SelectedDepartment: [],
            SidebarSetupDepartmentsVisible: 0,
            IsNewDepartment: false
        };
    }

    updateLanguage() {
        let commonData = CommonDataManager.getInstance();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: commonData.getLanguage(), Messages: commonData.getMessages() });
    }

    componentDidMount() {
        this.commonData?.setCurrentView('SetupDepartmentsPage');
    }

    componentWillUnmount() {
        this.vm?.$destroy();
        this.commonData?.unsubscribe(this.updateLanguage);
    }


    clearSelection() {
        var cloneDepartments = JSON.parse(JSON.stringify(this.state.Departments));

        for (let i = 0; i < cloneDepartments.length; i++)
            cloneDepartments[i].Selected = false;

        this.setState({ Departments: cloneDepartments, SidebarSetupDepartmentsVisible: 0, SelectedDepartment: [], IsNewDepartment: false });
    }

    changeDepartment(direction) {

        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state?.SelectedDepartment?.Id);

        if (direction === -1)
            selectedRowIndex--;

        if (direction === 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) return;

        if (selectedRowIndex > pageRows.length - 1) return;

        let newId = pageRows[selectedRowIndex].Id;

        let cloneDepartments = JSON.parse(JSON.stringify(this.state.Departments));

        for (let i = 0; i < cloneDepartments.length; i++)
            cloneDepartments[i].Selected = cloneDepartments[i].Id === newId;

        this.setState({ Departments: cloneDepartments, SelectedDepartment: cloneDepartments.filter(d => d.Selected)[0] });
    }

    refreshDepartments() {
        this.vm.$dispatch({ RefreshDepartments: '' });
    }

    newDepartment() {
        this.setState({ SidebarSetupDepartmentsVisible: 1, IsNewDepartment: true });
    }

    rowDepartmentSelected() {
        let selected;
        let id = -1;

        if (this.gridInstance.getSelectedRecords().length === 1) {
            id = this.gridInstance.getSelectedRecords()[0].Id;
            selected = this.gridInstance.getSelectedRecords()[0];
        }

        if (selected === undefined)
            id = -1;
        else
            if (selected.Selected)
                id = -1;

        let cloneDepartments = JSON.parse(JSON.stringify(this.state.Departments));

        for (let i = 0; i < cloneDepartments.length; i++) {
            cloneDepartments[i].Selected = (cloneDepartments[i].Id === id);
        }

        this.setState({ Departments: cloneDepartments, SidebarSetupDepartmentsVisible: (id !== -1) ? 1 : 0, SelectedDepartment: selected, NewDepartment: false });
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divSetup'>
                    <BasePage title={this.intl.formatMessage({ id: 'menusetupheader.departments' })} navigation={this.intl.formatMessage({ id: 'menusetupheader.departments' })}></BasePage>
                    <div id='divToolbarSetupDepartments' className='row'>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                            <ButtonComponent id='btnNewDepartment' className='Button' onClick={() => this.newDepartment()}><FormattedMessage id='setup_departments.new_department' /></ButtonComponent>
                        </div>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                        </div>
                    </div>
                    <div id='divSetupDepartmentsGrid'>
                        <GridComponent id='setupDepartmentsGrid' ref={grid => this.gridInstance = grid}
                            dataSource={this.state.Departments} enableHover={true}
                            allowFiltering={true} enableToggle={true}
                            allowExcelExport={true}
                            filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                            allowSorting={true} allowSelection={true}
                            rowSelected={this.rowDepartmentSelected.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field='Selected' headerText='' width='50px' displayAsCheckBox={true} allowFiltering={false}></ColumnDirective>
                                <ColumnDirective field='Id' headerText='ID' isPrimaryKey={true} visible={false}></ColumnDirective>
                                <ColumnDirective field='Name' headerText={this.intl.formatMessage({ id: 'name' })}></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Filter, Sort]} />
                        </GridComponent>
                        <SidebarSetupDepartments
                            SidebarSetupDepartmentsVisible={this.state.SidebarSetupDepartmentsVisible}
                            SelectedDepartment={this.state.SelectedDepartment}
                            IsNewDepartment={this.state.IsNewDepartment}
                            ClearSelection={this.clearSelection.bind(this)}
                            RefreshDepartments={this.refreshDepartments.bind(this)}
                            ChangeDepartment={this.changeDepartment.bind(this)}
                        />
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

SetupDepartmentsPage.propTypes = {
};

export default SetupDepartmentsPage;