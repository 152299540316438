import React from 'react';
import PropTypes from 'prop-types';
import { getValue } from '@syncfusion/ej2-base';

import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Inject, Page, CommandColumn } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import ProgressBar from '../../components/ProgressBar';
import '../../styles/manage/startsConfiguration.css'
import CommonDataManager from '../CommonDataManager'
import isEqual from 'react-fast-compare';

import { injectIntl, FormattedMessage } from 'react-intl';

class StartsConfiguration extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            Detail: props.IsConfigurationMenu ? props.Detail : null,
            EnableAddStart: true,
        };

        this.commonData = CommonDataManager.getInstance();
    }

    menuCloseClick() {
        this.gridStartsInstance?.endEdit();
        this.props.MenuCloseClick();
    }

    menuSaveClick() {
        this.gridStartsInstance?.endEdit();
        this.props.SetStarts(this.state.Detail.Starts);
        this.props.MenuCloseClick();
    }

    shouldComponentUpdate(nextProps, nextState) {
        let bShouldUpdate = true;

        if (nextProps?.Selected?.Id === this.state?.Detail?.Id) bShouldUpdate = false;
        if (!isEqual(nextState?.Detail?.Starts, this.state?.Detail?.Starts)) bShouldUpdate = true;
        if (nextState.EnableAddStart !== this.state.EnableAddStart) return true;

        return bShouldUpdate;
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props?.Selected !== undefined) && (this.props?.Selected?.Id !== this.props?.Detail?.Id)) {
            if (this.state.Detail !== null)
                this.setState({ Detail: null });
            return;
        }
        else {
            let bSetDetail = (this.state.Detail === null);

            if (!bSetDetail)
                bSetDetail = (this.props.Detail.Id !== this.state.Detail.Id);

            if (bSetDetail) {
                this.setState({ Detail: this.props.Detail });
                return;
            }
        }

        if (prevState?.Detail?.Starts?.length !== this.state?.Detail?.Starts?.length)
            if (prevState.EnableAddStart === this.state.EnableAddStart)
                this.setState({ EnableAddStart: (this.state?.Detail?.Starts?.length < this.props?.Detail?.MaxStarts) });
    }

    getDays() {
        return this.getDays(false);
    }

    getDays(abbreviated) {
        let days = [];

        days.push({ Value: 0, Value_v3: 0, Description: this.props.intl.formatMessage({ id: 'all_days' }) });

        let translatedDays = this.commonData?.getDayNames();

        if (abbreviated)
            translatedDays = this.commonData?.getAbbreviatedDayNames();

        if (translatedDays !== undefined)
            for (let i = 0; i < Object.values(translatedDays).length; i++)
                days.push({ Value: i + 1, Value_v3: 1 << i, Description: Object.values(translatedDays)[i] });

        return days;
    }

    valueTemplateCycle(props) {
        if (props === undefined)
            return (<div></div>);

        let c = getValue('Cycle', props)

        if ((c === -1) || (c === 0))
            c = '';
        else
            c = 'N.' + c;

        return (<div> {c}</div >);
    }

    editTemplateCycle(props) {
        return (
            <div>
                <span>{props.column.headerText}</span>
                <DropDownListComponent id='Cycle' value={getValue('Cycle', props)} dataSource={this.state?.Detail?.Cycles}
                    fields={{ text: 'Name', value: 'ID' }} />
            </div>
        );
    }


    valueTemplateDay(props) {
        let d;

        if (props === undefined)
            return (<div></div>);

        if (this.state?.Detail?.DeviceVersion !== 'v3') {
            d = this.getDays()?.find(v => v.Value === getValue('Day', props))['Description'];
            return (<span>{d}</span>);
        } else {

            let maxDaysView = 3;
            let daysValue = getValue('Day', props);

            let daysNames = this.getDays(true);

            let s = '';
            let count = 0;

            if (daysValue === 127) {
                s = daysNames[0].Description;
            }
            else {
                for (let i = 1; i < daysNames.length; i++) {
                    if (daysNames[i].Value_v3 === (daysValue & (1 << (i - 1)))) {
                        if (count <= maxDaysView)
                            s = s + daysNames[i].Description + ',';

                        count++;
                    }
                }
            }

            if (s.endsWith(','))
                s = s.substring(0, s.length - 1);

            if (count - maxDaysView - 1 > 0)
                s = s + '+' + (count - maxDaysView - 1);

            return (<div>{s}</div>);
        }
    }

    editTemplateDay(props) {
        if (this.state.Detail.DeviceVersion !== 'v3')
            return (
                <div>
                    <span>{props.column.headerText}</span>
                    <DropDownListComponent id='Day'
                        value={getValue('Day', props)}
                        dataSource={this.getDays()}
                        ref={dd => this.ddDay = dd}
                        fields={{ text: 'Description', value: 'Value' }} />
                </div>
            );
        else {

            let values = [];
            let days = this.getDays();
            let dayValue_v3 = getValue('Day', props);

            for (let i = 1; i < days.length; i++)
                if ((dayValue_v3 & days[i].Value_v3) === days[i].Value_v3)
                    values.push(days[i].Value_v3);

            return (
                <div>
                    <span>{props.column.headerText}</span>
                    <MultiSelectComponent id='mtselement'
                        dataSource={this.getDays().filter(d => d.Value_v3 !== 0)}
                        mode='CheckBox'
                        value={values}
                        headerText='Day'
                        showSelectAll={true}
                        selectAllText={this.props.intl.formatMessage({ id: 'all_days' })}
                        unSelectAllText={this.props.intl.formatMessage({ id: 'all_days' })}
                        fields={{ text: 'Description', value: 'Value_v3' }}
                        ref={ms => this.msDay = ms}
                        popupWidth='150px'>
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                </div>
            )
        }
    }

    renderAddStart() {
        if (this.state?.Detail?.DeviceVersion !== 'v3')
            return null;
        return (
            <ButtonComponent className='ButtonEmpty' iconCss='e-icons e-add-new' disabled={!this.state.EnableAddStart} onClick={() => this.addStart()} />
        );
    }


    addStart() {
        let starts = JSON.parse(JSON.stringify(this.state?.Detail?.Starts));
        let empty = JSON.parse(JSON.stringify(this.state?.Detail?.EmptyStart));

        let newStartId = starts.length + 1;

        empty.ID = newStartId;
        empty.IDStart = newStartId;

        starts.push(empty);

        starts.sort(function (a, b) {
            return parseInt(a.IDStart) - parseInt(b.IDStart)
        });

        this.setState({ Detail: { ...this.state.Detail, Starts: starts } });
    }


    renderDeleteStartColumn() {
        if (this.state?.Detail?.DeviceVersion !== 'v3')
            return null;

        return (
            <ColumnDirective field='' headerText=' ' commands={[{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]}></ColumnDirective >
        )
    }

    deleteStart(args) {
        args.cancel = true;

        let starts = JSON.parse(JSON.stringify(this.state?.Detail?.Starts));

        starts = starts.filter(s => s.ID !== args.rowData.ID);

        for (let i = 0; i < starts.length; i++) {
            starts[i].ID = i + 1;
            starts[i].IDStart = i + 1;
        }

        starts.sort(function (a, b) {
            return parseInt(a.IDStart) - parseInt(b.IDStart)
        });

        this.setState({ Detail: { ...this.state.Detail, Starts: starts } });
    }

    actionBeginStarts(props) {
        if (props.requestType === 'save') {

            if (this.state?.Detail?.DeviceVersion !== 'v3') {
                props.data.Day = this.ddDay?.value;

            }
            else {
                props.data.Day = 0;
                for (let i = 0; i < this.msDay?.value?.length; i++)
                    props.data.Day = props.data.Day + this.msDay?.value[i];
            }
        }
    }

    actionComplete(args) {
        if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
            args.dialog.header = this.props.intl?.formatMessage({ id: 'start' }) + ' ' + getValue('IDStart', args.rowData);
            if (this.state?.Detail?.DeviceVersion === 'v3') { args.dialog.width = 500; }
        }
    }

    renderStartsConfigurationGrid() {
        if (this.state.Detail === null)

            return (
                <ProgressBar Id='StartsConfiguration' Option={this.props.Option} Message={this.props.intl?.formatMessage({ id: 'waiting' })} />
            )
        else {
            return (
                <div id='divStartsBottom'>

                    <div className='row'>
                        <div className='col-xs-12 btnCycleV3'>
                            {this.renderAddStart()}
                        </div>
                    </div>

                    <GridComponent id='startsConfigurationGrid' ref={grid => this.gridStartsInstance = grid}
                        dataSource={this.state?.Detail?.Starts}
                        editSettings={{ allowEditing: true, allowAdding: this.state?.Detail?.DeviceVersion === 'v3', allowDeleting: this.state?.Detail?.DeviceVersion === 'v3', mode: 'Dialog' }}
                        allowPaging={true}
                        pageSettings={{ pageSize: 10 }}
                        allowSorting={false}
                        allowSelection={false}
                        className='comm-grid'
                        commandClick={this.deleteStart.bind(this)} actionComplete={this.actionComplete.bind(this)}
                        actionBegin={this.actionBeginStarts.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field='IDStart' headerText={this.props.intl.formatMessage({ id: 'id' })} allowEditing={false} isPrimaryKey={true}></ColumnDirective>
                            <ColumnDirective field='Hour' headerText={this.props.intl.formatMessage({ id: 'hour' })} editType='numericedit' format='N' edit={{ params: { min: 0, max: 23, decimals: 0, format: 'N' } }}></ColumnDirective>
                            <ColumnDirective field='Minutes' headerText={this.props.intl.formatMessage({ id: 'minutes' })} editType='numericedit' format='N' edit={{ params: { min: 0, max: 59, decimals: 0, format: 'N' } }}></ColumnDirective>
                            <ColumnDirective field='Cycle' headerText={this.props.intl.formatMessage({ id: 'cycle' })}
                                editTemplate={this.editTemplateCycle.bind(this)} template={this.valueTemplateCycle.bind(this)}></ColumnDirective>
                            <ColumnDirective field='Day' headerText={this.props.intl.formatMessage({ id: 'day' })}
                                editTemplate={this.editTemplateDay.bind(this)} template={this.valueTemplateDay.bind(this)}></ColumnDirective>
                            {this.renderDeleteStartColumn()}
                        </ColumnsDirective>
                        <Inject services={[Edit, Page, CommandColumn]} />
                    </GridComponent >
                </div>
            );
        }
    }

    render() {
        let idTxtSave = 'save';
        let btnId = 'configBtn';
        let btnIdSave = 'configBtnSave'
        if (this.props.Option === 'ConfigurationPage') idTxtSave = 'confirm', btnId = 'configBtnPage', btnIdSave = 'configBtnSavePage';
        return (
            <div className='divConfiguration'>
                <div id='divConfigurationTop'>
                    <div id='startsConfig'>
                        <span className='lblValue'><FormattedMessage id='configuration_starts' /></span>
                    </div>
                    <div id={btnId}>
                        <ButtonComponent
                            id='btnCloseConfigurations'
                            onClick={() => this.menuCloseClick()}
                            iconCss='e-icons e-FT_close'
                            className='ButtonEmpty'><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div id={btnIdSave}>
                        <ButtonComponent
                            id='btnSaveConfigurations'
                            onClick={() => this.menuSaveClick()}
                            disabled={(this.props.Option === 'MonitorPage' && this.state?.Detail?.Status !== 1)}
                            iconCss='e-icons e-FB_PV_Save'
                            className='Button'><FormattedMessage id={idTxtSave} /></ButtonComponent>
                    </div >
                </div>
                {this.renderStartsConfigurationGrid()}
            </div>
        );
    }
}

StartsConfiguration.propTypes = {
    Selected: PropTypes.object,
    Detail: PropTypes.object,
    MenuCloseClick: PropTypes.func,
    SetStarts: PropTypes.func,
    intl: PropTypes.object,
    IsConfigurationMenu: PropTypes.bool,
    Option: PropTypes.string
};

export default injectIntl(StartsConfiguration);