import * as React from 'react';
import PropTypes from 'prop-types';
import dotnetify from 'dotnetify';
import '../styles/monitorPage.css';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Sort, Page, Reorder } from '@syncfusion/ej2-react-grids';
import BasePage from '../components/BasePage';
import SidebarManage from '../components/SidebarManage';

import { statusTemplate, statusTemplateIntl } from '../components/templates/StatusTemplate';
import { deviceIDTemplate } from '../components/templates/DeviceIDTemplate';
import { cycleTimeTemplate } from '../components/templates/CycleTimeTemplate';
import { tOvenTemplate, tFridgeTemplate } from '../components/templates/TemperatureTemplate';
import ImageModel from '../components/model/ImageModel';

import { setSpinner } from '@syncfusion/ej2-popups';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import CommonDataManager from '../components/CommonDataManager';
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';
import { CheckBoxSelection, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

class MonitorPage extends React.Component {

    constructor(props) {
        super(props);

        this.fixedFields = ['Selected', 'Id', 'DeviceID', 'StatusValue'];
        this.toolbarOptions = ['ColumnChooser'];
        setSpinner({ template: '<div style="width:100%;height:100%" className="custom-rolling"><div></div></div>' });

        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribe(this.updateLanguage.bind(this));
        this.commonData.subscribeTheme(this.updateTheme.bind(this));

        L10n.load(this.commonData.getMessages());
        this.intlCache = createIntlCache();
        setCulture(this.commonData.getLanguageExt());
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);

        this.localData = [];
        this.state = {
            Theme: this.commonData.getTheme(),
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            Data: undefined,
            DataRefresh: [],
            SidebarManageVisible: 0,
            SelectedDevices: [],
            SelectedRows: [],
            SidebarEnabled: !(window.innerWidth < this.commonData.getMobileWidth()),
            ChangePage: '',
            ColumnChooser: [],
        };

        this.vm = dotnetify.react.connect('Monitor', this);

        this.filterSettings = { type: 'CheckBox', checkBoxOnly: 'true' };

    }

    updateLanguage() {
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);
        setCulture(this.commonData.getLanguageExt());
        L10n.load(this.commonData.getMessages());
        this.setState({ Language: this.commonData.getLanguage(), Messages: this.commonData.getMessages(), ColumnChooser: [] });
    }

    updateTheme() {
        this.setState({ Theme: this.commonData.getTheme() });
    }

    resizeEvent = () => {
        let columnSelected = undefined;
        if (this.gridInstance !== undefined)
            columnSelected = this.gridInstance.getColumnByField('Selected');

        if (window.innerWidth < this.commonData.getMobileWidth()) {
            if (columnSelected !== undefined) columnSelected.visible = false;
            this.setState({ SidebarEnabled: false });
            this.clearSelection();
        }
        else {
            if (columnSelected !== undefined) columnSelected.visible = true;
            this.setState({ SidebarEnabled: true });
        }
    };

    componentDidMount() {
        this.commonData.setCurrentView('MonitorPage');
        window.addEventListener('resize', this.resizeEvent);
    }

    componentWillUnmount() {
        this.vm?.$destroy();
        this.commonData?.unsubscribe(this.updateLanguage);
        this.commonData?.unsubscribeTheme(this.updateTheme);
        window.removeEventListener('resize', this.resizeEvent);
    }

    onChange(arg) {
        this.gridInstance.clearFiltering();
        let test;
        if (arg === 'TotalDevices') {
            this.gridInstance.removeFilteredColsByField('StatusValue', 'CycleNumber')
        }

        if (arg === 'ConnectedDevices') {
            test = 'NC';
            this.gridInstance.filterByColumn('StatusValue', 'notequal', test)
        }

        if (arg === 'InAlarmDevices') {
            test = 'ALARM';
            this.gridInstance.filterByColumn('StatusValue', 'equal', test)
        }

        if (arg === 'InCycleDevices') {
            test = 0 || null;
            this.gridInstance.filterByColumn('CycleNumber', 'notequal', test)
        }
    }

    componentDidUpdate(prevProps, prevState) {

        let idx = -1;

        this.umTemperature = this.commonData.getUmTemperature();
        this.Alarms = this.commonData.getAlarms();

        if (prevState.Theme !== this.state.Theme)
            this.gridInstance.refreshColumns();


        if (this.gridInstance !== undefined) {
            if (this.gridInstance.dataSource === undefined) {
                this.localData = this.state.Data;
                this.gridInstance.dataSource = this.localData;
            }
            else {

                if (prevState.Data !== this.state.Data) {
                    this.localData = this.state.Data;

                    if (this.state.Data !== undefined) {
                        this.gridInstance.dataSource = this.localData;
                    }
                }
            }

            if (this.state.ColumnChooser.length === 0) {
                let cc = [];
                for (let i = 0; i < this.gridInstance.columns.length; i++) {
                    if (!this.fixedFields.includes(this.gridInstance.columns[i].field))
                        cc.push({ field: this.gridInstance.columns[i].field, headerText: this.gridInstance.columns[i].headerText });

                }

                if (cc.length !== 0)
                    this.setState({ ColumnChooser: cc });
            }
        }

        if (prevState?.RouteInfo !== this.state?.RouteInfo) {
            //TODO gestire qui l'impostazione dei filtri sulla griglia in base al valore di RouteInfo
            this.onChange(this.state?.RouteInfo)
        }


        if (this.localData == null) {
            return;
        }

        if (prevState.DataRefresh != this.state.DataRefresh) {

            if (this.state.DataRefresh == undefined)
                return;

            for (let i = 0; i < this.state.DataRefresh.length; i++) {

                idx = this.localData.findIndex(x => x.Id == this.state.DataRefresh[i].Id);
                if (idx != -1) {

                    this.localData[idx].Status = this.state.DataRefresh[i].Status;
                    this.localData[idx].TOven = this.state.DataRefresh[i].TOven;
                    this.localData[idx].TFridge = this.state.DataRefresh[i].TFridge;
                    this.localData[idx].TProbe = this.state.DataRefresh[i].TProbe;
                    this.localData[idx].DeviceDate = new Date(this.state.DataRefresh[i].DeviceDate);
                    this.localData[idx].CycleNumber = this.state.DataRefresh[i].CycleNumber;
                    this.localData[idx].CycleTime = this.state.DataRefresh[i].CycleTime;
                    this.localData[idx].Remotes = this.state.DataRefresh[i].Remotes;
                    this.localData[idx].Alarms = this.state.DataRefresh[i].Alarms;
                }
            }

            this.setState(() => ({ SelectedDevices: this.localData.filter(item => item.Selected == true) }));

            if (this.gridInstance !== undefined) this.gridInstance.refresh();

            return;
        }

        if (prevState.SelectedRows != this.state.SelectedRows) {

            for (let i = 0; i < this.localData.length; i++) {
                idx = this.state.SelectedRows.findIndex(x => x == this.localData[i].Id);

                if (idx != -1)
                    this.localData[i].Selected = true;
                else
                    this.localData[i].Selected = false;
            }
            this.setState(() => ({ SelectedDevices: this.localData.filter(item => item.Selected == true) }));


            if (this.gridInstance !== undefined) this.gridInstance.refresh();
            return;
        }

    }
    rowTemplate(props) {
        return (
            <tr className='templateRow'>
                <td className='deviceId'>
                    <ImageModel theme={this.state.Theme} model={props.CodModel} version={props.DeviceVersion} />
                    <span id='testo'>{props.DeviceID}</span>
                </td>
                <td className='deviceDetails'>
                    <table className='deviceText' cellPadding={3} cellSpacing={2}>
                        <colgroup>
                            <col style={{ width: '50%' }} />
                            <col style={{ width: '50%' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className='detailsSite'><b>{this.intl.formatMessage({ id: 'site' })}</b></td>
                                <td id='testo'>{props.Site} </td>
                            </tr>
                            <tr>
                                <td className='detailsStatus'><b>{this.intl.formatMessage({ id: 'status' })}</b></td>
                                <td id='testo'>{statusTemplateIntl(props, this.intl)}</td>
                            </tr>
                            <tr>
                                <td className='Department'><b>{this.intl.formatMessage({ id: 'department' })}</b></td>
                                <td id='testo'>{props.Department} </td>

                            </tr>
                            <tr>
                                <td className='detailsCycle'><b>{this.intl.formatMessage({ id: 'cycletime' })}</b></td>
                                <td id='testo'>{cycleTimeTemplate(props)}</td>
                            </tr>
                            <tr>
                                <tbody className='row'>
                                    <tr className='col-xs-7'>
                                        <th className='TOven'><b>{this.intl.formatMessage({ id: 'toven' })}</b></th>
                                        <td id='testo'>{tOvenTemplate.bind(props)}</td>
                                    </tr>
                                    <tr className='col-xs-5'>
                                        <th className='TFridge'><b>{this.intl.formatMessage({ id: 'tfridge' })}</b></th>
                                        <td id='testo'>{tFridgeTemplate.bind(props)}</td>
                                    </tr>
                                </tbody>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    }

    handleCloseMultiSelect() {
        for (let i = 0; i < this.gridInstance.columns.length; i++) {
            if (!this.fixedFields.includes(this.gridInstance.columns[i].field))
                this.gridInstance.columns[i].visible = this.multiselect.value.includes(this.gridInstance.columns[i].field);
        }
        if (this.gridInstance !== undefined) this.gridInstance.refresh();
    }

    handleOpenMultiSelect() {
        let Checkbox = [];
        for (let i = 0; i < this.gridInstance.columns.length; i++) {
            if (!this.fixedFields.includes(this.gridInstance.columns[i].field))
                if (this.gridInstance.columns[i].visible)
                    Checkbox.push(this.gridInstance.columns[i].field);
        }
        this.multiselect.value = Checkbox;
    }

    selectAll() {
        let newSelectedRows = [];
        for (let i = 0; i < this.gridInstance.currentViewData.length; i++) {
            this.gridInstance.currentViewData[i].Selected = true;
            newSelectedRows.push(this.gridInstance.currentViewData[i].Id);
        }

        this.gridInstance.refresh();
        this.setState({ SelectedRows: newSelectedRows, SidebarManageVisible: 2 });
    }

    unSelectAll() {
        for (let i = 0; i < this.gridInstance.currentViewData.length; i++)
            if (this.gridInstance.currentViewData[i].Selected === true)
                this.gridInstance.currentViewData[i].Selected = false;
        this.clearSelection();
    }


    gridTemplate() {

        if (window.innerWidth < this.commonData.getMobileWidth()) return (
            <div>
                <GridComponent id='monitorGrid' ref={grid => this.gridInstance = grid}
                    enableHover={true} enableToggle={false} allowFiltering={true} allowPaging={true}
                    filterSettings={{ type: 'CheckBox', checkBoxOnly: 'true' }}
                    rowTemplate={this.rowTemplate.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field='Id' headerText='ID' isPrimaryKey={true} visible={false}></ColumnDirective>
                        <ColumnDirective field='DeviceID' headerText={this.intl.formatMessage({ id: 'id_trolley' })} width='30%'></ColumnDirective>
                        <ColumnDirective field='StatusValue' headerText={this.intl.formatMessage({ id: 'details' })} width='70%'
                            allowFiltering={false} allowSorting={false} />
                    </ColumnsDirective>
                    <Inject services={[Filter, Sort]} />
                </GridComponent>

            </div>
        );

        else
            return (
                <div>
                    <div id='divMultiSelectColumns'>
                        <div id='divBtnMonitorpage'>
                            <ButtonComponent id='btnSelectAll' onClick={this.selectAll.bind(this)}><FormattedMessage id='select_all' /></ButtonComponent>
                            <ButtonComponent id='btnUnSelectAll' onClick={this.unSelectAll.bind(this)}><FormattedMessage id='unSelect_all' /></ButtonComponent>
                        </div>
                        <div id='multiSelectColumns'>
                            <MultiSelectComponent
                                ref={ms => this.multiselect = ms}
                                fields={{ value: 'field', text: 'headerText' }}
                                dataSource={this.state.ColumnChooser}
                                mode='CheckBox'
                                showDropDownIcon='true' allowFiltering='false'
                                allowCustomValue='false'
                                open={this.handleOpenMultiSelect.bind(this)}
                                close={this.handleCloseMultiSelect.bind(this)}
                                selectAllText={this.intl.formatMessage({ id: 'select_all' })}
                                unSelectAllText={this.intl.formatMessage({ id: 'unSelect_all' })}
                                showSelectAll={true}
                                placeholder={this.intl.formatMessage({ id: 'columns' })}
                            >
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                    </div>
                    <GridComponent id='monitorGrid' ref={grid => this.gridInstance = grid}
                        enableHover={true} enableToggle={false} allowFiltering={true} allowPaging={true} pageSettings={{ pageSize: 30 }}
                        filterSettings={this.filterSettings}
                        selectionSettings={{ mode: 'Both' }}
                        allowSorting={true} allowSelection={true}
                        cellSelected={this.cellSelectedMonitorGrid.bind(this)}
                        allowReordering={true}
                        enablePersistence={true}
                        actionComplete={this.dataStateChangeMonitorGrid.bind(this)}>

                        <ColumnsDirective>
                            <ColumnDirective field='Selected' displayAsCheckBox={true} width='40px' allowFiltering={false} allowSorting={false} allowReordering={false} customAttributes={{ class: 'selected' }} headerText=' '></ColumnDirective>
                            <ColumnDirective field='Id' headerText='ID' isPrimaryKey={true} visible={false}></ColumnDirective>
                            <ColumnDirective field='DeviceID' headerText={this.intl.formatMessage({ id: 'id_trolley' })} template={deviceIDTemplate.bind(this)}></ColumnDirective>
                            <ColumnDirective field='Model' headerText={this.intl.formatMessage({ id: 'model' })} hideAtMedia='(min-width:800px)'></ColumnDirective>
                            <ColumnDirective field='StatusValue' headerText={this.intl.formatMessage({ id: 'status' })} template={statusTemplate.bind(this)} filterTemplate={statusTemplate.bind(this)} />
                            <ColumnDirective field='Site' headerText={this.intl.formatMessage({ id: 'site' })} />
                            <ColumnDirective field='Department' headerText={this.intl.formatMessage({ id: 'department' })} ></ColumnDirective>
                            <ColumnDirective field='CycleTime' headerText={this.intl.formatMessage({ id: 'cycletime' })} template={cycleTimeTemplate.bind(this)} textAlign='Center' hideAtMedia='(min-width:800px)'></ColumnDirective>
                            <ColumnDirective field='TOven' headerText={this.intl.formatMessage({ id: 'toven' })} clipMode='Clip' template={tOvenTemplate.bind(this)} textAlign='Center' hideAtMedia='(min-width:500px)'></ColumnDirective>
                            <ColumnDirective field='TFridge' headerText={this.intl.formatMessage({ id: 'tfridge' })} clipMode='Clip' template={tFridgeTemplate.bind(this)} textAlign='Center' hideAtMedia='(min-width:500px)'></ColumnDirective>
                            <ColumnDirective field='CycleNumber' headerText='' visible={false} />
                        </ColumnsDirective>

                        <Inject services={[Filter, Sort, Page, Reorder]} />

                    </GridComponent>

                </div>

            );
    }



    cellSelectedMonitorGrid(e) {

        let checkboxSelect = (e.cellIndex.cellIndex == 0);
        let selectedId = -1;
        if (this.gridInstance.getSelectedRecords().length != 0)
            selectedId = this.gridInstance.getSelectedRecords()[0].Id;
        let newSelectedRows = this.state.SelectedRows.slice();
        let sidebarVisible = 0;

        if (checkboxSelect) {
            newSelectedRows = this.state.SelectedRows.slice();
            if (newSelectedRows.filter(item => item == selectedId).length != 0)
                newSelectedRows = newSelectedRows.filter(item => item != selectedId);
            else
                newSelectedRows.push(selectedId);
        }
        else {
            if (newSelectedRows.length == 0)
                newSelectedRows.push(selectedId);
            else {
                if (newSelectedRows.length == 1) {
                    if (newSelectedRows.filter(item => item == selectedId).length == 0) {
                        newSelectedRows = [];
                        newSelectedRows.push(selectedId);
                    }
                    else
                        newSelectedRows = [];
                }
                else
                    return;
            }
        }

        if (checkboxSelect) {
            if (newSelectedRows.length == 0)
                sidebarVisible = 0;
            else
                sidebarVisible = 1;
        }
        else {
            if (newSelectedRows.length == 0)
                sidebarVisible = 0;

            if (newSelectedRows.length == 1)
                sidebarVisible = 2;
        }

        if (!this.state.SidebarEnabled)
            sidebarVisible = 0;

        this.setState({
            SelectedRows: newSelectedRows,
            SidebarManageVisible: sidebarVisible
        });

    }

    clearSelection() {
        this.setState({
            SelectedRows: [],
            SidebarManageVisible: 0
        });
    }

    dataStateChangeMonitorGrid(e) {
        if (e.requestType == 'paging')
            if (this.state.ChangePage != 0) {

                let pageRows = this.gridInstance.getCurrentViewRecords();
                let newSelectedRows = [];

                if (this.state.ChangePage == -1)
                    newSelectedRows.push(pageRows[pageRows.length - 1].Id);


                if (this.state.ChangePage == +1)
                    newSelectedRows.push(pageRows[0].Id);

                this.setState({
                    SelectedRows: newSelectedRows,
                });

            }
    }

    changeDevice(direction) {

        if (this.state.SelectedRows.length != 1)
            return;

        let currentPage = this.gridInstance.pageSettings.currentPage;
        let newCurrentPage = currentPage;
        let pageCount = this.gridInstance.pageSettings.pageCount - 1;

        let pageRows = this.gridInstance.getCurrentViewRecords();
        let selectedRowIndex = this.gridInstance.getRowIndexByPrimaryKey(this.state.SelectedDevices[0]);

        if (direction == -1)
            selectedRowIndex--;

        if (direction == 1)
            selectedRowIndex++;

        if (selectedRowIndex < 0) {
            newCurrentPage--;
            if (newCurrentPage == 0)
                newCurrentPage = pageCount;
        }

        if (selectedRowIndex > pageRows.length - 1) {
            newCurrentPage++;
            if (newCurrentPage > pageCount)
                newCurrentPage = 1;
        }

        if (newCurrentPage != currentPage) {
            this.gridInstance.goToPage(newCurrentPage);
            this.setState({ ChangePage: direction });
            return;
        }

        let newSelectedRows = [];
        newSelectedRows.push(pageRows[selectedRowIndex].Id);

        this.setState({
            SelectedRows: newSelectedRows,
            ChangePage: 0
        });
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div id='divMonitor'>
                    <BasePage title={this.intl.formatMessage({ id: 'menuheader.trolleys' })} navigation={this.intl.formatMessage({ id: 'menuheader.trolleys' })}></BasePage>
                    <div id='divMonitorGrid'>
                        {this.gridTemplate()}
                        <SidebarManage
                            SidebarManageVisible={this.state.SidebarManageVisible}
                            SelectedDevices={this.state.SelectedDevices}
                            ClearSelection={this.clearSelection.bind(this)}
                            ChangeDevice={this.changeDevice.bind(this)}
                            SitesPermissions={this.state.SitesPermissions}
                            DeparmentsPermissions={this.state.DeparmentsPermissions}
                            UmTemperature={this.umTemperature}
                            Option='MonitorPage'
                            Theme={this.state.Theme}
                            Alarms={this.Alarms}
                        >
                        </SidebarManage>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

MonitorPage.propTypes = {
    selectDashboard: PropTypes.string
};

export default MonitorPage;