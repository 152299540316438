import React from 'react';
import PropTypes from 'prop-types';
import auth from '../auth';
import '../styles/header.css';
import { injectIntl } from 'react-intl';
import Menu from '../components/Menu';
import CommonDataManager from '../components/CommonDataManager';
import Toolbar from '../components/Toolbar';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.commonData = CommonDataManager.getInstance();
        this.commonData.subscribeView(this.updateCurrentView.bind(this));

        this.state = { isSetupSelected: false, currentView: 'DashBoard', isEventsSelected: false };
    }

    updateCurrentView(v) {
        this.setState({ currentView: v, isSetupSelected: v.startsWith('Setup') && v !== 'SetupConfigurationsPage', isEventsSelected: v.startsWith('History') });
    }

    componentWillUnmount() {
        this.commonData?.unsubscribeView(this.updateCurrentView);
    }

    logout() { auth.logout(); }

    headerToolbarSelect(title, route) {
        this.setState({ isSetupSelected: title === 'menuheader.setup' || title.startsWith('menusetupheader.'), 
        isEventsSelected: title === 'menuheader.events' || title.startsWith('menueventsheader.') });
        this.props.vm.$routeTo(route);
    }

    renderHeaderToolbar() {
        if (!this.props.MobileMenuEnabled)
            return (
                <Toolbar
                    Items={this.props.MenuHeader}
                    ToolbarSelect={this.headerToolbarSelect.bind(this)}
                    Option='menuHeader'
                    CurrentView={this.state.currentView}
                />
            )
    }

    renderSetupHeaderToolbar() {
        if ((!this.props.MobileMenuEnabled) && (this.state.isSetupSelected))
            return (
                <Toolbar
                    Items={this.props.MenuSetupHeader}
                    ToolbarSelect={this.headerToolbarSelect.bind(this)}
                    Option='menuSetupHeader'
                    CurrentView={this.state.currentView}
                />
            )
    }

    renderEventsHeaderToolbar() {
        if ((!this.props.MobileMenuEnabled) && (this.state.isEventsSelected))
            return (
                <Toolbar
                    Items={this.props.MenuEventsHeader}
                    ToolbarSelect={this.headerToolbarSelect.bind(this)}
                    Option='menuEventHeader'
                    CurrentView={this.state.currentView}
                />
            )
    }

    renderMobileMenu() {
        if (this.props.MobileMenuEnabled)
            if (this.props.SidebarMenuMobileVisible === 0)
                return (
                    <span id='hamburger'
                        onClick={this.props.OpenMenuMobile}><img id='imgMenuClose' src={'/images/icons/menuClose.png'}></img></span>
                );
            else
                return (
                    <span id='hamburger'
                        onClick={this.props.OpenMenuMobile}><img id='imgMenuOpen' src={'/images/icons/menuOpen.png'}></img></span>
                );
    }

    renderInstallationInfo() {
        if (!this.props.MobileMenuEnabled)
            return (
                <div id='divInfoInstallation'>
                    <p className='infoInstallation'>
                        {this.props.InstallationInfo1}
                    </p>
                    <p className='infoInstallation'>
                        {this.props.InstallationInfo2}
                    </p>
                </div>
            )
    }

    render() {
        return (
            <header>
                <div id={'divHeader' + this.props.MobileMenuEnabled}>
                    {this.renderMobileMenu()}
                    <img src='/images/logoTest.png' id='logo-header' />

                </div>
                {this.renderInstallationInfo()}

                {this.renderHeaderToolbar()}
                {this.renderSetupHeaderToolbar()}
                {this.renderEventsHeaderToolbar()}

                <div id={'divButton' + this.props.MobileMenuEnabled}>
                    <Menu
                        Logout={this.logout.bind(this)}
                        OpenDialogSettings={this.props.OpenDialogSettings}
                        OpenDialogAbout={this.props.OpenDialogAbout}
                        MobileMenuEnabled={this.props.MobileMenuEnabled}
                        UserName={this.props.UserName}
                        UserImage={this.props.UserImage}
                        OpenUSB={this.props.OpenUSB}
                        OpenSetupProfile={this.props.OpenSetupProfile}
                    >
                    </Menu>
                </div>
            </header>
        );
    }
}


Header.propTypes = {
    MobileMenuEnabled: PropTypes.bool,
    vm: PropTypes.object,
    intl: PropTypes.object,
    MenuHeader: PropTypes.array,
    MenuSetupHeader: PropTypes.array,
    SidebarMenuMobileVisible: PropTypes.number,
    OpenDialogSettings: PropTypes.func,
    OpenDialogAbout: PropTypes.func,
    OpenMenuMobile: PropTypes.func,
    OpenSetupProfile: PropTypes.func,
    UserName: PropTypes.string,
    UserImage: PropTypes.string,
    InstallationInfo1: PropTypes.string,
    InstallationInfo2: PropTypes.string,
    OpenUSB: PropTypes.func,
    MenuEventsHeader: PropTypes.array
};

export default injectIntl(Header);