import React from 'react';
import PropTypes from 'prop-types';
import { IDParamsConfigurationTemplate } from '../templates/IDParamsConfigurationTemplate';
import '../../styles/manage/paramsConfiguration.css'
import { getValue } from '@syncfusion/ej2-base';
import ProgressBar from '../../components/ProgressBar';
import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { convertFahrenheit, convertCelsius } from '../../components/utils/TemperatureConversion';

import { injectIntl, FormattedMessage } from 'react-intl';

class ParamsConfiguration extends React.Component {

    constructor(props) {
        super(props);

        if (props.IsConfigurationMenu)
            this.state = { Detail: props.Detail };
        else
            this.state = { Detail: null };
    }

    menuCloseClick() {
        this.gridParamsInstance?.endEdit();
        this.props.MenuCloseClick();
    }

    menuSaveClick() {

        this.gridParamsInstance?.endEdit();
        let paramsT = this.state.Detail.Params;

        if (this.props.UmTemperature === 'F') {
            for (let i = 0; i < paramsT.length; i++) {
                if (paramsT[i].Type === 'T')
                    paramsT[i].Value = convertCelsius(paramsT[i].Value);
            }
        }

        this.props.SetParams(paramsT);
        this.props.MenuCloseClick();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps?.Selected?.Id === this.state?.Detail?.Id)
            return false;

        return true;
    }

    componentDidUpdate() {
        if ((this.props?.Selected !== undefined) && (this.props?.Selected?.Id !== this.props?.Detail?.Id)) {
            if (this.state.Detail !== null)
                this.setState({ Detail: null });
            return;
        }
        else {
            let bSetDetail = (this.state.Detail == null);

            if (!bSetDetail)
                bSetDetail = (this.props.Detail.Id !== this.state.Detail.Id);

            if (bSetDetail) {

                let d = this.props.Detail;

                if (this.props.UmTemperature === 'F') {
                    for (let i = 0; i < d?.Params?.length; i++)
                        if (d?.Params[i]?.Type === 'T') {
                            d.Params[i].Value = convertFahrenheit(d?.Params[i].Value);
                            d.Params[i].MinValue = convertFahrenheit(d?.Params[i].MinValue);
                            d.Params[i].MaxValue = convertFahrenheit(d?.Params[i].MaxValue);
                        }
                }

                this.setState({ SelectedCycle: null, Detail: d });
            }
        }
    }

    valueTemplateParam(props) {
        if (props.Type === 'E') {

            for (let i = 0; i < props.AllowedValues.length; i++)
                props.AllowedValues[i].Description = this.props.intl.formatMessage({ id: props.AllowedValues[i].ID });

            let d = props.AllowedValues.find(v => v.Value === getValue('Value', props))['Description'];


            return (<span>{d}</span>);
        }
        if (props.Type === 'S') {
            return (<span>{props.Value}</span>);
        }
        if (props.Type === 'N') {
            return (<span>{props.Value}</span>);
        }

        if (props.Type === 'T') {
            return (<span>{props.Value}°{this.props.UmTemperature}</span>);
        }

        return null;
    }

    editTemplateParam(props) {
        if (props.Type === 'E') {

            for (let i = 0; i < props.AllowedValues.length; i++)
                props.AllowedValues[i].Description = this.props.intl.formatMessage({ id: props.AllowedValues[i].ID });

            return (
                <div>
                    <DropDownListComponent id='Value' value={getValue('Value', props)} dataSource={props.AllowedValues}
                        fields={{ text: 'Description', value: 'Value' }}
                    />
                </div>
            );
        }
        if (props.Type === 'S') {
            return (
                <TextBoxComponent id='Value' value={props.Value} />
            );
        }
        if ((props.Type === 'N') || (props.Type === 'T')) {
            return (
                <NumericTextBoxComponent id='Value' format='n0' min={props.MinValue} max={props.MaxValue} value={props.Value} />
            );
        }

        return null;
    }

    renderParamsConfigurationGrid() {

        if (this.state.Detail === null)
            return (
                <ProgressBar Id='ParamsConfiguration' Option={this.props.Option} Message={this.props.intl?.formatMessage({ id: 'waiting' })} />
            )
        else
            return (
                <div id='renderParamsConfiguration'>
                <GridComponent id='paramsConfigurationGrid' ref={grid => this.gridParamsInstance = grid}
                    dataSource={(this.state?.Detail === null) ? null : this.state?.Detail?.Params}
                    editSettings={{ allowEditing: true, allowAdding: false, allowDeleting: false, mode: 'Dialog' }}
                    allowSorting={false} allowSelection={false} className='comm-grid'
                >
                    <ColumnsDirective>
                        <ColumnDirective field='Name' headerText={this.props.intl.formatMessage({ id: 'id' })} allowEditing={false} template={IDParamsConfigurationTemplate.bind(this)} editTemplate={IDParamsConfigurationTemplate.bind(this)} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective field='Value' headerText={this.props.intl.formatMessage({ id: 'value' })}
                            editTemplate={this.editTemplateParam.bind(this)} template={this.valueTemplateParam.bind(this)}>
                        </ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Edit]} />
                </GridComponent>
                </div>
            );
    }

    render() {
        let idTxtSave = 'save';
        let btnId ='configBtn';
        let btnIdSave='configBtnSave'
        if (this.props.Option === 'ConfigurationPage') idTxtSave ='confirm' , btnId='configBtnPage', btnIdSave='configBtnSavePage';
        return (
            <div className='divConfiguration'>
                <div id='divConfigurationTop'>
                    <div id='paramsConfig'>
                        <span className='lblValue'><FormattedMessage id='configuration_params' /></span>
                    </div>
                    <div id={btnId}>
                        <ButtonComponent
                            id='btnCloseConfigurations'
                            onClick={() => this.menuCloseClick()}
                            iconCss='e-icons e-FT_close'
                            className='ButtonEmpty'><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div id={btnIdSave}>
                        <ButtonComponent
                            id='btnSaveConfigurations'
                            onClick={() => this.menuSaveClick()}
                            disabled={(this.props.Option === 'MonitorPage' && this.state?.Detail?.Status !== 1)}
                            iconCss='e-icons e-FB_PV_Save'
                            className='Button'><FormattedMessage id={idTxtSave} /></ButtonComponent>
                    </div >
                </div>
                {this.renderParamsConfigurationGrid()}
            </div>
        );
    }
}

ParamsConfiguration.propTypes = {
    Selected: PropTypes.object,
    Detail: PropTypes.object,
    SetParams: PropTypes.func,
    MenuCloseClick: PropTypes.func,
    intl: PropTypes.object,
    UmTemperature: PropTypes.string,
    IsConfigurationMenu: PropTypes.bool,
    Option: PropTypes.string
};

export default injectIntl(ParamsConfiguration);