// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#renderSetting{\n    display: block;\n    width: 100%;\n}\n\n.temperature{\n    padding-top: 10px;\n}\n\n.theme{\n    padding-top: 10px;\n}\n\n#btnSet{\n   width: 132px;\n}\n\n#lblDescription{\n    text-align: left;\n}\n\n.divBtnSet{\n    padding-top: 145px;\n}", "",{"version":3,"sources":["webpack://./src/styles/dialogSet.css"],"names":[],"mappings":"AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;GACG,YAAY;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["@import \"app.css\";\n\n#renderSetting{\n    display: block;\n    width: 100%;\n}\n\n.temperature{\n    padding-top: 10px;\n}\n\n.theme{\n    padding-top: 10px;\n}\n\n#btnSet{\n   width: 132px;\n}\n\n#lblDescription{\n    text-align: left;\n}\n\n.divBtnSet{\n    padding-top: 145px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
