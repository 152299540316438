import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/dialogSetupProfile.css';
import AvatarTemplate from '../components/templates/AvatarTemplate';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';

class DialogSetupProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = { UserDetail: this.props.UserDetail }

        this.allowedExtensions = '.jpg,.png,.jpeg';

    }
    imgPreviewAvatar() {
        return (
            <div>
                <AvatarTemplate
                    UserImage={this.state.UserDetail.UserImage}
                    UserName={this.state.UserDetail.UserName}
                    Option='dialogUp'
                    IsNewUser={this.props.IsNewUser}
                ></AvatarTemplate>
                <div id='divImgAvatarProfile' className='e-avatar e-avatar-xsmall e-avatar-circle' onClick={this.browseClick.bind(this)}>
                    <img id='browse' src={'/images/icons/folder.svg'} ></img>

                </div>
                <div id='divImgDeleteProfile' className='e-avatar e-avatar-xsmall e-avatar-circle' onClick={this.onRemoveFile.bind(this)} >
                    <img id='imgDelete' src={'/images/icons/delete.svg'} ></img>
                </div>
                <div className='btnBrowser'>
                    <UploaderComponent type='file'
                        allowedExtensions={this.allowedExtensions}
                        asyncSettings={this.asyncSettings} autoUpload={true} multiple={false}
                        minFileSize={1000} maxFileSize={1000000}
                        selected={this.onSelect.bind(this)}
                        removing={this.onRemoveFile.bind(this)}
                    ></UploaderComponent>
                </div>
            </div>
        )
    }

    close() {
        this.props.HideSetupProfile()
    }

    SaveSettings() {
        this.setState({ UserDetail: { ...this.state.UserDetail } })
        this.props.HideAndSaveProfile(this.state.UserDetail)
    }

    onSelect(args) {
        let file = args.filesData[0].rawFile;
        let reader = new FileReader();

        reader.addEventListener('load', () => { this.setState({ UserDetail: { ...this.state.UserDetail, UserImage: reader.result } }); }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    onRemoveFile() {
        this.setState({ UserDetail: { ...this.state.UserDetail, UserImage: null } });
    }

    browseClick() {
        document.getElementById('browse').onclick = () => {
            document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
            return false;
        }
    }

    changeUserDetail(e) {
        this.setState({
            UserDetail: { ...this.state.UserDetail, [e.target.name]: e.target.value }
        })
    }

    changePassword(e) {
        this.setState({ UserDetail: { ChangePassword: e } });
    }

    renderUserDetail() {

        return (
            <div id='divProfile'>
                <div id='divUserProfile'>
                    <div className='row'>
                        <div className='col-xs-12' id='txtForm'>
                            <span className='lblDescription'><FormattedMessage id='username' /></span>
                            <input className='e-input' type='text' name='UserName' value={this.state.UserDetail.UserName || ''} onChange={this.changeUserDetail.bind(this)} id='txtUserName' placeholder='' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12' id='txtForm'>
                            <span className='lblDescription'><FormattedMessage id='password' /></span>
                            <input className='e-input' type='password' name='Password' value={this.state.UserDetail.Password || ''} onChange={this.changeUserDetail.bind(this)} id='txtPassword' placeholder='' />
                        </div>
                        <div className='col-xs-12' id='txtForm'>
                            <span className='lblDescription'><FormattedMessage id='confirm_password' /></span>
                            <input className='e-input' type='password' name='ConfirmPassword' value={this.state.UserDetail.ConfirmPassword || ''} onChange={this.changeUserDetail.bind(this)} id='txtConfirmPassword' placeholder='' />
                        </div>
                        <div id='divChangePassword' className='col-xs-12'>
                            <ButtonComponent id='btnChangePassword' className='Button' onClick={() => this.changePassword(this)}><FormattedMessage id='change_password' /></ButtonComponent>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-xs-12' id='txtForm'>
                            <span className='lblDescription'><FormattedMessage id='name' /></span>
                            <input className='e-input' type='text' name='Name' value={this.state.UserDetail.Name || ''} onChange={this.changeUserDetail.bind(this)} id='txtName' placeholder='' />
                        </div>
                        <div className='col-xs-12' id='txtForm'>
                            <span className='lblDescription'><FormattedMessage id='surname' /></span>
                            <input className='e-input' type='text' name='Surname' value={this.state.UserDetail.Surname || ''} onChange={this.changeUserDetail.bind(this)} id='txtSurname' placeholder='' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12' id='txtForm'>
                            <span className='lblDescription'><FormattedMessage id='email' /></span>
                            <input className='e-input' type='text' name='Email' value={this.state.UserDetail.Email || ''} onChange={this.changeUserDetail.bind(this)} id='txtEmail' placeholder='' />
                        </div>
                        <div className='col-xs-12' id='txtForm'>
                            <span className='lblDescription'><FormattedMessage id='mobile' /></span>
                            <input className='e-input' type='text' name='Mobile' value={this.state.UserDetail.Mobile || ''} onChange={this.changeUserDetail.bind(this)} id='txtMobile' placeholder='' />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSetupProfile() {
        return (
            <div id='renderSetupProfile'>
                <div className='headerSetupProfile'>
                    <span className='titleDialog'><FormattedMessage id='profile' /></span>
                    <div id='imgAvatar'>
                        {this.imgPreviewAvatar()}
                    </div>
                </div>
                <div className='divRenderSetup'>
                    {this.renderUserDetail()}
                </div>

                <div className='row divBtnsProfile'>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnUploader' onClick={this.close.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                    </div>
                    <div className='col-xs-6'>
                        <ButtonComponent id='btnUploader' onClick={this.SaveSettings.bind(this)}><FormattedMessage id='confirm' /></ButtonComponent>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className='dialogSetup'>
                    {this.renderSetupProfile()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        )
    }
}

DialogSetupProfile.propTypes = {
    UserImage: PropTypes.any,
    HideSetupProfile: PropTypes.func,
    IsNewUser: PropTypes.bool,
    HideAndSaveProfile: PropTypes.func,
    UserName: PropTypes.string,
    intl: PropTypes.object,
    UserDetail: PropTypes.array

};

export default injectIntl(DialogSetupProfile);