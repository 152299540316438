import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl,FormattedMessage } from 'react-intl';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/sidebarHistoryUSB.css';

class SidebarHistoryUSB extends React.Component {

    renderHistoryUSB() {
       
        return (
            <div id='renderSidebarUSB'>
                <span>Importazione dei dati storici</span>
                <div >
                    <ButtonComponent id='btn' onClick={this.props.openHistoryUSB} ><FormattedMessage id='close' /></ButtonComponent>
                </div>
            </div>

        )
    }

    render() {
        let widthSidebar = '0px';

        if (this.props.SidebarHistoryVisible === 1)
            widthSidebar = '60%';

        return (
            <div id='divBlockSidebarUSB'>
                <div id='divSidebarUSB'
                    style={{ width: widthSidebar }} >
                        {this.renderHistoryUSB()}
                </div>
            </div>
        )

    }

}

SidebarHistoryUSB.propTypes = {
    intl: PropTypes.object,
    SidebarHistoryVisible: PropTypes.bool,
    openHistoryUSB: PropTypes.func
};

export default injectIntl(SidebarHistoryUSB);