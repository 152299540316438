import React from 'react';
import PropTypes from 'prop-types';
import auth from '../auth';
import '../styles/loginPage.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import CommonDataManager from '../components/CommonDataManager'
import { IntlProvider, createIntl, createIntlCache, FormattedMessage } from 'react-intl';


class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.commonData = CommonDataManager.getInstance();
        if (this.commonData.getLanguage() == null) {
            let tmp = window.localStorage.getItem('language');
            if (tmp == null)
                this.commonData.setLanguage(navigator.language);
        }
        this.intlCache = createIntlCache();
        this.intl = createIntl({ locale: this.commonData.getLanguage(), messages: this.commonData.getMessages() }, this.intlCache);


        this.state = {
            Language: this.commonData.getLanguage(),
            Messages: this.commonData.getMessages(),
            user: '',
            password: ''
        };
    }

    componentDidMount() {
        this.txtUsername.focus();
    }

    login() {
        this.setState({ error: null });
        auth.login(this.state.user, this.state.password).then(() => this.props.Authenticate()).catch(error => {
            try {
                this.setState({ error: this.intl.formatMessage({ id: error.message }) });
            }
            catch
            {
                this.setState({ error: this.intl.formatMessage({ id: 'login.error' }) });
            }
        });
    }

    render() {
        return (
            <IntlProvider locale={this.state.Language} messages={this.state.Messages}>
                <div>
                    <div id='loginContainer'>
                        <div className='row'>
                            <div className='col-xs-2'>
                            </div>
                            <div className='col-xs-8' align='center'>
                                <div className='row'>
                                    <div className='col-xs-12'>
                                        <img src='/images/logo-rational.png' id='logo' />
                                    </div>
                                    <div className='col-xs-12'>
                                        <span id='textCommunicator'>COMMUNICATOR</span><span id='textPlus'> PLUS</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xs-2'>
                            </div>
                        </div>
                        <form id='formLogin'>
                            <div className='row'>
                                <div className='col-xs-3' align='center'>
                                </div>
                                <div className='col-xs-6'>
                                    <label id='lblUsername'><FormattedMessage id='login.username' /></label>
                                    <input className='e-input' type='text' autoCapitalize='none' id='txtUsername' placeholder=''
                                        ref={(txt) => { this.txtUsername = txt; }}
                                        onChange={(e) => this.setState({ user: e.target.value, error: '' })}
                                        onKeyDown={(e) => { if (e.keyCode === 13) this.txtPassword.focus(); }} />
                                    <label id='lblPassword'><FormattedMessage id='login.password' /></label>
                                    <input className='e-input' type='password' id='txtPassword' placeholder=''
                                        ref={(txt) => { this.txtPassword = txt; }}
                                        onChange={(e) => this.setState({ password: e.target.value, error: '' })}
                                        onKeyDown={(e) => { if (e.keyCode === 13) this.login(); }} />
                                    <label id='errorLabel'>{this.state.error}</label>
                                    <ButtonComponent id='btnLogin' type='button'
                                    iconCss= 'e-icons e-navigation-right'
                                        ref={(button) => { this.btnLogin = button; }}
                                        onClick={this.login.bind(this)}>
                                        <FormattedMessage id='login.login' />
                                    </ButtonComponent>
                                </div>
                                <div className='col-xs-6' align='center'>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-3' align='right'>
                                </div>
                                <div className='col-xs-3' align='right'>
                                </div>
                                <div className='col-xs-3' align='right'>
                                </div>
                                <div className='col-xs-3' align='right'>
                                </div>
                            </div>
                        </form>
                        <div id='divPrivacy'>
                            <label id='textPrivacy'>© 2020 RATIONAL PRODUCTION - <a href='' id='textLink'>Privacy</a></label>
                        </div>

                    </div>
                </div>
            </IntlProvider >
        )
    }
}

LoginPage.propTypes = {
    Authenticate: PropTypes.func
};

export default LoginPage;
