import React from 'react';

export function cycleTimeTemplate(props) {
  try {
    if ((props.CycleTime == null) || (props.CycleTime == 0))
      return (
        <div></div>
      );
    else
      return (
        <div>{props.CycleTime} min</div>
      );
  }
  catch (exp) {
    return (<div></div>);
  }
}
