import React from 'react';
import AvatarModel from '../model/AvatarModel';
import CommonDataManager from '../../components/CommonDataManager';

export function deviceIDTemplate(props) {
    this.commonData = CommonDataManager.getInstance();
    this.theme = this.commonData.getTheme()

    return (
        <div>
            <AvatarModel  theme={this.theme} model={props.CodModel} version={props.DeviceVersion} width={40} height={40} />
            <div>
                <span id='DeviceIDText'>{props.DeviceID}</span>
            </div>
        </div>);
}