import React from 'react';
import PropTypes from 'prop-types';

export function ImageModel(props) {
    var modelImage = 'noModel';
    if (props !== undefined) {

        var m = '';
        if (props.version !== null)
            m = '_' + props.version;

        if (props.model !== null)
            m = m + '_' + props.model;

        if (props.Dashboard !== undefined)
            modelImage = 'modelDashboard' + m;
        else
            modelImage = 'model' + m;
    }
    var pathModel = '/images/models/' + modelImage + '_' + props.theme + '.png';
    return (
        <img className='imageModel' width={props.width} height={props.height} src={pathModel} />
    );
}


ImageModel.propTypes = {
    Dashboard: PropTypes.bool,
    model: PropTypes.number,
    version: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    theme: PropTypes.string
};

export default ImageModel;