// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../app.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#divStartsConfiguration #divMenuTop .Button {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divStartsConfiguration #divMenuTop .ButtonEmpty {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divStartsConfiguration #divMenuBottom .ButtonEmpty {\n    text-align: left;\n    font-size: 12px;\n    width: 100%;\n    height: 40px;\n}\n\n#startsConfigurationGrid{\n    padding-top: 12px;\n}", "",{"version":3,"sources":["webpack://./src/styles/manage/startsConfiguration.css"],"names":[],"mappings":"AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["@import \"../app.css\";\n\n#divStartsConfiguration #divMenuTop .Button {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divStartsConfiguration #divMenuTop .ButtonEmpty {\n    font-size: 12px;\n    height: 40px;\n}\n\n#divStartsConfiguration #divMenuBottom .ButtonEmpty {\n    text-align: left;\n    font-size: 12px;\n    width: 100%;\n    height: 40px;\n}\n\n#startsConfigurationGrid{\n    padding-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
