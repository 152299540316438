import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import '../styles/dialogAbout.css';

class DialogAlertCycle extends React.Component {

    close() {
        this.props.HideAlertCycle();
    }

    renderAlertCycle() {
        return (
            <div id='divDialogAlertCycle'>
                <h3 id='titleAlert'><FormattedMessage id='warning' /></h3>
                <div id='messageAlert'>
                    <span><FormattedMessage id='alert_cycle_start' /></span>
                </div>
                <div id='divBtnAbt'>
                    <ButtonComponent id='btnAbt' onClick={this.close.bind(this)}><FormattedMessage id='close' /></ButtonComponent>
                </div>

            </div>
        )
    }

    render() {
        return (
            <div className='divBlockDialog'>
                <div className="dialog">
                    {this.renderAlertCycle()}
                </div>
                <div className='e-dlg-overlay' />
            </div>
        );
    }
}

DialogAlertCycle.propTypes = {
    HideAlertCycle: PropTypes.func
};
export default injectIntl(DialogAlertCycle);