
export function convertFahrenheit(celsius) {
    if (celsius !== null) {
        let fahrenheit = Math.round((celsius * (9 / 5) + 32));
        return fahrenheit;
    } else
        return null
}

export function convertCelsius(fahrenheit) {
    if (fahrenheit !== null) {
        let celsius = Math.round(((fahrenheit - 32) * 5 / 9));
        return celsius;
    } else
        return null
}