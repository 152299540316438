import React from 'react';
import PropTypes from 'prop-types';
import dotnetify from 'dotnetify';
import '../styles/sidebarSetupSites.css';
import Toast from '../components/Toast';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FormattedMessage } from 'react-intl';


class SidebarSetupSites extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            SiteDetail: [],
            NewSite: [],
            Notification: []
        };

        this.vm = dotnetify.react.connect('SetupSiteDetail', this);
    }

    componentWillUnmount() {
        this.vm?.$destroy();
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.SidebarSetupSitesVisible !== this.props?.SidebarSetupSitesVisible) {
            this.setState({ LocalSidebarManageVisible: this.props.SidebarSetupSitesVisible });
        }

        if (prevProps?.IsNewSite != this.props?.IsNewSite)
            if (this.props.IsNewSite)
                this.setState({ SiteDetail: this.state.NewSite });

        if (prevProps?.SelectedSite?.Id != this.props?.SelectedSite?.Id) {
            this.vm?.$dispatch({ SiteID: this.props?.SelectedSite?.Id });
        }
    }

    changeSiteDetail(e) {
        this.setState({
            SiteDetail: { ...this.state.SiteDetail, [e.target.name]: e.target.value }
        })
    }

    saveSite() {
        this.vm?.$dispatch({ SaveSite: this.state.SiteDetail });
    }

    renderSiteDetail() {

        if (this.state?.SiteDetail === null)
            return null;

        return (
            <div id='divSite'>
                <Toast
                    Notification={this.state.Notification}
                    Refresh={this.props.RefreshSites}
                    Option='sites'
                />
                <div className='row rowHeader'>
                    <div className='col-xs-12'>
                        <div className='rowInfo'>
                            <span className='lblTitle'>{this.props?.SelectedSite?.Name}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='row' id='divSiteTop'>
                        <div className='col-xs-6'>
                            <span id="setUpSite" className='lblValue'><FormattedMessage id='setup_site' /></span>
                        </div>
                        <div className='col-xs-6' id='divBtnSite'>
                            <ButtonComponent id='btnSaveSite' className='Button' onClick={() => this.saveSite()}><FormattedMessage id='setup_sites.save_site' /></ButtonComponent>
                        </div>
                    </div>

                    <div className='row' id='divSiteDetail'>
                        <span className='col-xs-12 lblDescription detailName'><FormattedMessage id='site' /></span>
                        <input className='col-xs-12 e-input' type='text' name='Name' value={this.state?.SiteDetail?.Name || ''} onChange={this.changeSiteDetail.bind(this)} id='txtName' placeholder='' />
                    </div>
                </div>

            </div>
        );
    }

    renderButtonsSidebarManage() {
        return (
            <div id='divButtonsSidebar'>
                <div id='divButtonClear'>
                    <ButtonComponent id='btnClear' cssClass='e-round' onClick={() => this.props.ClearSelection()} iconCss='e-icons e-sidebarclear' />
                </div>
                <div id="divButtonPrev">
                    <ButtonComponent id='btnPrev' cssClass='e-round' onClick={() => this.props.ChangeSite(-1)} iconCss='e-icons e-sidebarprev' />
                </div>
                <div id="divButtonNext">
                    <ButtonComponent id='btnNext' cssClass='e-round' onClick={() => this.props.ChangeSite(+1)} iconCss='e-icons e-sidebarnext' />
                </div>
            </div>
        );
    }

    renderContentSidebarSetupSites() {
        if (this.props.SidebarManageVisible === 0)
            return null;

        let stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible !== 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            return (
                <div>
                    {this.renderButtonsSidebarManage()}
                    {this.renderSiteDetail()}
                </div>
            )
    }

    render() {

        let widthSidebar = { width: '0px' };
        let stateSidebar = this.props.SidebarManageVisible;

        if (this.state.LocalSidebarManageVisible != 0)
            stateSidebar = this.state.LocalSidebarManageVisible;

        if (stateSidebar === 1)
            widthSidebar = { width: '45%' };

        return (
            <div ref={Sidebar => this.sidebarobj = Sidebar}
                id='sidebarSetupSites' style={widthSidebar}>
                {this.renderContentSidebarSetupSites()}
            </div>
        );

    }
}

SidebarSetupSites.propTypes = {
    SidebarManageVisible: PropTypes.bool,
    SidebarSetupSitesVisible: PropTypes.number,
    IsNewSite: PropTypes.bool,
    SelectedSite: PropTypes.any,
    RefreshSites: PropTypes.func,
    ClearSelection: PropTypes.func,
    ChangeSite: PropTypes.func
};

export default SidebarSetupSites;